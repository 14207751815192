import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueRouter from 'vue-router'
import 'font-awesome/css/font-awesome.css'
import axios from './axios/index'
import bus from './axios/bus'
import allComponents from '@/components/install'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/resetStyle.scss'
import vueSwiper from 'vue-awesome-swiper'
import tinymce from 'tinymce'
// import VueTinymce from '@packy-tang/vue-tinymce'

Vue.config.productionTip = false;
Vue.use(allComponents);
Vue.use(ElementUI);
Vue.use(vueSwiper);
// Vue.use(VueTinymce)


Vue.prototype.$tinymce = tinymce
Vue.prototype.$Http = axios;
Vue.prototype.$Https = axios.post;
Vue.prototype.$bus = bus;
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

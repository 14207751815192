<template>
  <div class="infoMes-edit-Q">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      class="edit-form"
    >
      <el-form-item label="文章标题" class="common-form-item" prop="title">
        <el-input v-model="ruleForm.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="作者" class="select-auid-item" prop="auId">
        <el-select v-model="ruleForm.auId" filterable placeholder="请选择">
          <el-option
            v-for="item in authorList"
            :key="item.auId"
            :label="item.auName"
            :value="item.auId"
          ></el-option>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-select>
      </el-form-item>
      <el-form-item label="分类" class="select-auid-item" prop="atNa">
        <el-checkbox-group v-model="ruleForm.atNa" size="mini">
          <el-checkbox-button
            v-for="item in classifyList"
            :key="item.atId"
            :label="item.atName"
            >{{ item.atName }}</el-checkbox-button
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="来源" class="common-form-item">
        <el-input v-model="ruleForm.source" placeholder="请输入来源"></el-input>
      </el-form-item>
      <!-- <el-form-item
        label="抓取网站"
        class="common-form-item select-web-item"
        prop="fromWebsite"
      >
        <el-select v-model="ruleForm.fromWebsite" placeholder="请选择抓取网站">
          <el-option
            v-for="item in webList"
            :key="item.dictCode"
            :label="item.dictName"
            :value="item.dictCode + ''"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item
        label="简介"
        class="common-form-item intro-form-item"
        prop="introduction"
      >
        <el-input
          type="textarea"
          v-model="ruleForm.introduction"
          placeholder="请输入简介"
        ></el-input>
      </el-form-item>
      <el-form-item label="封面" class="cover-form-item" prop="imageUrl">
        <el-upload
          class="avatar-uploader"
          :action="url + '/nft-server/common/uploadImage'"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            （图片大小限制为5MB，图片尺寸为615px*467px)
          </div>
        </el-upload>
        <img v-if="ruleForm.imageUrl" :src="ruleForm.imageUrl" class="avatar" />
        <div v-else class="default-img-box">请上传图片</div>
      </el-form-item>
      <div style="text-align: right;padding-bottom: 5px;">
        <el-button type="primary" size="small" @click="transform()" class="save-btn-common">图片转存</el-button>
      </div>
      <el-form-item class="richText-form-item">
        <Tinymce v-model="ruleForm.context"></Tinymce>
      </el-form-item>
      <el-form-item class="footer-form-item">
        <div class="all-tags-box">
          <span>标签</span>
          <!-- <span v-for="item in Tags" :key="item.ltId">{{ item.ltName }}</span> -->
          <span v-for="(item, index) in Tags" :key="item.ltId">
            <el-tag
              v-show="!item.isShowInput"
              :closable="index != 0"
              :disable-transitions="false"
              @close="handleClose(item)"
              @click="switchTagsFun(item)"
            >
              {{ item.ltName }}
            </el-tag>
            <!-- :autofocus="true" -->
            <el-input
              v-if="item.isShowInput"
              class="input-new-tag"
              v-model="item.ltName"
              :ref="'editorTagInput' + item.ltId"
              size="small"
              @blur="editorInputConfirm(item)"
              maxlength="50"
            >
            </el-input>
          </span>

          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @blur="handleInputConfirm"
            maxlength="50"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >添加标签<i class="el-icon-circle-plus-outline"></i
          ></el-button>
        </div>
        <div class="footer-publish-btn-box">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 1)"
            class="save-btn-common"
            >保存并发布
          </el-button>
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 0)"
            class="save-btn-common"
            >保存
          </el-button>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </div>
      </el-form-item>



    </el-form>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="showDialogText == 0"
        >图片格式错误，请上传bmp，jpg，png，gif，jpeg格式图片</span
      >
      <span v-else>图片过大，上传失败，请重新上传</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisible = false"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="tagDialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span>是否确认删除该标签</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancelTag"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="tagDialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Tinymce from "../../components/VueTinymce/PackVueTinymce";

import Tinymce from "../../components/Tinymce";
export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      ruleForm: {
        title: "",
        auId: "",
        // fromWebsite: "",
        introduction: "",
        imageUrl: "",
        context: "",
        atNa: ["未分类"],
        atIds: "",
        source: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        auId: [{ required: true, message: "请输入作者", trigger: "change" }],
        atNa: [{ required: true, message: "请选择分类", trigger: "change" }],
        fromWebsite: [
          { required: true, message: "请输入抓取网站", trigger: "blur" },
        ],
        introduction: [
          { required: false, message: "请输入简介", trigger: "blur" },
        ],
        imageUrl: [
          { required: true, message: "请上传封面", trigger: "change" },
        ],
      },
      Tags: [],
      inputVisible: false,
      inputValue: "",
      tagDialogVisible: false,
      tmpTag: {},
      detailData: {},
      webList: [],
      authorList: [],
      dialogVisible: false,
      showDialogText: 0,
      url: "",
      headers: "",
      classifyList: [],
    };
  },
  created() {
    this.url = process.env.VUE_APP_BASEURL;
    this.headers = { token: sessionStorage.getItem("token") };
    this.detail = JSON.parse(this.$route.query.row);
    console.log("数据", this.detail);
    this.getWebList();
    this.getAuthor();
    this.getClsaaify();
  },
  methods: {
    transform(){
      this.$Https("/nft-server/article/translate", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.ruleForm.context = res.result;
          let editor =  tinymce.activeEditor;
          editor.setContent(this.ruleForm.context)
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    handleClose(tag) {
      this.tagDialogVisible = true;
      this.tmpTag = tag;
    },
    sureCancelTag() {
      let params = {
        arId: this.detail.arId,
        laType: 1,
        ltId: this.tmpTag.ltId,
      };
      this.$Https("/nft-server/article/remove-label", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功！");
          this.getAllTags(this.detail);
        } else {
          this.$message.error(res.msg);
        }
        this.tagDialogVisible = false;
      });
    },
    switchTagsFun(item) {
      item.isShowInput = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$refs["editorTagInput" + item.ltId][0].$refs.input.focus();
      });
    },
    editorInputConfirm(item) {
      if (item.ltName == "") {
        this.$message.error("标签名称不能为空");
        this.getAllTags(this.detail);
      } else {
        let params = {
          ltId: item.ltId,
          arId: this.detail.arId,
          laType: 1,
          ltName: item.ltName,
        };
        this.$Https("/nft-server/article/edit-label", params).then((res) => {
          if (res.code == 200) {
            this.$message.success("操作成功！");
            this.getAllTags(this.detail);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue.split(" ").join("").length == 0) {
        this.$message.error("标签名称不能为空");
        this.inputVisible = false;
        return;
      }
      let params = {
        arId: this.detail.arId,
        laType: 1,
        ltName: inputValue,
      };
      this.$Https("/nft-server/article/add-label", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getAllTags(this.detail);
        } else {
          this.$message.error(res.msg);
        }
        this.inputVisible = false;
        this.inputValue = "";
      });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    submitForm(formName, val) {
      let _this = this;
      // console.log(this.ruleForm.context,this.ruleForm.context.replace(/<[^>]+>/g, ""))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.ruleForm.atIds = "";
          this.ruleForm.atNa.forEach((item) => {
            this.classifyList.forEach((ele) => {
              if (item == ele.atName) {
                _this.ruleForm.atIds += ele.atId + ",";
              }
            });
          });
          let params = {};
          let ltIds = "";
          this.Tags.forEach((item) => {
            ltIds += item.ltId + ",";
          });
          params = {
            arId: this.detail.arId,
            atIds: this.ruleForm.atIds,
            title: this.ruleForm.title,
            imageUrl: this.ruleForm.imageUrl,
            context: this.ruleForm.context,
            category: 1,
            fromWebsite: this.ruleForm.fromWebsite,
            auId: this.ruleForm.auId,
            introduction: this.ruleForm.introduction,
            source: this.ruleForm.source,
            isRelase: val,
            ltIds: ltIds,
            arTxt: this.ruleForm.context.replace(/<[^>]+>/g, ""),
          };
          this.$Https("/nft-server/articlereptile/update", params).then(
            (res) => {
              if (res.code == 200) {
                this.$message.success("操作成功！");
                this.$router.go(-1);
              } else {
                this.$message.error("操作失败！");
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    getWebList() {
      this.$Http("/nft-server/sysdict/listWebSite").then((res) => {
        if (res.code == 200) {
          this.webList = res.data;
          this.getInMesDetail(this.detail);
        }
      });
    },
    getAllTags(row) {
      this.$Https("/nft-server/articlereptile/label/" + row.arId).then(
        (res) => {
          if (res.code == 200) {
            for (let x in res.list) {
              res.list[x]["isShowInput"] = false;
            }
            this.Tags = res.list;
          }
        }
      );
    },
    getInMesDetail(row) {
      this.$Http("/nft-server/articlereptile/info/" + row.arId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.articleReptile;
          this.ruleForm.title = res.articleReptile.title;
          this.ruleForm.fromWebsite = res.articleReptile.fromWebsite;
          this.ruleForm.source = res.articleReptile.source;
          this.ruleForm.context = res.articleReptile.context;
          this.ruleForm.introduction = res.articleReptile.introduction;
          this.ruleForm.auId =
            res.articleReptile.auId == 0 ? "" : res.articleReptile.auId;
          for (let x in res.articleReptile.lables) {
            res.articleReptile.lables[x]["isShowInput"] = false;
          }
          // res.articleReptile.lables.forEach((item) => {
          //   item.isShowInput = false;
          // });
          this.Tags = res.articleReptile.lables;
          this.ruleForm.imageUrl = res.articleReptile.imageUrl;

          let editor =  tinymce.activeEditor;
          editor.setContent(this.ruleForm.context)

        }
      });
    },
    getAuthor() {
      this.$Https("/nft-server/author/list-all").then((res) => {
        this.authorList = res.list;
      });
    },
    getClsaaify() {
      this.$Https("/nft-server/articletype/list").then((res) => {
        res.data.forEach((item) => {
          if (item.atId == null) {
            item.atId = 0;
          }
        });
        this.classifyList = res.data;
        console.log("分类", this.classifyList);
      });
    },
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.$message.success("上传成功！");
        // this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
        this.ruleForm.imageUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      const isType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp" ||
        file.type === "image/gif" ||
        file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 <= 5;
      console.log(
        file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/bmp" ||
          file.type === "image/gif" ||
          file.type === "image/png"
      );
      if (!isType) {
        this.showDialogText = 0;
        this.dialogVisible = true;
      }
      if (!isLt5M) {
        this.showDialogText = 1;
        this.dialogVisible = true;
      }
      return isLt5M && isType;
    },
  },
};
</script>

<style lang="scss" scoped>
.infoMes-edit-Q {
  .edit-form {
    /deep/ .el-form-item__label {
      font-weight: 300;
      color: #000;
      padding-right: 0.1rem;
      font-size: 12px;
      line-height: 0.24rem;
    }

    /deep/ .el-form-item__content {
      line-height: 0.24rem;

      .el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
      }

      .el-textarea__inner {
        font-size: 0.12rem;
      }
    }

    .common-form-item.el-form-item {
      /deep/ .el-form-item__content {
        width: 3.44rem;

        .el-input__inner {
          padding-left: 0.1rem;
        }
      }
    }

    .select-auid-item {
      /deep/ .el-input__inner {
        padding-left: 0.1rem;
      }

      /deep/ .el-input__prefix {
        left: unset;
        right: 0.3rem;

        .el-input__icon {
          font-weight: bolder;
          line-height: 0.24rem;
        }
      }

      /deep/ .el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;

        &::before {
          content: "\e78f";
        }
      }
      .el-checkbox-button {
        margin-right: 15px;
        &.is-checked {
          /deep/.el-checkbox-button__inner {
            background-color: #a39ff3;
            border-color: #a39ff3;
            box-shadow: none;
          }
        }
        &.is-focus {
          /deep/.el-checkbox-button__inner {
            border-color: transparent;
          }
        }
        /deep/.el-checkbox-button__inner {
          border-radius: 3px;
          padding: 5px 15px;
        }
      }
    }

    .select-web-item {
      /deep/ .el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;

        &::before {
          content: "\e78f";
        }
      }
    }

    .richText-form-item {
      /deep/ .el-form-item__content {
        margin-left: 0 !important;
        .tox-tinymce {
          height: 1000px !important;
        }
      }
    }

    .intro-form-item.el-form-item {
      /deep/ .el-form-item__content {
        width: 6.21rem;

        .el-textarea__inner {
          min-height: 1.57rem !important;
          padding-left: 0.1rem;
        }
      }
    }

    .cover-form-item {
      /deep/ .el-form-item__content {
        .avatar-uploader {
          .el-button {
            font-weight: 400;
            font-size: 12px;
            padding: 0.05rem 0.08rem;
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }

        img,
        .default-img-box {
          width: 2.28rem;
          height: 1.72rem;
          vertical-align: middle;
        }

        .default-img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #999999;
          font-weight: 300;
        }

        .el-upload__tip {
          display: inline-block;
          margin-left: 0.1rem;
          margin-top: 0;
          margin-bottom: 0.14rem;
          font-size: 12px;
        }
      }
    }

    .footer-form-item {
      /deep/ .el-form-item__content {
        margin-left: 0 !important;

        .all-tags-box {
          margin-bottom: 0.1rem;

          & > span {
            margin-right: 0.14rem;
            color: #000;
            font-weight: 400;
            font-size: 0.12rem;
          }

          // span:not(:first-child) {
          //   padding: 0.04rem 0.14rem;
          //   border: 1px solid #999999;
          //   background-color: #fff;
          // }
          .el-tag {
            margin-right: 0.08rem;
            background-color: #ccc;
            border-color: #ccc;
            color: #fff;
            cursor: pointer;
            height: 0.26rem;
            line-height: 0.24rem;

            .el-tag__close {
              color: #fff;
            }
          }

          .active.el-tag {
            background-color: #a39ff3;
          }

          .input-new-tag {
            max-width: 1rem;
          }

          .button-new-tag {
            color: #a39ff3;
            border: none;
            background-color: transparent;
            font-size: 12px;
            padding: 0.05rem 0.08rem;
          }
        }

        .footer-publish-btn-box {
          text-align: center;

          .el-button {
            background-color: #ccc;
            font-weight: 400;
            font-size: 16px;
            padding: 0.08rem 0.14rem;
            border-color: #ccc;
          }

          .save-btn-common {
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="infoMes-edit-Q">
    <!-- <el-form
      ref="addSlide"
      label-position="top"
      class="slideForm"
      :model="slideForm"
      :rules="slideRules"
    >
      <el-form-item>
        <div class="uploadIng">
          <span style="color: #ff0000">*</span>
          <span>NFT轮播图</span>
          <i
            class="el-icon-circle-plus-outline"
            @click="dialogVisible = true"
          ></i>
        </div>
        <div style="display: flex">
          <div class="no_img_box">
            <template v-if="!slideForm.imageUrl">
              <i
                class="fa fa-image"
                style="font-size: 24px; margin-bottom: 10px"
              ></i>
              <span>请上传图片</span>
            </template>
            <template v-else>
              <img
                :src="slideForm.imageUrl"
                style="width: 100%; height: 100%; object-fit: cover"
              />
            </template>
          </div>
          <div
            style="line-height: 180px; padding-left: 30px"
            v-if="slideForm.imageUrl"
          >
            <el-button type="text" @click="delSilde" style="color: #3e35e7"
              >删除</el-button
            >
          </div>
        </div>
      </el-form-item>
    </el-form> -->
    <div class="form-box">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="90px"
          class="add-role-ruleForm"
      >
        <el-form-item label=" " prop="imageUrl">
          <div style="display: flex; align-items: center">
            <div class="upload_img_box">
              <template v-if="!ruleForm.imageUrl">
                <span style="font-size: 12px">图片大小限制为5MB</span>
                <span style="font-size: 12px">图片尺寸为200px*200px</span>
              </template>
              <template v-else>
                <img
                    :src="ruleForm.imageUrl"
                    style="width: 100%; height: 100%; object-fit: cover"
                />
              </template>
            </div>
            <!-- accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF" -->
            <el-upload
                :multiple="false"
                class="upload-btn"
                :action="uploadAction"
                :headers="headers"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
            >
              <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
            <el-button
                class="del_btn"
                type="text"
                @click="delUploadImg"
                v-if="ruleForm.imageUrl"
            >删除
            </el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="艺术品名称" prop="worksName">
          <el-input
              v-model="ruleForm.worksName"
              placeholder="请输入名称"
              maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接地址" prop="linkUrl">
          <el-input
              v-model="ruleForm.linkUrl"
              placeholder="请输入链接"
          ></el-input>
        </el-form-item>
        <el-form-item label="艺术家" prop="artist">
          <el-input
              v-model="ruleForm.artist"
              placeholder="请输入名称"
              maxlength="126"
          ></el-input>
        </el-form-item>
        <el-form-item label="展示平台" prop="displayPlat" maxlength="50">
          <el-input
              v-model="ruleForm.displayPlat"
              placeholder="请输入平台"
          ></el-input>
        </el-form-item>
        <el-form-item label="上链时间" prop="linkTime" class="linkTime-item">
          <el-date-picker
              v-model="ruleForm.linkTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="作品简介" prop="comments">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              placeholder="请输入简介"
              v-model="ruleForm.comments"
              maxlength="1024"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-footer-box">
      <el-button
          type="primary"
          class="save-add-form-btn"
          @click="submitForm('ruleForm')"
      >保存
      </el-button
      >
      <el-button type="primary" @click="resetForm('ruleForm')">取消</el-button>
    </div>
    <el-dialog
        title="新增NFT作品"
        :visible.sync="dialogVisible"
        width="60%"
        center
        :before-close="
        () => {
          this.dialogVisible = false;
        }
      "
    >
      <el-form
          ref="ruleForm"
          class="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
      >
        <el-form-item label=" " prop="">
          <div style="display: flex; align-items: center">
            <div class="upload_img_box">
              <template v-if="!ruleForm.imageUrl">
                <span style="font-size: 14px">图片大小限制为5MB</span>
                <span style="font-size: 14px">图片尺寸为200px*200px</span>
              </template>
              <template v-else>
                <img
                    :src="ruleForm.imageUrl"
                    style="width: 100%; height: 100%; object-fit: cover"
                />
              </template>
            </div>
            <!-- accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF" -->
            <el-upload
                :multiple="false"
                class="upload-btn"
                :action="uploadAction"
                :headers="headers"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
            >
              <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
            <el-button
                class="del_btn"
                type="text"
                @click="delUploadImg"
                v-if="ruleForm.imageUrl"
            >删除
            </el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="艺术品名称" prop="worksName">
          <el-input
              v-model="ruleForm.worksName"
              placeholder="请输入名称"
              maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接地址" prop="linkUrl">
          <el-input
              v-model="ruleForm.linkUrl"
              placeholder="请输入链接"
          ></el-input>
        </el-form-item>
        <el-form-item label="艺术家" prop="artist">
          <el-input
              v-model="ruleForm.artist"
              placeholder="请输入名称"
              maxlength="126"
          ></el-input>
        </el-form-item>
        <el-form-item label="展示平台" prop="displayPlat" maxlength="50">
          <el-input
              v-model="ruleForm.displayPlat"
              placeholder="请输入平台"
          ></el-input>
        </el-form-item>
        <el-form-item label="上链时间" prop="linkTime">
          <el-date-picker
              v-model="ruleForm.linkTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="作品简介" prop="comments">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              placeholder="请输入简介"
              v-model="ruleForm.comments"
              maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="save" @click="save">保存</el-button>
        <el-button class="cancel" @click="cancelUpload">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleTips"
        width="30%"
        center
        custom-class="user-for-reset-dialog"
    >
      <span v-if="showDialogText == 1"
      >图片格式错误，请上传bmp，jpg，png，gif，jpeg格式图片</span
      >
      <span v-else>图片过大，上传失败，请重新上传</span>
      <span slot="footer" class="dialog-footer">
        <el-button
            type="primary"
            @click="dialogVisibleTips = false"
            class="dialog-footer-sure-btn"
        >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisibleTips = false"
        >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
var validateImg = (rule, value, callback) => {
  if (value == '' || value == null || value == undefined) {
    callback(new Error('请上传封面'))
  }
  callback()
}

export default {
  data() {
    return {
      slideForm: {
        imageUrl: "",
      },
      allImageUrl: "",
      slideRules: {
        imageUrl: [{required: true}],
      },
      dialogVisible: false,
      uploadAction:
          process.env.VUE_APP_BASEURL + "/nft-server/common/uploadImage",
      headers: {token: sessionStorage.getItem("token")},
      ruleForm: {
        imageUrl: "",
        worksName: "",
        linkUrl: "",
        artist: "",
        displayPlat: "",
        linkTime: "",
        comments: "",
        nwId: "",
      },
      delNwId: undefined,
      uploadImageUrl: "",
      rules: {
        imageUrl: [{required: true, validator: validateImg}],
        worksName: [{required: true, message: "请输入名称"}],
        artist: [{required: true, message: "请输入名称"}],
        displayPlat: [{required: true, message: "请输入平台"}],
        linkTime: [{required: true, message: "请输入上链时间"}],
        comments: [{required: true, message: "请输入简介"}],
        linkUrl: [{required: true, message: "请输入链接"}],
      },
      dialogVisibleTips: false,
      showDialogText: 0,
      detail: {},
      isAdd: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    if (this.$route.query.row) {
      this.detail = JSON.parse(this.$route.query.row);
      this.ruleForm.nwId = this.detail.nwId;
      this.isAdd = false;
      this.ruleForm.imageUrl = this.detail.imageUrl;
      this.ruleForm.worksName = this.detail.worksName;
      this.ruleForm.linkUrl = this.detail.linkUrl;
      this.ruleForm.artist = this.detail.artist;
      this.ruleForm.displayPlat = this.detail.displayPlat;
      this.ruleForm.linkTime = this.detail.linkTime;
      this.ruleForm.comments = this.detail.comments;
      // this.dialogVisible = true;
    } else {
      this.isAdd = true;
    }
  },
  methods: {
    beforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 5;
      if (!isRightSize) {
        // this.$message.error('文件大小超过 5MB')
        this.showDialogText = 0;
        this.dialogVisibleTips = true;
      }
      // let isAccept = new RegExp('image/*').test(file.type)
      let isAccept =
          file.type == "image/jpeg" ||
          file.type == "image/jpg" ||
          file.type == "image/bmp" ||
          file.type == "image/gif" ||
          file.type == "image/png";
      if (!isAccept) {
        // this.$message.error("应该选择图片类型的文件");
        this.showDialogText = 1;
        this.dialogVisibleTips = true;
      }
      return isRightSize && isAccept;
    },
    uploadSuccess(response) {
      if (response.code == 200) {
        this.ruleForm.imageUrl = response.imageUrl;
        this.$refs.ruleForm.clearValidate('imageUrl')
      } else {
        this.$message({message: response.msg, type: "error"});
      }
    },
    delUploadImg() {
      this.ruleForm.imageUrl = "";
    },
    cancelUpload() {
      this.ruleForm.imageUrl = "";
      this.ruleForm.worksName = "";
      this.ruleForm.linkUrl = "";
      this.ruleForm.artist = "";
      this.ruleForm.displayPlat = "";
      this.ruleForm.linkTime = "";
      this.ruleForm.comments = "";
      this.dialogVisible = false;
    },
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return;
        let url = "";
        if (this.isAdd) {
          url = "/nft-server/nftworks/save";
        } else {
          url = "/nft-server/nftworks/update";
        }
        this.$Https(url, this.ruleForm).then((res) => {
          if (res.code == 200) {
            this.slideForm.imageUrl = this.ruleForm.imageUrl;
            this.cancelUpload();
            this.$message.success("操作成功");
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
          this.$refs.ruleForm.clearValidate();
        });
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.isAdd) {
            url = "/nft-server/nftworks/save";
          } else {
            url = "/nft-server/nftworks/update";
          }
          this.$Https(url, this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.slideForm.imageUrl = this.ruleForm.imageUrl;
              this.cancelUpload();
              this.$message.success("操作成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
            this.$refs.ruleForm.clearValidate();
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    delSilde() {
      this.$Https("/nft-server/nftworks/delete", {nwId: this.delNwId}).then(
          (res) => {
            if (res.code == 200) {
              this.slideForm.imageUrl = "";
              this.cancelUpload();
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.msg);
            }
          }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.infoMes-edit-Q {
  padding: 0.5rem 0.25rem;
  background-color: #fff;
  min-height: calc(100vh - 0.8rem - 1.85rem);
  margin-bottom: 0.3rem;

  .slideForm {
    .uploadIng {
      color: #000000;
      display: flex;
      align-items: center;

      i {
        font-size: 24px;
        color: #4940e8;
        margin-left: 5px;
        cursor: pointer;
      }

      /deep/ .el-upload.el-upload--text {
        display: flex;
        align-items: center;
      }
    }

    .no_img_box {
      width: 182px;
      height: 182px;
      border: 1px solid #999999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #666666;
    }
  }

  .add-role-ruleForm {
    .el-form-item {
      /deep/ .el-form-item__label {
        font-size: 12px;
        line-height: 0.24rem;
      }

      /deep/ .el-form-item__content {
        line-height: 0.24rem;

        .el-input__inner {
          font-size: 0.12rem;
          height: 0.24rem;
          line-height: 0.24rem;
        }

        .el-textarea__inner {
          font-size: 0.12rem;
        }
      }
    }

    .linkTime-item {
      /deep/ .el-input__inner {
        padding-left: 0.15rem;
        padding-right: 0.15rem;
      }

      /deep/ .el-input__prefix {
        // right: 5px;
        // left: unset;
        left: 0.02rem;

        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
    }

    .upload_img_box {
      width: 182px;
      height: 182px;
      border: 1px solid #999999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #666666;
    }

    .el-input {
      width: 400px;
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
      border-color: #a39ff3;
    }

    .upload-btn {
      margin: 0 0.16rem;

      .el-button {
        background: #a39ff3;
        border: none;
      }
    }

    .del_btn {
      color: #a39ff3;
    }

    .el-textarea {
      width: 6.18rem;
    }

    .el-dialog__title {
      font-size: 14px;
      font-weight: 600;
    }

    .save {
      background: #a39ff3;
      color: #ffffff;
    }

    .cancel {
      background: #c9c9c9;
      color: #ffffff;
    }
  }

  .form-footer-box {
    text-align: center;

    .el-button {
      font-size: 16px;
      font-weight: 400;
      padding: 0.08rem 0.24rem;
      background-color: #c9c9c9;
      border-color: #c9c9c9;
    }

    .save-add-form-btn {
      background-color: #a39ff3;
      border-color: #a39ff3;
      margin-right: 0.16rem;
    }
  }
}
</style>

<template>
  <div class="infoMes-manage-page">
    <div class="tags-box">
      <el-tag
        :class="item.ntId == activeAtId ? 'active' : ''"
        v-for="item in tags"
        :key="item.ntId"
        :closable="item.ntId != null && item.ntId != ''"
        :disable-transitions="false"
        @close="handleClose(item)"
        @click="switchTagsFun(item)"
      >
        <template v-if="item.ntId !== ''">
          {{ item.ntName }}({{ item.navigationNums }})
        </template>
        <template v-else>
          {{ item.ntName }}
        </template>
      </el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @blur="handleInputConfirm"
        maxlength="10"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput"
        >添加分类<i class="el-icon-circle-plus-outline"></i
      ></el-button>
    </div>
    <div class="page-head-btn clearfixed">
      <el-button type="primary" @click="addWebFun" class="add-flash-btn"
        >添加网站<i class="el-icon-s-platform"></i
      ></el-button>
    </div>
    <div class="page-top">
      <el-form :inline="true" :model="searchFormQ" class="search-form">
        <el-form-item label="网站名称" class="title-form-item">
          <el-input
            v-model="searchFormQ.naName"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="网站链接" class="title-form-item">
          <el-input
            v-model="searchFormQ.naLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="发布状态"
          class="select-state-item select-common-item"
        >
          <el-select v-model="searchFormQ.isRelase" placeholder="请选择">
            <el-option
              v-for="item in editorList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="分类" class="select-state-item select-common-item">
          <el-select v-model="searchFormQ.ntId" placeholder="请选择">
            <el-option
              v-for="item in tags"
              :key="item.ntId"
              :label="item.ntName"
              :value="item.ntId"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <el-button type="primary" @click="searchFun">搜索</el-button>
    </div>
    <div class="page-head-btn clearfixed move-classify-select-box">
      <el-checkbox
        class="selectAll"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选
      </el-checkbox>
      <el-select
        v-model="bindClassify"
        placeholder="移动分类"
        class="bindClassify-select"
        @change="changeClassify"
      >
        <el-option
          v-for="item in tagsCopy"
          :key="item.ntId"
          :label="item.ntName"
          :value="item.ntId"
        ></el-option>
      </el-select>
    </div>
    <el-table
      :data="tableDataQ"
      style="width: 100%"
      @selection-change="selectRow"
      ref="bindTable"
      class="role-table"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" label="序号" width="150px" align="center">
      </el-table-column>
      <el-table-column prop="naName" label="网站名称" align="center">
      </el-table-column>
      <el-table-column prop="naLink" label="网站链接" align="center">
      </el-table-column>
      <el-table-column
        prop="isRelase"
        label="发布状态"
        class-name="state-column"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <span
            :class="scope.row.isRelase == 0 ? 'forbidden-state' : 'start-state'"
          ></span> -->
          <span v-if="scope.row.isRelase == 1">已上架</span>
          <span v-else class="no-editor">未上架</span>
        </template>
      </el-table-column>
      <el-table-column prop="ntName" label="分类" align="center">
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
        min-width="260"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row)" class="editor-span">编辑</span>
          <span @click="clickCancel(scope.row)" class="editor-span">删除</span>
          <span
            @click="clickRelase(scope.row, 'isRelase')"
            v-if="scope.row.isRelase"
            class="editor-span"
            >下架</span
          ><span
            @click="clickRelase(scope.row, 'isRelase')"
            v-else
            class="editor-span"
            >上架</span
          >
          <span
            v-if="scope.row.isRecommend == 1"
            @click="clickRelase(scope.row, 'isRecommend')"
            class="ifValid-span"
            >取消推荐</span
          >
          <span
            v-else
            @click="clickRelase(scope.row, 'isRecommend')"
            class="ifValid-span"
            >特别推荐</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-show="tableDataQ.length > 0"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchFormQ.currentPage"
      :page-size="searchFormQ.pageSize"
      layout="prev, pager, next, slot"
      :total="totalQ"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="searchFormQ.jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="!moveTips">是否确认删除该网站</span>
      <span v-if="moveTips == 1"
        >勾选的所有网站都将被移动到<span style="color: #f00">{{
          selectClassify.ntName
        }}</span
        >下，是否确认操作？</span
      >
      <span v-if="moveTips == 2">是否确认删除该分类</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancel"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moveTips: 0,
      webList: [],
      searchFormQ: {
        naName: "",
        naLink: "",
        isRelase: "",
        ntId: "",
        pageSize: 10,
        currentPage: 1,
        jumperPage: 1,
      },
      checkAll: false,
      isIndeterminate: false,
      editorList: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "已上架",
        },
        {
          id: 0,
          label: "未上架",
        },
      ],
      tableDataQ: [],
      currentPage: 1,
      totalQ: 0,
      tableDataA: [],
      totalA: 0,
      dialogVisible: false,
      cancelTmpItem: {},
      tags: [],
      inputVisible: false,
      inputValue: "",
      naIds: "",
      bindClassify: "",
      selectClassify: "",
      activeAtId: "",
      tagsCopy: [],
      tmpTag: {},
    };
  },
  created() {
    this.getNavigationType();
    this.getWebList();
    this.getListQ();
  },
  methods: {
    getNavigationType() {
      this.$Http("/nft-server/navigationtype/list").then((res) => {
        if (res.code == 200) {
          this.tags = [{ ntId: "", ntName: "全部" }, ...res.data];
          this.tagsCopy = res.data;
        }
      });
    },
    handleClose(tag) {
      if (tag.navigationNums) {
        this.$message.error("该分类下有网站，不可删除！");
        return;
      }
      this.moveTips = 2;
      this.dialogVisible = true;
      this.tmpTag = tag;
    },
    deleteClassify() {
      let params = {
        ntId: this.tmpTag.ntId,
      };
      this.$Https("/nft-server/navigationtype/delete", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          // this.tags.splice(this.tags.indexOf(this.tmpTag), 1);
          this.getNavigationType();
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    switchTagsFun(item) {
      if (item.ntId == null) {
        this.searchFormQ.ntId = 0;
      } else {
        this.searchFormQ.ntId = item.ntId;
      }
      this.activeAtId = item.ntId;
      this.getListQ();
    },

    showInput() {
      if (this.tags.length == 10) {
        this.$message.error("最多添加9个分类！");
        return;
      }
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue.split(" ").join("").length == 0) {
        this.$message.error("分类名称不能为空");
        this.inputVisible = false;
        return;
      }
      let params = {
        ntName: inputValue,
      };
      this.$Https("/nft-server/navigationtype/save", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.inputVisible = false;
          this.inputValue = "";
          this.getNavigationType();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addWebFun() {
      this.$router.push("/navgationAdd");
    },
    clickEditor(row) {
      if(row.isRelase==1){
        this.$message.error("请先将网站下架后执行编辑操作");
        return;
      }
      this.$router.push({
        path: "/navgationAdd",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    handleCheckAllChange(val) {
      this.$refs.bindTable.toggleAllSelection();
    },
    selectRow(val) {
      this.naIds = "";
      if (val.length > 0) {
        val.forEach((item) => {
          this.naIds += item.naId + ",";
        });
      } else {
        this.naIds = "";
      }
      if (
        val.length > 0 &&
        val.length < this.searchFormQ.pageSize &&
        val.length != this.tableDataQ.length
      ) {
        this.isIndeterminate = true;
      } else if (
        val.length == this.searchFormQ.pageSize ||
        val.length == this.tableDataQ.length && val.length 
      ) {
        this.isIndeterminate = false;
        this.checkAll = true;
      } else if (val.length == 0) {
        this.isIndeterminate = false;
        this.checkAll = false;
      }
    },
    changeClassify(val) {
      if (!this.naIds) {
        this.$message.warning("请先选择网站！");
        this.bindClassify = "";
        return;
      }
      this.selectClassify = this.tags.find((item) => item.ntId == val);
      this.moveTips = 1;
      this.dialogVisible = true;
    },
    moveClassify() {
      let params = {
        ntId: this.bindClassify,
        naIds: this.naIds,
      };
      this.$Https("/nft-server/navigation/doWithNavigationType", params).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("操作成功！");
            this.getNavigationType();
            this.getListQ();
            this.isIndeterminate = false;
            this.checkAll = false;
          } else {
            this.$message.error(res.msg);
          }
          this.dialogVisible = false;
        }
      );
    },
    getWebList() {
      this.$Http("/nft-server/sysdict/listWebSite").then((res) => {
        if (res.code == 200) {
          this.webList = res.data;
        }
      });
    },
    searchFun() {
      this.getListQ();
    },
    sureCancel() {
      if (!this.moveTips) {
        this.cancelInfoMes();
      } else if (this.moveTips == 1) {
        this.moveClassify();
      } else if (this.moveTips == 2) {
        this.deleteClassify();
      }
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.searchFormQ.currentPage = val;
      this.getListQ();
    },
    jumperPageFun() {
      this.searchFormQ.currentPage = this.searchFormQ.jumperPage;
      this.getListQ();
    },
    clickCancel(row) {
      if (row.isRelase == 1) {
        this.$message.error("请先将网站下架后执行删除操作");
        return;
      }
      this.dialogVisible = true;
      this.cancelTmpItem = row;
    },
    cancelInfoMes() {
      let tmp = { naId: this.cancelTmpItem.naId };
      this.$Https("/nft-server/navigation/delete", tmp).then((res) => {
        if (res.code == 200) {
          this.getListQ();
          this.$message.success("删除成功！");
        } else {
          this.$message.error("删除异常！");
        }
        this.dialogVisible = false;
      });
    },
    clickRelase(row, val) {
      let params = {
        naId: row.naId,
        [val]: row[val] == 0 ? 1 : 0,
      };
      this.$Https("/nft-server/navigation/update", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.getListQ();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    clickSurePublishFun(row, val) {
      let params = {
        arId: row.arId,
      };
      this.$Https("/nft-server/articlereptile/release", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("发布成功！");
          if (val == 0) {
            this.getListQ();
          }
        } else {
          this.$message.error("发布失败！");
        }
      });
    },
    getListQ() {
      this.$Https("/nft-server/navigation/list", this.searchFormQ).then(
        (res) => {
          if (res.code == 200) {
            this.tableDataQ = res.page.data;
            this.currentPage = res.page.totalPage;
            this.totalQ = res.page.totalNumber;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-box {
  .el-tag {
    margin-right: 0.08rem;
    background-color: #ccc;
    color: #fff;
    cursor: pointer;
    height: 0.26rem;
    line-height: 0.24rem;
    /deep/ .el-tag__close {
      color: #fff;
    }
  }
  .active.el-tag {
    background-color: #a39ff3;
  }

  .input-new-tag {
    max-width: 1rem;
  }

  .button-new-tag {
    color: #a39ff3;
    border: none;
    background-color: transparent;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
  }
}

.page-head-btn {
  margin-bottom: 0.16rem;

  .selectAll {
    float: left;
    line-height: 0.24rem;
    margin-right: 0.08rem;
    /deep/.el-checkbox__label {
      font-size: 12px;
    }
  }

  .bindClassify-select {
    width: 1.7rem;

    /deep/ .el-input__inner {
      padding-left: 0.05rem;
      border-color: #999;
      font-size: 0.12rem;
      height: 0.24rem;
      line-height: 0.24rem;
    }
    /deep/ .el-icon-arrow-up {
      color: #000;
      line-height: 0.24rem;
      &::before {
        content: "\e78f";
      }
    }
  }

  .common-btn {
    float: left;
    background-color: #ccc;
    font-weight: 400;
    font-size: 14px;
    padding: 0.05rem 0.08rem;
    border-color: #ccc;
  }

  .active-btn {
    background-color: #a39ff3;
    border-color: #a39ff3;
  }

  .add-flash-btn {
    font-size: 14px;
    font-weight: 400;
    padding: 0.05rem 0 0.05rem 0.08rem;
    float: right;
    background-color: transparent;
    color: #4940e8;
    border-color: transparent;
  }
}
.move-classify-select-box {
  padding-left: 0;
}

.page-top {
  display: flex;
  .search-form {
    // float: left;
    margin-right: 15px;
    .el-form-item {
      margin-right: 0.4rem;

      /deep/ .el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
      }

      /deep/ .el-input__inner {
        padding-left: 0.05rem;
        border-color: #999;
        padding-right: 0.15rem;
        line-height: 0.24rem;
        font-size: 0.12rem;
        height: 0.24rem;
      }

      /deep/ .el-input__icon {
        width: 0.15rem;
      }
    }

    .title-form-item {
      /deep/ .el-input__inner {
        width: 2.3rem;
      }
    }

    .publish-form-item {
      /deep/ .datetime-split-text {
        text-align: center;
      }

      /deep/ .el-date-editor.el-input {
        width: 1.6rem;
      }

      /deep/ .el-input__prefix {
        right: 5px;
        left: unset;
      }
    }

    .select-state-item {
      /deep/ .el-form-item__content {
        width: 0.85rem;
      }
    }

    .select-web-item {
      /deep/ .el-form-item__content {
        width: 1.2rem;
      }
    }

    .select-isSift-item {
      /deep/ .el-form-item__content {
        width: 0.8rem;
      }
    }

    .select-common-item {
      /deep/ .el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
  }

  .el-button {
    // float: right;
    height: fit-content;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}

.role-table.el-table {
  margin-bottom: 0.4rem;

  &::before {
    background-color: #fff;
  }

  /deep/ thead {
    color: #fff;

    th {
      background-color: #a39ff3;
      font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;

      &:first-child > .cell {
        display: none;
      }
    }
  }

  /deep/ td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 12px;
  }

  .state-column {
    span {
      vertical-align: middle;
    }

    .no-editor {
      color: #f00;
    }

    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.13rem;
      width: 0.14rem;
      height: 0.14rem;
      border-radius: 50%;
    }

    .forbidden-state {
      background-color: #ebebeb;
    }

    .start-state {
      background-color: #a39ff3;
    }
  }

  .operation-column {
    span {
      cursor: pointer;
      display: inline-block;
    }

    span:not(:last-child) {
      margin-right: 0.16rem;
    }

    .editor-span {
      color: #a39ff3;
    }

    .ifValid-span {
      color: #ff3696;
    }
  }
}

.custom-pagination.el-pagination {
  text-align: center;

  &.is-background {
    /deep/ .el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;

        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }

  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 14px;
    font-weight: 300;
    color: #000;

    .jumper-input {
      width: 0.5rem;

      /deep/ .el-input__inner {
        height: 28px;
      }
    }
  }

  /deep/ button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>

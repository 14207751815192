<template>
  <div class="infoMes-manage-page">
    <div class="page-head-btn clearfixed">
      <el-button type="primary" @click="addAuthorFun" class="add-flash-btn"
        >添加作者<i class="el-icon-edit-outline"></i
      ></el-button>
    </div>
    <div class="page-top clearfixed">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="作者" class="title-form-item">
          <el-input
            v-model="searchForm.auName"
            placeholder="请输入作者"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchFun">搜索</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :default-sort="{ prop: 'readNum', order: 'descending' }"
      @sort-change="customSort"
      class="role-table"
    >
      <el-table-column type="index" label="序号" width="80" align="center">
      </el-table-column>
      <el-table-column prop="auName" label="作者" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
      </el-table-column>
      <el-table-column prop="artNums" label="文章数" align="center">
      </el-table-column>
      <el-table-column
        prop="readNum"
        label="阅读量"
        sortable="custom"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.readNum == null">0</span>
          <span v-else>{{ scope.row.readNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row)" class="editor-span">编辑</span>
          <span @click="clickCancel(scope.row)" class="editor-span">删除</span>
          <span
            @click="clickForbidden(scope.row)"
            v-if="scope.row.isDisable == 0"
            class="editor-span"
            >禁用</span
          >
          <span @click="clickForbidden(scope.row)" v-else class="ifValid-span"
            >启用</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.length > 0"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, slot"
      :total="totalNumber"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="!switchForReset">是否确认删除该作者</span>
      <div v-else class="reset-box">是否确认禁用该作者</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancel"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        auName: "",
        pageSize: 10,
        currentPage: 1,
        readNumOrder: 0,
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalNumber: 0,
      jumperPage: 1,
      dialogVisible: false,
      cancelTmpItem: {},
      switchForReset: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    customSort(column) {
      this.searchForm.readNumOrder = column.order == "ascending" ? 1 : 0;
      this.getList();
    },
    getList() {
      this.$Https("/nft-server/author/list", this.searchForm).then((res) => {
        if (res.code == 200) {
          this.tableData = res.page.list;
          this.totalNumber = res.page.totalCount;
          this.currentPage = res.page.currPage;
        } else {
          this.$message.error("查询失败！");
        }
      });
    },
    addAuthorFun() {
      this.$router.push("/authorAdd");
    },
    searchFun() {
      this.getList();
    },
    clickEditor(row) {
      this.$router.push({
        path: "/authorAdd",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    clickCancel(row) {
      if (row.artNums != 0) {
        this.$message.error("该作者在系统中有文章存在，不可删除");
        return;
      }
      this.switchForReset = 0;
      this.dialogVisible = true;
      this.cancelTmpItem = row;
    },
    sureCancel() {
      if (this.switchForReset == 0) {
        this.cancelInfoMes(this.cancelTmpItem);
      } else {
        this.forbiddenAuthor(this.cancelTmpItem);
      }
    },
    cancelInfoMes(row) {
      let tmp = {
        auId: row.auId,
        auName: row.auName,
        introduce: row.introduce,
        imageUrl: row.imageUrl,
        artNums: row.artNums,
        thumbsUpNums: row.thumbsUpNums,
        fansNums: row.fansNums,
      };
      this.$Https("/nft-server/author/delete", tmp).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$message.success("删除成功！");
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    forbiddenAuthor(row) {
      let tmp = {
        auId: row.auId,
        isDisable: row.isDisable == 0 ? 1 : 0,
      };
      this.$Https("/nft-server/author/update", tmp).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    clickForbidden(row, val) {
      this.cancelTmpItem = row;
      this.switchForReset = 1;
      this.dialogVisible = true;
    },
    clickResetPass(row, val) {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getList();
    },
    jumperPageFun() {
      this.searchForm.currentPage = this.jumperPage;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-head-btn {
  margin-bottom: 0.16rem;

  .common-btn {
    float: left;
    background-color: #ccc;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #ccc;
  }

  .active-btn {
    background-color: #a39ff3;
    border-color: #a39ff3;
  }

  .add-flash-btn {
    font-size: 12px;
    font-weight: 400;
    padding: 0.05rem 0 0.05rem 0.08rem;
    float: right;
    background-color: transparent;
    color: #4940e8;
    border-color: transparent;
  }
}

.page-top {
  .search-form {
    float: left;

    .el-form-item {
      margin-right: 0.5rem;

      /deep/ .el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
      }

      /deep/ .el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
        padding-left: 0.05rem;
        border-color: #999;
      }
    }

    .title-form-item {
      /deep/ .el-input__inner {
        width: 2.5rem;
      }
    }

    .publish-form-item {
      /deep/ .el-date-editor.el-input {
        width: 1.7rem;
      }

      /deep/ .el-input__prefix {
        right: 5px;
        left: unset;
      }
    }

    .select-state-item {
      /deep/ .el-form-item__content {
        width: 0.85rem;
      }
    }

    .select-web-item {
      /deep/ .el-form-item__content {
        width: 1.2rem;
      }
    }

    .select-isSift-item {
      /deep/ .el-form-item__content {
        width: 0.8rem;
      }
    }

    .select-common-item {
      /deep/ .el-icon-arrow-up {
        color: #000;

        &::before {
          content: "\e78f";
        }
      }
    }
  }

  .el-button {
    float: right;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}

.role-table.el-table {
  margin-bottom: 0.4rem;

  &::before {
    background-color: #fff;
  }

  /deep/ thead {
    color: #fff;

    th {
      background-color: #a39ff3;
      font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;
    }
    th.is-sortable {
      .sort-caret.ascending {
        top: 4px;
      }
      .sort-caret.descending {
        bottom: 4px;
      }
    }
    th.descending {
      .sort-caret.descending {
        border-top-color: #fff;
      }
    }
    th.ascending {
      .sort-caret.ascending {
        border-bottom-color: #fff;
      }
    }
  }

  /deep/ td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 12px;
  }

  .state-column {
    span {
      vertical-align: middle;
    }

    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.13rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
    }

    .forbidden-state {
      background-color: #ebebeb;
    }

    .start-state {
      background-color: #a39ff3;
    }
  }

  .operation-column {
    span {
      cursor: pointer;
    }

    span:not(:last-child) {
      margin-right: 0.16rem;
    }

    .editor-span {
      color: #a39ff3;
    }

    .ifValid-span {
      color: #ff3696;
    }
  }
}

.custom-pagination.el-pagination {
  text-align: center;

  &.is-background {
    /deep/ .el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;

        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }

  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 14px;
    font-weight: 300;
    color: #000;

    .jumper-input {
      width: 0.5rem;

      /deep/ .el-input__inner {
        height: 28px;
      }
    }
  }

  /deep/ button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
const roleManage=()=>import('../views/role/roleManage.vue')
const roleAdd=()=>import('../views/role/roleAdd.vue')
const backstageUserManage=()=>import('../views/backstageUser/backstageUserManage.vue')
const backstageUserAdd=()=>import('../views/backstageUser/backstageUserAdd.vue')
const registerUserManage=()=>import('../views/registerUser/registerUserManage.vue')
const infoMesManageP=()=>import('../views/infoMes/infoMesManageP.vue')
const infoMesAddPQ=()=>import('../views/infoMes/infoMesAddPQ.vue')
const infoMesAddPA=()=>import('../views/infoMes/infoMesAddPA.vue')
const infoMesList=()=>import('../views/infoMes/infoMesList.vue')
const infoMesEditQ=()=>import('../views/infoMes/infoMesEditQ.vue')
const infoMesEditA=()=>import('../views/infoMes/infoMesEditA.vue')
const specialClassify=()=>import('../views/infoMes/specialClassify.vue')
const specialClassifyAdd=()=>import('../views/infoMes/specialClassifyAdd.vue')
const specialClassifyBind=()=>import('../views/infoMes/specialClassifyBind.vue')
const authorManage=()=>import('../views/infoMes/authorManage.vue')
const authorAdd=()=>import('../views/infoMes/authorAdd.vue')
const navgationManage=()=>import('../views/navgationManage/navgationManage.vue')
const navgationAdd=()=>import('../views/navgationManage/navgationAdd.vue')
const worksManage=()=>import('../views/worksManage/worksManage.vue')
const worksAdd=()=>import('../views/worksManage/worksAdd.vue')
const webSet=()=>import('../views/systemSet/webSet.vue')
const webAbout=()=>import('../views/systemSet/webAbout.vue')
const privacy=()=>import('../views/systemSet/privacy.vue')
const serviceAgreement=()=>import('../views/systemSet/serviceAgreement.vue')
const changePassword=()=>import('../views/systemSet/changePassword.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
    meta:{
      isLogin:true
    }
  },
  {
    path: '/roleManage',
    name: 'roleManage',
    component: roleManage,
    meta:{
      b_Data:[
        {
          label:'用户管理',
        },
        {
          label:'角色列表',
          path:'/roleManage'
        }
      ]
    }
  },
  {
    path: '/roleAdd',
    name: 'roleAdd',
    component: roleAdd,
    meta:{
      b_Data:[
        {
          label:'用户管理',
        },
        {
          label:'角色列表',
          path:'/roleManage'
        },
        {
          label:'添加角色',
          path:'/roleAdd'
        }
      ],
      isSub:'/roleManage'
    }
  },
  {
    path: '/backstageUserManage',
    name: 'backstageUserManage',
    component: backstageUserManage,
    meta:{
      b_Data:[
        {
          label:'用户管理',
        },
        {
          label:'后台用户管理',
          path:'/backstageUserManage'
        }
      ]
    }
  },
  {
    path: '/backstageUserAdd',
    name: 'backstageUserAdd',
    component: backstageUserAdd,
    meta:{
      b_Data:[
        {
          label:'用户管理',
        },
        {
          label:'后台用户管理',
          path:'/backstageUserManage'
        },
        {
          label:'添加用户',
          path:'/backstageUserAdd'
        }
      ],
      isSub:'/backstageUserManage'
    }
  },
  {
    path: '/registerUserManage',
    name: 'registerUserManage',
    component: registerUserManage,
    meta:{
      b_Data:[
        {
          label:'用户管理',
        },
        {
          label:'注册用户管理',
          path:'/registerUserManage'
        },
      ]
    }
  },
  {
    path: '/infoMesManageP',
    name: 'infoMesManageP',
    component: infoMesManageP,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'资讯发布管理',
          path:'/infoMesManageP'
        }
      ]
    }
  },
  {
    path: '/infoMesAddPQ',
    name: 'infoMesAddPQ',
    component: infoMesAddPQ,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'资讯发布管理',
          path:'/infoMesManageP'
        },
        {
          label:'新建快讯',
          path:'/infoMesAddPQ'
        }
      ],
      isSub:'/infoMesManageP'
    }
  },
  {
    path: '/infoMesAddPA',
    name: 'infoMesAddPA',
    component: infoMesAddPA,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'资讯发布管理',
          path:'/infoMesManageP'
        },
        {
          label:'新建文章',
          path:'/infoMesAddPA'
        }
      ],
      isSub:'/infoMesManageP'
    }
  },
  {
    path: '/infoMesList',
    name: 'infoMesList',
    component: infoMesList,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'资讯抓取列表',
          path:'/infoMesList'
        }
      ]
    }
  },
  {
    path: '/infoMesEditQ',
    name: 'infoMesEditQ',
    component: infoMesEditQ,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'资讯抓取列表',
          path:'/infoMesList'
        },
        {
          label:'编辑快讯',
          path:'/infoMesEditQ'
        }
      ],
      isSub:'/infoMesList'
    }
  },
  {
    path: '/infoMesEditA',
    name: 'infoMesEditA',
    component: infoMesEditA,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'资讯抓取列表',
          path:'/infoMesList'
        },
        {
          label:'编辑文章',
          path:'/infoMesEditA'
        }
      ],
      isSub:'/infoMesList'
    }
  },
  {
    path: '/specialClassify',
    name: 'specialClassify',
    component: specialClassify,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'专题分类设置',
          path:'/specialClassify'
        },
      ]
    }
  },
  {
    path: '/specialClassifyAdd',
    name: 'specialClassifyAdd',
    component: specialClassifyAdd,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'专题分类设置',
          path:'/specialClassify'
        },
        {
          label:'新增专题',
          path:'/specialClassifyAdd'
        }
      ],
      isSub:'/specialClassify'
    }
  },
  {
    path: '/specialClassifyBind',
    name: 'specialClassifyBind',
    component: specialClassifyBind,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'专题分类设置',
          path:'/specialClassify'
        },
        {
          label:'绑定文章',
          path:'/specialClassifyBind'
        }
      ],
      isSub:'/specialClassify'
    }
  },
  {
    path: '/authorManage',
    name: 'authorManage',
    component: authorManage,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'作者管理',
          path:'/authorManage'
        },
      ]
    }
  },
  {
    path: '/authorAdd',
    name: 'authorAdd',
    component: authorAdd,
    meta:{
      b_Data:[
        {
          label:'资讯管理',
        },
        {
          label:'作者管理',
          path:'/authorManage'
        },
        {
          label:'添加作者',
          path:'/authorAdd'
        },
      ],
      isSub:'/authorManage'
    }
  },
  {
    path: '/navgationManage',
    name: 'navgationManage',
    component: navgationManage,
    meta:{
      b_Data:[
        {
          label:'导航管理',
          path:'/navgationManage'
        },
      ]
    }
  },
  {
    path: '/navgationAdd',
    name: 'navgationAdd',
    component: navgationAdd,
    meta:{
      b_Data:[
        {
          label:'导航管理',
          path:'/navgationManage'
        },
        {
          label:'添加网站',
          path:'/navgationAdd'
        },
      ],
      isSub:'/navgationManage'
    }
  },
  {
    path: '/worksManage',
    name: 'worksManage',
    component: worksManage,
    meta:{
      b_Data:[
        {
          label:'NFT作品管理',
          path:'/worksManage'
        },
      ]
    }
  },
  {
    path: '/worksAdd',
    name: 'worksAdd',
    component: worksAdd,
    meta:{
      b_Data:[
        {
          label:'NFT作品管理',
          path:'/worksManage'
        },
        {
          label:'添加作品',
          path:'/worksAdd'
        },
      ],
      isSub:'/worksManage'
    }
  },
  {
    path: '/webSet',
    name: 'webSet',
    component: webSet,
    meta:{
      b_Data:[
        {
          label:'系统设置',
        },
        {
          label:'网站设置',
          path:'/webSet'
        },
      ]
    }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword,
    meta:{
      b_Data:[
        {
          label:'系统设置',
        },
        {
          label:'修改密码',
          path:'/changePassword'
        },
      ]
    }
  },
  {
    path: '/webAbout',
    name: 'webAbout',
    component: webAbout,
    meta:{
      b_Data:[
        {
          label:'系统设置',
        },
        {
          label:'关于我们',
          path:'/webAbout'
        },
      ]
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy,
    meta:{
      b_Data:[
        {
          label:'系统设置',
        },
        {
          label:'隐私政策',
          path:'/privacy'
        },
      ]
    }
  },
  {
    path: '/serviceAgreement',
    name: 'serviceAgreement',
    component: serviceAgreement,
    meta:{
      b_Data:[
        {
          label:'系统设置',
        },
        {
          label:'服务协议',
          path:'/serviceAgreement'
        },
      ]
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router

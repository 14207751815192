import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMenu:false,
    defaultPath:'',
    breadcrumbData:[],
    menu:[]
  },
  mutations: {
    saveShowMenu(state,val){
      state.showMenu=val;
    },
    saveDefaultPath(state,val){
      state.defaultPath=val;
    },
    saveBreadcrumbData(state,val){
      state.breadcrumbData=val;
    },
    saveMenu(state,val){
      state.menu=val;
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div class="infoMes-edit-Q">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      class="edit-form"
    >
      <el-form-item label="快讯标题" class="common-form-item" prop="title">
        <el-input v-model="ruleForm.title" placeholder="请输入标题" maxlength="100"></el-input>
      </el-form-item>
     <!-- <el-form-item label="作者" class="select-auid-item" prop="auId">
        <el-select v-model="ruleForm.auId" filterable placeholder="请选择">
          <el-option
            v-for="item in authorList"
            :key="item.auId"
            :label="item.auName"
            :value="item.auId"
          ></el-option>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-select>
      </el-form-item>-->
      <el-form-item label="来源" class="common-form-item" prop="source">
        <el-input
          v-model="ruleForm.source"
          placeholder="请输入数据来源"
        ></el-input>
      </el-form-item>
      <div style="text-align: right;padding-bottom: 5px;">
        <el-button type="primary" size="small" @click="transform()" class="save-btn-common">图片转存</el-button>
      </div>
      <el-form-item class="richText-form-item">
          <Tinymce v-model="ruleForm.context"></Tinymce>
        
      </el-form-item>
      <el-form-item class="footer-form-item">
        <div class="footer-publish-btn-box">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 1)"
            class="save-btn-common"
            >保存并发布</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 0)"
            class="save-btn-common"
            >保存</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import Tinymce from "../../components/VueTinymce/PackVueTinymce";
import Tinymce from "../../components/Tinymce";

export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      ruleForm: {
        title: "",
        auId: null,
        context: "",
        category: "",
        isRelase: 0,
        arId: "",
        source:""
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        // auId: [{ required: true, message: "请输入作者", trigger: "change" }],
        source: [
          { required: false, message: "请输入数据来源", trigger: "blur" },
        ],
      },
      detailData: {},
      authorList: [],
      detail: {},
      isAdd: true,
    };
  },
  created() {
    if (this.$route.query.category) {
      this.ruleForm.category = this.$route.query.category;
    }
    if (this.$route.query.row) {
      this.detail = JSON.parse(this.$route.query.row);
      this.ruleForm.auId = this.detail.auId;
      this.ruleForm.arId = this.detail.arId;
      this.ruleForm.category = this.detail.category;
      this.getInMesDetail(this.detail);
      this.isAdd = false;
    } else {
      this.isAdd = true;
    }
    this.getAuthor();
  },
  methods: {
    submitForm(formName, val) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (val == 0) {
            this.ruleForm.isRelase = 0;
          } else {
            this.ruleForm.isRelase = 1;
          }
          if (this.isAdd) {
            this.addInfoMes();
          } else {
            this.editorInfoMes();
          }
        } else {
          return false;
        }
      });
    },
    transform(){
      this.$Https("/nft-server/article/translate", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.ruleForm.context = res.result;
          let editor =  tinymce.activeEditor;
          editor.setContent(this.ruleForm.context)
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    addInfoMes() {
      this.ruleForm.arTxt=this.ruleForm.context.replace(/<[^>]+>/g, "");
      this.$Https("/nft-server/article/save", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    editorInfoMes() {
      this.ruleForm.arTxt=this.ruleForm.context.replace(/<[^>]+>/g, "");
      this.$Https("/nft-server/article/update", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    getInMesDetail(row) {
      this.$Http("/nft-server/article/info/" + row.arId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.article;
          this.ruleForm.title = res.article.title;
          this.ruleForm.context = res.article.context;
          this.ruleForm.auId = res.article.auId;
          this.ruleForm.source = res.article.source;
          let editor =  tinymce.activeEditor;
          editor.setContent(this.ruleForm.context)
        }
      });
    },
    getAuthor() {
      this.$Https("/nft-server/author/list-all").then((res) => {
        this.authorList = res.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.infoMes-edit-Q {
  .edit-form {
    /deep/.el-form-item__label {
      font-weight: 300;
      color: #000;
      padding-right: 0.1rem;
      font-size: 12px;
      line-height: 0.24rem;
    }
    /deep/.el-form-item__content {
      line-height: 0.24rem;
      .el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
      }
      .el-textarea__inner {
        font-size: 0.12rem;
      }
    }
    .common-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 3.44rem;
        .el-input__inner {
          padding-left: 0.1rem;
        }
      }
    }
    .select-auid-item {
      /deep/.el-input__inner {
        padding-left: 0.1rem;
      }
      /deep/.el-input__prefix {
        left: unset;
        right: 0.3rem;
        .el-input__icon {
          font-weight: bolder;
          line-height: 0.24rem;
        }
      }
      /deep/.el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
    .richText-form-item {
      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
    }
    .footer-form-item {
      /deep/.el-form-item__content {
        margin-left: 0 !important;
        .all-tags-box {
          span {
            margin-right: 0.14rem;
            color: #000;
            font-weight: 400;
            font-size: 0.14rem;
          }
          span:not(:first-child) {
            padding: 0.04rem 0.14rem;
            border: 1px solid #999999;
            background-color: #fff;
          }
        }
        .footer-publish-btn-box {
          text-align: center;
          .el-button {
            background-color: #ccc;
            font-weight: 400;
            font-size: 16px;
            padding: 0.08rem 0.14rem;
            border-color: #ccc;
          }
          .save-btn-common {
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="infoMes-manage-page">
    <div class="page-top">
      <el-form :inline="true" :model="searchFormQ" class="search-form">
        <el-form-item label="标题" class="title-form-item">
          <el-input
            v-model="searchFormQ.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布时间" class="publish-form-item">
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择开始日期"
            v-model="searchFormQ.relaseStartTime"
          ></el-date-picker>
          <!-- </el-col> -->
          <span class="datetime-split-text" :span="2">至</span>
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择结束日期"
            v-model="searchFormQ.relaseEndTime"
          ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item
          label="抓取网站"
          class="select-web-item select-common-item"
        >
          <el-select v-model="searchFormQ.fromWebSite" placeholder="请选择">
            <el-option
              v-for="item in webList"
              :key="item.dictCode"
              :label="item.dictName"
              :value="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="发布状态"
          class="select-state-item select-common-item"
        >
          <el-select v-model="searchFormQ.isRelase" placeholder="请选择">
            <el-option
              v-for="item in editorList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchFun">搜索</el-button>
    </div>
    <div class="page-head-btn clearfixed">
      <el-checkbox
        class="selectAll"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <el-button
        type="primary"
        @click="bindArticleFun"
        class="common-btn active-btn"
        >绑定文章</el-button
      >
      <el-button type="primary" @click="unboundFun" class="common-btn"
        >解除绑定</el-button
      >
    </div>
    <el-table
      :data="tableDataQ"
      style="width: 100%"
      @selection-change="selectRow"
      ref="bindTable"
      class="role-table"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="80" align="center">
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center" min-width="220">
      </el-table-column>
      <el-table-column prop="createTime" label="发布时间" align="center">
      </el-table-column>
      <el-table-column prop="fromWebsiteName" label="抓取网站" align="center">
      </el-table-column>
      <el-table-column
        prop="isRelase"
        label="发布状态"
        class-name="state-column"
        align="center"
        width="95"
      >
        <template slot-scope="scope">
          <!-- <span
            :class="scope.row.isRelase == 0 ? 'forbidden-state' : 'start-state'"
          ></span> -->
          <span v-if="scope.row.isRelase == 1">已上架</span>
          <span v-else class="no-editor">未上架</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.isBinding"
            @click="clickBind(scope.row, 1)"
            class="editor-span"
            >解除绑定</span
          >
          <span v-else @click="clickBind(scope.row, 0)" class="ifValid-span"
            >绑定</span
          >
          <!-- <span v-else style="min-width: 23px"></span> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-show="tableDataQ.length > 0"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchFormQ.currentPage"
      :page-size="searchFormQ.pageSize"
      layout="prev, pager, next, slot"
      :total="totalQ"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="searchFormQ.jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span>请选择文章</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancel"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      webList: [],
      searchFormQ: {
        title: "",
        relaseStartTime: "",
        relaseEndTime: "",
        isRelase: "",
        fromWebSite: "",
        pageSize: 10,
        currentPage: 1,
        jumperPage: 1,
        spId: "",
      },
      checkAll: false,
      isIndeterminate: false,
      editorList: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "已上架",
        },
        {
          id: 0,
          label: "未上架",
        },
      ],
      tableDataQ: [],
      totalQ: 0,
      tableDataA: [],
      totalA: 0,
      dialogVisible: false,
      cancelTmpItem: {},
      artIds: "",
      detail: {},
      isBind: 0,
    };
  },
  created() {
    this.detail = JSON.parse(this.$route.query.row);
    this.searchFormQ.spId = this.detail.spId;
    this.getWebList();
    this.getListQ();
  },
  methods: {
    handleCheckAllChange(val) {
      this.$refs.bindTable.toggleAllSelection();
    },
    selectRow(val) {
      this.artIds = "";
      if (val.length > 0) {
        val.forEach((item) => {
          this.artIds += item.arId + ",";
        });
      } else {
        this.artIds = "";
      }
      if (val.length > 0 && val.length < this.searchFormQ.pageSize && val.length != this.tableDataQ.length) {
        this.isIndeterminate = true;
      } else if (val.length == this.searchFormQ.pageSize || val.length == this.tableDataQ.length && val.length ) {
        this.isIndeterminate = false;
        this.checkAll = true;
      } else if (val.length == 0) {
        this.isIndeterminate = false;
        this.checkAll = false;
      }
    },
    getWebList() {
      this.$Http("/nft-server/sysdict/listWebSite").then((res) => {
        if (res.code == 200) {
          this.webList = [{ dictCode: "", dictName: "全部" }, ...res.data];
        }
      });
    },
    unboundFun() {
      if (!this.artIds) {
        this.dialogVisible = true;
        return;
      }
      this.isBind = 1;
      this.clickBind();
    },
    bindArticleFun() {
      if (!this.artIds) {
        this.dialogVisible = true;
        return;
      }
      this.isBind = 0;
      this.clickBind();
    },
    searchFun() {
      this.getListQ();
    },
    sureCancel() {
      this.dialogVisible = false;
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.searchFormQ.jumperPage = val;
      this.getListQ();
    },
    jumperPageFun() {
      this.searchFormQ.currentPage = this.searchFormQ.jumperPage;
      this.getListQ();
    },
    clickCancel(row, val) {
      this.dialogVisible = true;
      this.cancelTmpItem = row;
    },
    cancelInfoMes() {
      let tmp = { arId: this.cancelTmpItem.arId };
      this.$Https("/nft-server/articlereptile/delete", tmp).then((res) => {
        if (res.code == 200) {
          this.getListQ();
          this.isIndeterminate = false;
          this.checkAll = false;
          this.$message.success("删除成功！");
        } else {
          this.$message.error("删除异常！");
        }
        this.dialogVisible = false;
      });
    },
    clickBind(row, val) {
      let url = "";
      let params = {};
      if (row) {
        params = {
          spId: this.searchFormQ.spId,
          artIds: row.arId + "",
        };
        url =
          val == 1
            ? "/nft-server/special/deleteBinding"
            : "/nft-server/special/addBinding";
      } else {
        params = {
          spId: this.searchFormQ.spId,
          artIds: this.artIds,
        };
        url =
          this.isBind == 1
            ? "/nft-server/special/deleteBinding"
            : "/nft-server/special/addBinding";
      }
      this.$Https(url, params).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.getListQ();
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    getListQ() {
      this.$Https("/nft-server/special/articleList", this.searchFormQ).then(
        (res) => {
          if (res.code == 200) {
            this.tableDataQ = res.page.data;
            this.totalQ = res.page.totalNumber;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page-head-btn {
  margin-bottom: 0.16rem;
  .selectAll {
    float: left;
    line-height: 0.24rem;
    margin-right: 0.08rem;
    /deep/.el-checkbox__label {
      font-size: 12px;
    }
  }
  .common-btn {
    float: left;
    background-color: #ccc;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #ccc;
  }
  .active-btn {
    background-color: #a39ff3;
    border-color: #a39ff3;
  }
}
.page-top {
  display: flex;
  .search-form {
    // float: left;
    margin-right: 15px;
    .el-form-item {
      margin-right: 0.4rem;
      /deep/.el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
      }
      /deep/.el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        padding-left: 0.05rem;
        border-color: #999;
        padding-right: 0.15rem;
      }
      /deep/.el-input__icon {
        width: 0.15rem;
        line-height: 0.24rem;
      }
    }
    .title-form-item {
      /deep/.el-input__inner {
        width: 2.3rem;
      }
    }
    .publish-form-item {
      /deep/ .el-input__inner {
        padding-left: 0.15rem;
        padding-right: 0.15rem;
      }
      /deep/.datetime-split-text {
        text-align: center;
        font-size: 12px;
      }
      /deep/.el-date-editor.el-input {
        width: 1.65rem;
      }
      /deep/.el-input__prefix {
        // right: 5px;
        // left: unset;
        left: 0.02rem;
        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
      /deep/ .el-input__suffix {
        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
    }
    .select-state-item {
      /deep/.el-form-item__content {
        width: 0.85rem;
      }
    }
    .select-web-item {
      /deep/.el-form-item__content {
        width: 1.2rem;
      }
    }
    .select-isSift-item {
      /deep/.el-form-item__content {
        width: 0.8rem;
      }
    }
    .select-common-item {
      /deep/.el-icon-arrow-up {
        color: #000;
        &::before {
          content: "\e78f";
        }
      }
    }
  }
  .el-button {
    // float: right;
    height: fit-content;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}
.role-table.el-table {
  margin-bottom: 0.4rem;
  &::before {
    background-color: #fff;
  }
  /deep/thead {
    color: #fff;
    th {
      background-color: #a39ff3;
     font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;
      &:first-child > .cell {
        display: none;
      }
    }
  }
  /deep/td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 12px;
  }
  .state-column {
    span {
      vertical-align: middle;
    }
    .no-editor {
      color: #f00;
    }
    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.13rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
    }
    .forbidden-state {
      background-color: #ebebeb;
    }
    .start-state {
      background-color: #a39ff3;
    }
  }
  .operation-column {
    span {
      cursor: pointer;
      display: inline-block;
    }
    span:not(:last-child) {
      margin-right: 0.16rem;
    }
    .editor-span {
      color: #a39ff3;
    }
    .ifValid-span {
      color: #ff3696;
    }
  }
}
.custom-pagination.el-pagination {
  text-align: center;
  &.is-background {
    /deep/.el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;
        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }
  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 14px;
    font-weight: 300;
    color: #000;
    .jumper-input {
      width: 0.5rem;
      /deep/.el-input__inner {
        height: 28px;
      }
    }
  }
  /deep/button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>
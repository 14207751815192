<template>
  <div class="sys-header clearfixed">
    <div class="sys-header-left">
      <div class="sys-logo-title-box" @click="goIndex">
        <div class="sys-logo-box">
          <img class="img-clear" src="../../assets/image/logo.png" alt />
        </div>
        <!-- <div class="sys-title-box">元宇宙之心管理后台</div> -->
      </div>
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        class="sys-breadcrumb-box"
        v-if="showHeaderRight"
      >
        <el-breadcrumb-item
          v-for="item in breadcrumbData"
          :key="item.path"
          :to="{ path: item.path ? item.path : '' }"
          >{{ item.label }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="sys-header-right" v-if="showHeaderRight">
      <el-popover
        placement="left"
        trigger="hover"
        popper-class="custom-userphoto-pop"
      >
        <div class="login-password-box">
          <span @click="loginOut">退出登录</span>
          <span @click="changePassword">修改密码</span>
        </div>
        <div slot="reference" class="user-photo-box">
          <img src="../../assets/image/head.png" alt />
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    showHeaderRight() {
      return (
        this.$store.state.showMenu ||
        sessionStorage.getItem("showMenu") == "true"
      );
    },
    breadcrumbData() {
      return this.$store.state.breadcrumbData;
    },
  },
  created() {},
  methods: {
    goIndex() {
      if (!sessionStorage.getItem("token")) {
        return;
      }
      let path =
        JSON.parse(sessionStorage.getItem("menu")) ||
        JSON.parse(this.$store.state.menu);
      // let pathCopy = JSON.parse(this.$store.state.menu);
      // if (path) {
      if (!path[0].ofUrl) {
        this.$router.push({
          path: path[0].childFuncs[0].ofUrl,
        });
      } else {
        this.$router.push({
          path: path[0].ofUrl,
        });
      }
      // } else {
      //   this.$router.push({
      //     path: pathCopy[0].ofUrl,
      //   });
      // }
    },
    loginOut() {
      this.$router.push("/");
      window.sessionStorage.clear();
      this.$store.commit("saveShowMenu", false);
      this.$store.commit("saveBreadcrumbData", []);
      sessionStorage.setItem("showMenu", false);
    },
    changePassword() {
      this.$router.push({
        path: "/",
        query: {
          reuse: true,
        },
      });
      this.$store.commit("saveShowMenu", false);
      sessionStorage.setItem("showMenu", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.sys-header {
  padding: 0.22rem 0.68rem 0.18rem 1.2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  .sys-header-left {
    float: left;
    .sys-logo-title-box {
      margin-right: 0.55rem;
      display: inline-block;
      cursor: pointer;
      & > div {
        display: inline-block;
        vertical-align: middle;
      }
      .sys-logo-box {
        // width: 1.1rem;
        width: 2rem;
        height: 0.4rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .sys-title-box {
        min-width: 1.9rem;
        height: 0.5rem;
        line-height: 0.5rem;
        background-color: #707070;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        text-align: center;
      }
    }
    .sys-breadcrumb-box.el-breadcrumb {
      display: inline-block;
      vertical-align: middle;
      /deep/.el-breadcrumb__item {
        &:first-child {
          .el-breadcrumb__inner {
            cursor: default;
          }
        }
        &:last-child {
          .el-breadcrumb__inner {
            border-bottom: 0.02rem solid #a39ff3;
          }
        }
        .el-icon-arrow-right {
          font-size: 14px;
          color: #000;
          font-weight: bolder;
          margin: 0;
        }
        .el-breadcrumb__inner {
          font-size: 14px;
          font-weight: 400;
          color: #000;
          cursor: pointer;
        }
      }
    }
  }
  .sys-header-right {
    float: right;
    .user-photo-box {
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
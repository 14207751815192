<!--
 * @Author: your name
 * @Date: 2021-11-19 10:25:04
 * @LastEditTime: 2021-11-19 10:33:47
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \NFT\nft-admin\src\views\systemSet\serviceAgreement.vue
-->
<template>
  <div>
    <Tinymce v-model="ruleForm.dictName" class="editor"></Tinymce>
    <div class="footer-publish-btn-box">
      <el-button
          type="primary"
          @click="saveAboutContent"
          class="save-btn-common"
      >保存
      </el-button
      >
      <el-button @click="cancelAboutContent">取消</el-button>
    </div>
  </div>
</template>

<script>
// import Tinymce from "../../components/VueTinymce/PackVueTinymce";
import Tinymce from "../../components/Tinymce/PackVueTinymce.vue";

export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      ruleForm: {
        dictName: "",
        dictId: "",
        dictCode: 300,
        parentCode: null,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$Http("/nft-server/sysdict/service").then((res) => {
        if (res.code == 200) {
          this.ruleForm = res.data;
        } else {
          this.$message.error("获取失败！");
        }
      });
    },
    saveAboutContent() {
      this.$Https("/nft-server/sysdict/service-save", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    cancelAboutContent() {
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  /deep/ .mce-edit-area {
    iframe {
      min-height: calc(100vh - 150px);
    }
  }
}

.footer-publish-btn-box {
  text-align: center;
  margin-top: 0.3rem;

  .el-button {
    background-color: #ccc;
    font-weight: 400;
    font-size: 16px;
    padding: 0.08rem 0.14rem;
    border-color: #ccc;
  }

  .save-btn-common {
    background-color: #a39ff3;
    border-color: #a39ff3;
  }
}
</style>

<template>
  <div class="specialClassify-page">
    <div class="page-head-btn clearfixed">
      <el-button type="primary" @click="addSpecialFun" class="add-flash-btn"
        >新增专题<i class="el-icon-circle-plus-outline"></i
      ></el-button>
    </div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="专题" class="title-form-item">
          <el-input
            v-model="searchForm.title"
            placeholder="请输入专题"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间" class="publish-form-item">
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择开始日期"
            v-model="searchForm.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="datetime-split-text" :span="2">至</el-col> -->
          <span class="datetime-split-text" :span="2">至</span>
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择结束日期"
            v-model="searchForm.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item
          label="编辑状态"
          class="select-state-item select-common-item"
        >
          <el-select v-model="searchForm.isRelease" placeholder="请选择">
            <el-option
              v-for="item in editorList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchFun">搜索</el-button>
    </div>
    <div class="special-list-box">
      <div class="special-list-box-copy">
        <div v-for="item in listData" :key="item.spId" class="list-item-box">
          <div class="list-item-img-box">
            <img :src="item.coverUrl" alt="" />
          </div>
          <div class="title-titme-box">
            <div>
              <span class="title">{{ item.title }}</span>
            </div>
            <div class="time-box">
              <div>
                <span class="title-extra">已绑定文章数(</span>
              <span class="bind-num">{{ item.acticleNums }}</span>
              <span class="title-extra">)</span>
              </div>
              <div class="time">
                {{ item.createTime }}发布
              </div>
            </div>
          </div>
          <div class="operation-box">
            <span @click="clickEditor(item)">编辑</span
            ><span @click="clickRelase(item, 'isRelease')" v-if="item.isRelease"
              >下架</span
            ><span @click="clickRelase(item, 'isRelease')" v-else>上架</span
            ><span @click="clickCancelSpecial(item)">删除</span
            ><span @click="clickRelase(item, 'isTop')" v-if="item.isTop == 0"
              >置顶</span
            ><span @click="clickRelase(item, 'isTop')" v-if="item.isTop == 1"
              >取消置顶</span
            ><span @click="clickBindArticalFun(item)">绑定文章</span>
          </div>
        </div>
        <div
          v-if="listData.length == 0"
          style="width: 100%; text-align: center"
        >
          暂无数据
        </div>
      </div>
    </div>
    <el-pagination
      v-if="listData.length > 0"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, slot"
      :total="total"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span>是否确认删除该专题</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureAlter"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeModule: 0,
      searchForm: {
        title: "",
        startTime: "",
        endTime: "",
        pageSize: 8,
        currentPage: 1,
        isRelease: "",
      },
      editorList: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "已上架",
        },
        {
          id: 0,
          label: "未上架",
        },
      ],
      listData: [],
      currentPage: 1,
      pageSize: 8,
      total: 100,
      jumperPage: 1,
      dialogVisible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$Https("/nft-server/special/list", this.searchForm).then((res) => {
        if (res.code == 200) {
          this.listData = res.page.data;
          this.currentPage = res.page.currentPage;
          this.total = res.page.totalNumber;
        } else {
          this.$message.error("查询失败！");
        }
        this.dialogVisible = false;
      });
    },
    addSpecialFun() {
      this.$router.push("/specialClassifyAdd");
    },
    clickBindArticalFun(row) {
      this.$router.push({
        path: "/specialClassifyBind",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    searchFun() {
      this.getList();
    },
    clickEditor(row) {
      if (row.isRelease) {
        this.$message.error("请先将专题下架后执行编辑操作");
        return;
      }
      this.$router.push({
        path: "/specialClassifyAdd",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    clickRelase(row, val) {
      let params = {
        title: row.title,
        introduce: row.introduce,
        coverUrl: row.coverUrl,
        detailUrl: row.detailUrl,
        spId: row.spId,
        [val]: row[val] == 0 ? 1 : 0,
      };
      this.$Https("/nft-server/special/update", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    clickCancelSpecial(row) {
      if (row.isRelease) {
        this.$message.error("请先将专题下架后执行删除操作");
        return;
      }
      this.dialogVisible = true;
      this.deleteSpId = row.spId;
    },
    cancelSpecial() {
      let params = {
        spId: this.deleteSpId,
      };
      this.$Https("/nft-server/special/delete", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功！");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    sureAlter() {
      this.cancelSpecial();
      this.getList();
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getList();
    },
    jumperPageFun() {
      this.searchForm.currentPage = this.jumperPage;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.specialClassify-page {
  .special-list-box {
    background-color: #fff;
    padding: 0.35rem 0.38rem;
    margin-bottom: 0.3rem;

    .special-list-box-copy {
      display: flex;
      flex-wrap: wrap;
      margin-left: -0.2rem;
      margin-right: -0.2rem;

      .list-item-box {
        width: 25%;
        padding: 0 0.2rem;
        margin-bottom: 0.38rem;

        .list-item-img-box {
          margin-bottom: 0.08rem;

          img {
            display: block;
            width: 100%;
            height: 1.84rem;
          }
        }

        .title-titme-box {
          // display: flex;
          // justify-content: space-between;
          color: #000;
          margin-bottom: 0.08rem;

          .title {
            font-weight: bold;
            font-size: 15px;
          }

          .title-extar {
            font-weight: 300;
          }

          .bind-num {
            font-weight: bold;
          }

          .time-box {
            font-size: 0.12rem;
            font-weight: 300;
            margin-top: 0.08rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .time {
            }
          }
        }

        .operation-box {
          span {
            cursor: pointer;
            font-weight: 300;
            color: #4940e8;
            font-size: 12px;
          }
          span:not(:last-child) {
            margin-right: 0.2rem;
          }
        }
      }
    }
  }
}

.page-head-btn {
  margin-bottom: 0.2rem;

  .common-btn {
    float: left;
    background-color: #ccc;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #ccc;
  }

  .active-btn {
    background-color: #a39ff3;
    border-color: #a39ff3;
  }

  .add-flash-btn {
    font-size: 12px;
    font-weight: 400;
    padding: 0.05rem 0 0.05rem 0.08rem;
    float: right;
    background-color: transparent;
    color: #4940e8;
    border-color: transparent;
  }
}

.page-top {
  display: flex;
  .search-form {
    // float: left;
    margin-right: 15px;
    .el-form-item {
      margin-right: 0.5rem;

      /deep/ .el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
        .el-input__inner {
          font-size: 0.12rem;
          height: 0.24rem;
          line-height: 0.24rem;
        }
      }

      /deep/ .el-input__inner {
        padding-left: 0.05rem;
        border-color: #999;
      }
    }

    .title-form-item {
      /deep/ .el-input__inner {
        width: 2.5rem;
      }
    }

    .publish-form-item {
      /deep/ .el-input__inner {
        padding-left: 0.15rem;
        padding-right: 0.15rem;
      }
      /deep/ .datetime-split-text {
        text-align: center;
        font-size: 0.12rem;
      }

      /deep/ .el-date-editor.el-input {
        width: 1.65rem;
      }

      /deep/.el-input__prefix {
        // right: 5px;
        // left: unset;
        left: 0.02rem;
        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
      /deep/ .el-input__suffix {
        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
    }

    .select-state-item {
      /deep/ .el-form-item__content {
        width: 0.85rem;
      }
    }

    .select-web-item {
      /deep/ .el-form-item__content {
        width: 1.2rem;
      }
    }

    .select-isSift-item {
      /deep/ .el-form-item__content {
        width: 0.8rem;
      }
    }

    .select-common-item {
      /deep/ .el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
  }

  .el-button {
    // float: right;
    height: fit-content;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}

.role-table.el-table {
  margin-bottom: 0.4rem;

  &::before {
    background-color: #fff;
  }

  /deep/ thead {
    color: #fff;

    th {
      background-color: #a39ff3;
      font-size: 18px;
      font-weight: 500;
      padding: 20px 0;
    }
  }

  /deep/ td {
    font-weight: 300;
    padding: 20px 0;
    color: #000;
  }

  .state-column {
    span {
      vertical-align: middle;
    }

    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.13rem;
      width: 0.14rem;
      height: 0.14rem;
      border-radius: 50%;
    }

    .forbidden-state {
      background-color: #ebebeb;
    }

    .start-state {
      background-color: #a39ff3;
    }
  }

  .operation-column {
    span {
      cursor: pointer;
    }

    span:not(:last-child) {
      margin-right: 0.3rem;
    }

    .editor-span {
      color: #a39ff3;
    }

    .ifValid-span {
      color: #ff3696;
    }
  }
}

.custom-pagination.el-pagination {
  text-align: center;

  &.is-background {
    /deep/ .el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;

        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }

  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 14px;
    font-weight: 300;
    color: #000;

    .jumper-input {
      width: 0.5rem;

      /deep/ .el-input__inner {
        height: 28px;
      }
    }
  }

  /deep/ button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>

<template>
  <div class="role-manage-page">
    <div class="page-top clearfixed">
      <el-button type="primary" @click="addRoleFun">添加角色</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" class="role-table">
      <el-table-column type="index" label="序号" width="150px" align="center">
      </el-table-column>
      <el-table-column prop="roleName" label="角色名称" align="center">
      </el-table-column>
      <el-table-column prop="roleComment" label="描述" align="center">
      </el-table-column>
      <el-table-column
        prop="isDisabled"
        label="状态"
        class-name="state-column"
        align="center"
      >
        <template slot-scope="scope">
          <span
            :class="scope.row.isDisabled == 1 ? 'forbidden-state' : 'start-state'"
          ></span>
          <span v-if="scope.row.isDisabled == 1">已禁用</span>
          <span v-else>已启用</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row)" class="editor-span">编辑</span
          ><span
            @click="clickForbidden(scope.row)"
            v-if="scope.row.isDisabled == 1"
            class="isDisabled-span"
            >启用</span
          >
          <span v-else @click="clickForbidden(scope.row)" class="isDisabled-span"
            >禁用</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      v-if="tableData.length > 0"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, slot"
      :total="total"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination> -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span
        >是否确认禁用该用户，禁用后，用户将无法正常使用</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancel(forbiddenTmpItem)"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 100,
      jumperPage: 1,
      dialogVisible:false,
      forbiddenTmpItem:{}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$Http("/nft-server/sysrole/list").then((res) => {
        if (res.code == 200) {
          this.tableData = res.page;
        }
      });
    },
    addRoleFun() {
      this.$router.push("/roleAdd");
    },
    clickEditor(row) {
      this.$router.push({
        path: "/roleAdd",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    clickForbidden(row) {
      if(row.isDisabled==1){
        this.sureCancel(row);
      } else{
      this.dialogVisible=true;
      this.forbiddenTmpItem = row;
      }
    },
    sureCancel(row){
      let tmp = { roleId: row.roleId};
      this.$Https("/nft-server/sysrole/open-close-role", tmp).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.message);
        }
        this.dialogVisible = false;
      });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {},
    jumperPageFun() {
      this.currentPage = this.jumperPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-top {
  margin-bottom: 0.2rem;
  .el-button {
    float: right;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}
.role-table.el-table {
  margin-bottom: 0.4rem;
  &::before {
    background-color: #fff;
  }
  /deep/thead {
    color: #fff;
    th {
      background-color: #a39ff3;
      font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;
    }
  }
  /deep/td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 0.12rem;
  }
  .state-column {
    span {
      vertical-align: middle;
    }
    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.09rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
    }
    .forbidden-state {
      background-color: #ebebeb;
    }
    .start-state {
      background-color: #a39ff3;
    }
  }
  .operation-column {
    span {
      cursor: pointer;
    }
    .editor-span {
      margin-right: 0.16rem;
      color: #a39ff3;
    }
    .isDisabled-span {
      color: #ff3696;
    }
  }
}
.custom-pagination.el-pagination {
  text-align: center;
  &.is-background {
    /deep/.el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;
        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }
  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 12px;
    font-weight: 300;
    color: #000;
    .jumper-input {
      width: 0.5rem;
      /deep/.el-input__inner {
        height: 28px;
      }
    }
  }
  /deep/button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>
<template>
  <div class="menu-box">
    <p class="sys-name">元宇宙之心管理后台</p>
    <el-menu
      class="custom-menu"
      :default-active="defaultPath"
      @open="handleOpen"
      @select="handSelect"
      text-color="#000000"
      active-text-color="#3E35E7"
      :collapse="isCollapse"
      router
    >
      <!-- <el-submenu index="1">
        <template slot="title">
          <span>导航一</span>
        </template>
        <el-menu-item index="1-1">选项1</el-menu-item>
        <el-menu-item index="1-2">选项2</el-menu-item>
        <el-menu-item index="1-3">选项3</el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <span>导航二</span>
        </template>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <span>导航三</span>
        </template>
        <el-menu-item index="3-1">选项1</el-menu-item>
        <el-menu-item index="3-2">选项2</el-menu-item>
        <el-menu-item index="3-3">选项3</el-menu-item>
      </el-submenu>-->
      <!-- :index="item.ofUrl?item.ofUrl:item.ofId+''" -->
      <!-- <el-menu-item
        v-for="item in menuList"
        v-if="item.childFuncs.length==0"
        :key="item.ofId"
        :index="item.ofUrl"
        >{{ item.ofName }}</el-menu-item
      >
      <el-submenu
      v-if="item.childFuncs.length>0"
        v-for="item in menuList"
        :key="item.ofId"
        :index="item.ofUrl ? item.ofUrl : item.ofId + ''"
      >
        <template slot="title" v-if="item.childFuncs.length > 0">
          <span>{{ item.ofName }}</span>
        </template>
        <el-menu-item
          v-for="subItem in item.childFuncs"
          :key="subItem.ofId"
          :index="subItem.ofUrl"
          >{{ subItem.ofName }}</el-menu-item
        >
      </el-submenu> -->

      <template v-for="(item, index) in menuList">
        <!-- 标题 -->
        <template v-if="item.childFuncs.length">
          <el-submenu
            :key="item.ofId"
            :index="item.ofUrl ? item.ofUrl : item.ofId + ''"
          >
            <template slot="title">
              <span>{{ item.ofName }}</span>
            </template>
            <el-menu-item
              v-for="subItem in item.childFuncs"
              :key="subItem.ofId"
              :index="subItem.ofUrl"
              >{{ subItem.ofName }}</el-menu-item
            >
          </el-submenu>
        </template>
        <!-- 选项 -->
        <template v-else>
          <el-menu-item :key="index" :index="item.ofUrl">
            <span>{{ item.ofName }}</span>
            <i class="el-icon-caret-right"></i>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <!-- <span v-show="!isCollapse" class="menu-fold-btn" @click="switchCollapse(1)"><i class="el-icon-d-arrow-left"></i></span>
    <span v-show="isCollapse" class="menu-fold-btn" @click="switchCollapse(0)"><i class="el-icon-d-arrow-right"></i></span> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      menuList: [],
      defaultPath: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.meta.isSub) {
        this.defaultPath = to.meta.isSub;
      } else {
        this.defaultPath = to.path;
      }
      this.$store.commit("saveBreadcrumbData", to.meta.b_Data);
      // console.log("watch",this.defaultPath,  to,from)
    },
  },
  created() {
    this.getMenuList();
  },
  methods: {
    getMenuList() {
      this.$Http("/nft-server/orgfunc/listRoleByUser").then((res) => {
        if (res.code == 200) {
          this.menuList = res.data;
          this.$store.commit("saveMenu", JSON.stringify(this.menuList));
          sessionStorage.setItem("menu", JSON.stringify(this.menuList));
        }
      });
    },
    handleOpen(index, path) {
      console.log("index", index, path);
    },
    handSelect(index, path) {
      console.log("select", index, path);
    },
    switchCollapse(val) {
      if (val) {
        this.isCollapse = true;
      } else {
        this.isCollapse = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-box {
  padding: 0.36rem 0 0;
  .menu-fold-btn {
    cursor: pointer;
    position: absolute;
    top: 0.8rem;
  }
  .sys-name {
    font-size: 18px;
    font-weight: 500;
    color: #3e35e7;
    letter-spacing: 1px;
    padding-left: 1.3rem;
    img {
      width: 3.3rem;
      height: 0.58rem;
    }
  }
  .custom-menu.el-menu {
  padding: 0 0.26rem 0 1.3rem;
    border-right: none;
    & > li {
      border-bottom: 1px solid #cdcdcd;
    }
    & > li.is-opened {
      border-bottom: none;
      /deep/.el-submenu__title {
        border-bottom: 1px solid #cdcdcd;
      }
    }
    .el-menu-item {
      font-size: 14px;
      padding: 0 0.14rem!important;
      height: 0.4rem;
      line-height: 0.4rem;
      .el-icon-caret-right {
        color: #000;
        font-size: 14px;
      }
    }
    /deep/.el-submenu {
      &:not(:last-child) .el-submenu__title {
        // border-bottom: 1px solid #cdcdcd;
      }
      &:not(:last-child) {
        .el-submenu__title:not(:first-child) {
          border-bottom: 1px solid #cdcdcd;
        }
      }
      .el-submenu__title {
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 14px;
        font-weight: 400;
        padding: 0 0.14rem!important;
        span {
          margin-right: 0.05rem;
        }
      }
      .el-menu-item {
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 12px;
        font-weight: 400;
        border-bottom: 1px solid #cdcdcd;
        background-color: #f8f8f8;
        padding: 0 0.28rem!important;
        min-width: auto;
      }
      .el-submenu__title * {
        vertical-align: unset;
      }
      .el-icon-arrow-down {
        color: #000;
        font-size: 14px;
        position: unset;
        &::before {
          content: "\e791";
        }
      }
    }
    /deep/.el-submenu.is-opened {
      & > .el-submenu__title {
        span {
          color: #3e35e7;
          font-size: 14px;
          font-weight: 400;
        }
        .el-submenu__icon-arrow {
          color: #3e35e7;
          transform: rotateZ(90deg);
        }
      }
    }
  }
}
</style>
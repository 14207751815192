<template>
  <div class="infoMes-edit-Q">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      class="edit-form"
    >
      <el-form-item label="快讯标题" class="common-form-item" prop="title">
        <el-input v-model="ruleForm.title" placeholder="请输入标题"></el-input>
      </el-form-item>
    <!--  <el-form-item label="作者" class="select-auid-item" prop="auId">
        <el-select v-model="ruleForm.auId" filterable placeholder="请选择">
          <el-option
            v-for="item in authorList"
            :key="item.auId"
            :label="item.auName"
            :value="item.auId"
          ></el-option>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-select>
      </el-form-item>-->
      <el-form-item
        label="抓取网站"
        class="common-form-item select-web-item"
        prop="fromWebsite"
      >
        <el-select v-model="ruleForm.fromWebsite" placeholder="请选择抓取网站">
          <el-option
            v-for="item in webList"
            :key="item.dictCode"
            :label="item.dictName"
            :value="item.dictCode + ''"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源" class="common-form-item" prop="source">
        <el-input
          v-model="ruleForm.source"
          placeholder="请输入数据来源"
        ></el-input>
      </el-form-item>
      <div style="text-align: right;padding-bottom: 5px;">
        <el-button type="primary" size="small" @click="transform()" class="save-btn-common">图片转存</el-button>
      </div>
      <el-form-item class="richText-form-item">
        <Tinymce v-model="ruleForm.context"></Tinymce>
      </el-form-item>
      <el-form-item class="footer-form-item">
        <!-- <div class="all-tags-box">
          <span>标签</span
          ><span v-for="item in Tags" :key="item.ltId">{{ item.ltName }}</span>
        </div> -->
        <div class="footer-publish-btn-box">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 1)"
            class="save-btn-common"
            >保存并发布</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 0)"
            class="save-btn-common"
            >保存</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import Tinymce from "../../components/VueTinymce/PackVueTinymce";

import Tinymce from "../../components/Tinymce";
export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      ruleForm: {
        title: "",
        auId: null,
        fromWebsite: "",
        source: "",
        context: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        fromWebsite: [
          { required: true, message: "请输入抓取网站", trigger: "change" },
        ],
        source: [
          { required: false, message: "请输入数据来源", trigger: "blur" },
        ],
      },
      Tags: [],
      detailData: {},
      webList: [],
      authorList: [],
      detail: {},
    };
  },
  created() {
    this.detail = JSON.parse(this.$route.query.row);
    // this.getAllTags();
    this.getWebList();
    this.getAuthor();
  },
  methods: {
    transform(){
      this.$Https("/nft-server/article/translate", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.ruleForm.context = res.result;
          let editor =  tinymce.activeEditor;
          editor.setContent(this.ruleForm.context)
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    submitForm(formName, val) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {};
          let ltIds = "";
          this.Tags.forEach((item) => {
            ltIds += item.ltId + ",";
          });
          params = {
            arId: this.detail.arId,
            title: this.ruleForm.title,
            imageUrl: "",
            context: this.ruleForm.context,
            category: 0,
            fromWebsite: this.ruleForm.fromWebsite,
            auId: this.ruleForm.auId,
            introduction: "",
            source: this.ruleForm.source,
            isRelase: val,
            ltIds: ltIds,
            arTxt:this.ruleForm.context.replace(/<[^>]+>/g, "")
          };
          this.$Https("/nft-server/articlereptile/update", params).then(
            (res) => {
              if (res.code == 200) {
                this.$message.success("操作成功！");
                this.$router.go(-1);
              } else {
                this.$message.error("操作失败！");
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    getWebList() {
      this.$Http("/nft-server/sysdict/listWebSite").then((res) => {
        if (res.code == 200) {
          this.webList = res.data;
          this.getInMesDetail(this.detail);
        }
      });
    },
    getAllTags() {
      this.$Http("/nft-server/labletype/list").then((res) => {
        if (res.code == 200) {
          if (res.data.length > 10) {
            this.allTags = res.data.slice(0, 10);
          } else {
            this.allTags = res.data;
          }
        }
      });
    },
    getInMesDetail(row) {
      this.$Http("/nft-server/articlereptile/info/" + row.arId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.articleReptile;
          this.ruleForm.title = res.articleReptile.title;
          this.ruleForm.fromWebsite = res.articleReptile.fromWebsite;
          this.ruleForm.source = res.articleReptile.source;
          this.ruleForm.context = res.articleReptile.context;
          this.ruleForm.auId =
            res.articleReptile.auId == 0 ? "" : res.articleReptile.auId;
          this.Tags =
            res.articleReptile.lables.length > 10
              ? res.articleReptile.lables.slice(0, 10)
              : res.articleReptile.lables;

          let editor =  tinymce.activeEditor;
          editor.setContent(this.ruleForm.context)
        }
      });
    },
    getAuthor() {
      this.$Https("/nft-server/author/list-all").then((res) => {
        this.authorList = res.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.infoMes-edit-Q {
  .edit-form {
    /deep/.el-form-item__label {
      font-weight: 300;
      color: #000;
      padding-right: 0.1rem;
      font-size: 12px;
      line-height: 0.24rem;
    }
    /deep/.el-form-item__content {
      line-height: 0.24rem;
      .el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
      }
      .el-textarea__inner {
        font-size: 0.12rem;
      }
    }
    .common-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 3.44rem;
        .el-input__inner {
          padding-left: 0.1rem;
        }
      }
    }
    .select-auid-item {
      /deep/.el-input__inner {
        padding-left: 0.1rem;
      }
      /deep/.el-input__prefix {
        left: unset;
        right: 0.3rem;
        .el-input__icon {
          font-weight: bolder;
          line-height: 0.24rem;
        }
      }
      /deep/.el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
    .select-web-item {
      /deep/.el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
    .richText-form-item {
      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
    }
    .footer-form-item {
      /deep/.el-form-item__content {
        margin-left: 0 !important;
        .all-tags-box {
          span {
            margin-right: 0.14rem;
            color: #000;
            font-weight: 400;
            font-size: 0.12rem;
          }
          span:not(:first-child) {
            padding: 0.04rem 0.14rem;
            border: 1px solid #999999;
            background-color: #fff;
          }
        }
        .footer-publish-btn-box {
          text-align: center;
          .el-button {
            background-color: #ccc;
            font-weight: 400;
            font-size: 16px;
            padding: 0.08rem 0.14rem;
            border-color: #ccc;
          }
          .save-btn-common {
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
      }
    }
  }
}
</style>

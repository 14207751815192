<template>
  <div class="infoMes-edit-Q">
    <div class="form-box">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="edit-form"
      >
        <el-form-item label="姓名" class="common-form-item" prop="auName">
          <el-input
              v-model="ruleForm.auName"
              placeholder="请输入姓名"
              :disabled="!isAdd"
              maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="简介"
            class="common-form-item intro-form-item"
            prop="introduce"
        >
          <el-input
              type="textarea"
              v-model="ruleForm.introduce"
              placeholder="请输入简介"
              :autosize="{minRows: 4}"
              show-word-limit
              maxlength="512"
          ></el-input>
        </el-form-item>
        <el-form-item label="头像" class="cover-form-item" prop="imageUrl">
          <el-upload
              class="avatar-uploader"
              :action="url + '/nft-server/common/uploadImage'"
              :headers="headers"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-remove="handleRemove"
              :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              （图片大小限制为5MB，图片尺寸为200px*200px)
            </div>
          </el-upload>
          <img
              v-if="ruleForm.imageUrl"
              :src="ruleForm.imageUrl"
              class="avatar"
          />
          <div v-else class="default-img-box">请上传图片</div>
        </el-form-item>
        <el-form-item label="文章数" class="common-form-item" prop="artNums">
          <el-input
              v-model="ruleForm.artNums"
              placeholder="请输入数字"
              @input="inputLimitNumber('artNums',$event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="粉丝数" class="common-form-item" prop="fansNums">
          <el-input
              v-model="ruleForm.fansNums"
              placeholder="请输入数字"
              @input="inputLimitNumber('fansNums',$event)"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="获赞数"
            class="common-form-item"
            prop="thumbsUpNums"
        >
          <el-input
              v-model="ruleForm.thumbsUpNums"
              placeholder="请输入数字"
              @input="inputLimitNumber('thumbsUpNums',$event)"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="阅读量"
            class="common-form-item"
            prop="readNum"
        >
          <el-input
              v-model="ruleForm.readNum"
              placeholder="请输入数字"
              @input="inputLimitNumber('readNum',$event)"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-footer-box">
      <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          class="save-btn-common"
      >保存
      </el-button
      >
      <el-button @click="resetForm('ruleForm')">取消</el-button>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        center
        custom-class="user-for-reset-dialog"
    >
      <span v-if="showDialogText == 0"
      >图片格式错误，请上传bmp，jpg，png，gif，jpeg格式图片</span
      >
      <span v-else>图片过大，上传失败，请重新上传</span>
      <span slot="footer" class="dialog-footer">
        <el-button
            type="primary"
            @click="dialogVisible = false"
            class="dialog-footer-sure-btn"
        >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
        >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        auName: "",
        introduce: "",
        imageUrl: "",
        artNums: "",
        fansNums: "",
        thumbsUpNums: "",
        readNum: "",
        auId: "",
      },
      rules: {
        auName: [
          {required: true, message: "请输入姓名", trigger: "blur"},
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        introduce: [
          {required: true, message: "请输入简介", trigger: "blur"},
        ],
        imageUrl: [{required: false, message: "请上传头像", trigger: "blur"}],
        artNums: [{required: false, message: "请输入数字", trigger: "blur"}],
        fansNums: [{required: false, message: "请输入数字", trigger: "blur"}],
        thumbsUpNums: [
          {required: false, message: "请输入数字", trigger: "blur"},
        ],
        readNum: [
          {required: false, message: "请输入数字", trigger: "blur"},
        ]
      },
      detailData: {},
      webList: [],
      authorList: [],
      dialogVisible: false,
      showDialogText: 0,
      url: "",
      headers: "",
      detail: {},
      isAdd: true,
    };
  },
  created() {
    this.url = process.env.VUE_APP_BASEURL;
    this.headers = {token: sessionStorage.getItem("token")};
    if (this.$route.query.row) {
      this.detail = JSON.parse(this.$route.query.row);
      this.ruleForm.auId = this.detail.auId;
      this.getInMesDetail(this.detail);
      this.isAdd = false;
    } else {
      this.isAdd = true;
    }
  },
  methods: {
    inputLimitNumber(str, e) {
      this.ruleForm[str] = e.replace(/[^\d]/g, '');
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addInfoMes();
          } else {
            this.editorInfoMes();
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    addInfoMes() {
      this.$Https("/nft-server/author/save", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    editorInfoMes() {
      this.$Https("/nft-server/author/update", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    getInMesDetail(row) {
      this.$Http("/nft-server/author/info/" + row.auId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.author;
          this.ruleForm.auName = res.author.auName;
          this.ruleForm.introduce = res.author.introduce;
          this.ruleForm.auId = res.author.auId;
          this.ruleForm.imageUrl = res.author.imageUrl;
          this.ruleForm.artNums = res.author.artNums;
          this.ruleForm.fansNums = res.author.fansNums;
          this.ruleForm.thumbsUpNums = res.author.thumbsUpNums;
          this.ruleForm.readNum = res.author.readNum == null ? 0 : res.author.readNum;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.$message.success("上传成功！");
        // this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
        this.ruleForm.imageUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      const isType =
          file.type == "image/jpeg" ||
          file.type == "image/jpg" ||
          file.type == "image/bmp" ||
          file.type == "image/gif" ||
          file.type == "image/png";
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isType) {
        this.showDialogText = 0;
        this.dialogVisible = true;
      }
      if (!isLt5M) {
        this.showDialogText = 1;
        this.dialogVisible = true;
      }
      return isLt5M && isType;
    },
  },
};
</script>

<style lang="scss" scoped>
.infoMes-edit-Q {
  .form-box {
    padding: 0.24rem 0 0.24rem 0;
    background-color: #fff;
    min-height: calc(100vh - 0.8rem - 1.85rem);
    margin-bottom: 0.3rem;

    .edit-form {
      max-width: 6.18rem;

      .el-form-item {
        margin-bottom: 0.2rem;

        /deep/ .el-form-item__label {
          font-size: 12px;
          line-height: 0.24rem;
        }

        /deep/ .el-form-item__content {
          line-height: 0.24rem;

          .el-input__inner {
            font-size: 0.12rem;
            height: 0.24rem;
            line-height: 0.24rem;
          }

          .el-textarea__inner {
            font-size: 0.12rem;
          }
        }

        .custom-text-area {
          height: 1.06rem;

          /deep/ .el-textarea__inner {
            height: 100%;
          }
        }
      }

      .authority-form-item {
        // /deep/.el-form-item__label {
        //   float: none;
        //   display: inline-block;
        //   margin-bottom: 0.2rem;
        // }
        /deep/ .el-form-item__content {
          border: 1px solid #d8d8d8;
          padding: 0.1rem 0 0.22rem 0.22rem;
          min-height: 2.69rem;

          .el-tree-node__content {
            .el-icon-caret-right {
              font-size: 14px;
              color: #000;

              &.expanded {
                color: #4940e8;
              }

              &.is-leaf {
                color: transparent;
                cursor: default;
              }
            }
          }
        }
      }

      /deep/ .el-textarea__inner::-webkit-input-placeholder,
      /deep/ .el-input__inner::-webkit-input-placeholder {
        color: #707070;
      }

      /deep/ .el-textarea__inner::-moz-input-placeholder,
      /deep/ .el-input__inner::-moz-input-placeholder {
        color: #707070;
      }

      /deep/ .el-textarea__inner::-ms-input-placeholder,
      /deep/ .el-input__inner::-ms-input-placeholder {
        color: #707070;
      }
    }
  }

  .form-footer-box {
    text-align: center;

    .el-button {
      font-size: 16px;
      font-weight: 400;
      padding: 0.08rem 0.24rem;
      background-color: #c9c9c9;
      border-color: #c9c9c9;
    }

    .save-btn-common {
      background-color: #a39ff3;
      border-color: #a39ff3;
      margin-right: 0.16rem;
    }
  }

  .edit-form {
    /deep/ .el-form-item__label {
      font-weight: 300;
      color: #000;
      padding-right: 0.1rem;
    }

    .common-form-item.el-form-item {
      /deep/ .el-form-item__content {
        width: 3.44rem;

        .el-input__inner {
          padding-left: 0.1rem;
        }
      }
    }

    .intro-form-item.el-form-item {
      /deep/ .el-form-item__content {
        width: 6.21rem;

        .el-textarea__inner {
          min-height: 1.57rem !important;
          padding-left: 0.1rem;
        }
      }
    }

    .cover-form-item {
      /deep/ .el-form-item__content {
        .avatar-uploader {
          .el-button {
            font-weight: 400;
            font-size: 12px;
            padding: 0.05rem 0.08rem;
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }

        img,
        .default-img-box {
          width: 2.28rem;
          height: 1.72rem;
          vertical-align: middle;
        }

        .default-img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #999999;
          font-weight: 300;
        }

        .el-upload__tip {
          display: inline-block;
          margin-left: 0.1rem;
          margin-top: 0;
          margin-bottom: 0.14rem;
          font-size: 12px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="container">
    <!-- <vue-tinymce
        ref="tinymce"
        v-model="content"
        :setup="setup"
        :disabled="disabled.disabled"
        @change="editorChange"
        :setting="setting"/> -->
    <editorImage :disabled="disabled" color="#b5bcc2" class="editor-upload-btn" @successCBK="imageSuccessCBK"/>
  </div>
</template>

<script>
import plugins from "@/components/Tinymce/plugins";
import toolbar from "@/components/Tinymce/toolbar";
import editorImage from '@/components/Tinymce/components/EditorImage'

export default {
  name: "PackVueTinymce",
  components: {editorImage},
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 400
    },
    disabled: {
      type: Object,
      default: ()=>{
        return {
          id:0,
          disabled:false
        }
      }
    }
  },
  model: {
    prop: 'value',
    event: 'changeVal'
  },
  data: () => ({
    dialogVisible: false,
    content: '',
    setting: {
      id: 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + ''),
      menubar: 'file edit insert view format table',
      menu: {
        insert: {title: 'Insert', items: 'hr anchor pagebreak insertdatetime inserttable toc'},
      },
      toolbar: toolbar,
      toolbar_drawer: "sliding",
      quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
      plugins: plugins,
      language: 'zh_CN',
      height: 400,
    },
    setup: function (editor) {
      let _this=this;
        if(this.disabled){
          editor.setMode('readonly');
        }
      editor.on('init', function () {
        this.getBody().style.fontSize = '14px';
        if(_this.disabled){
          this.getBody().style.color = '#999999';
        } else{
          this.getBody().style.color = '#000000';
        }
        this.getBody().style.fontFamily = 'Microsoft YaHei';
        this.getBody().style.lineHeight = '24px';
        this.getBody().style.letterSpacing = '2px';
        // this.getBody().setAttribute('contenteditable', false);
      });
    }
  }),
  directives: {},
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.content = val;
      },
    },
    height: {
      immediate: true,
      handler(val) {
        this.setting.height = val;
      },
    },
    disabled:{
      immediate:true,
      handler(val){
        if(this.$refs.tinymce){
          if(val.disabled==true){
          // this.$refs.tinymce.setting.readonly=1;
          // this.$refs.tinymce.editor.mode.readonly=1;
          } else{
          // this.$refs.tinymce.setting.readonly=0;
          // this.$refs.tinymce.editor.mode.readonly=0;
          this.$refs.tinymce.editor.getBody().style.color = '#000000';
          }
        }
      },
      deep:true
    }
  },
  methods: {
    imageSuccessCBK(arr) {
      console.log(arr)
      arr.forEach(v => {
        this.onClick('packy', v.url)
      })
    },
    insertImg(editor, {path, key}) {
      editor.undoManager.transact(function () {
        editor.focus();
        editor.selection.setContent(editor.dom.createHTML('img', {src: path, 'data-key': key}));
      })
    },
    onClick(key, path) {
      this.insertImg(this.$refs.tinymce.editor, {key, path})
    },
    editorChange() {
      this.$emit("changeVal", this.content);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  position: relative;

  .editor-upload-btn {
    position: absolute;
    top: 0px;
    right: 5px;
    z-index: 1000;
  }
}

</style>

<template>
  <div class="infoMes-manage-page">
    <div class="page-head-btn clearfixed">
      <el-button type="primary" @click="addWorksFun" class="add-flash-btn"
        >添加作品<i class="el-icon-edit-outline"></i
      ></el-button>
    </div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="作品标题" class="title-form-item">
          <el-input
            v-model="searchForm.worksName"
            placeholder="请输入作品标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布时间" class="publish-form-item">
          <!-- <el-col :span="11"> -->
            <el-date-picker
              type="datetime"
              placeholder="请选择开始日期"
              v-model="searchForm.releaseStartTime"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="datetime-split-text" :span="2">至</el-col> -->
          <span class="datetime-split-text" :span="2">至</span>
          <!-- <el-col :span="11"> -->
            <el-date-picker
              type="datetime"
              placeholder="请选择结束日期"
              v-model="searchForm.releaseEndTime"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item
          label="发布状态"
          class="select-state-item select-common-item"
        >
          <el-select v-model="searchForm.isRelease" placeholder="请选择">
            <el-option
              v-for="item in editorList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchFun">搜索</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" class="role-table">
      <el-table-column type="index" label="序号" width="150px" align="center">
      </el-table-column>
      <el-table-column prop="worksName" label="作品名称" align="center">
      </el-table-column>
      <el-table-column prop="releaseTime" label="发布时间" align="center">
      </el-table-column>
      <el-table-column
        prop="isRelease"
        label="发布状态"
        class-name="state-column"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <span
            :class="scope.row.isRelase == 0 ? 'forbidden-state' : 'start-state'"
          ></span> -->
          <span v-if="scope.row.isRelease == 0">未上架</span>
          <span v-else>已上架</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
        min-width="150"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row)" class="editor-span">编辑</span>
          <span @click="clickCancel(scope.row)" class="editor-span">删除</span>
          <span
            v-if="scope.row.isRelease == 1"
            @click="clickRelase(scope.row, 'isRelease')"
            class="editor-span"
            >下架</span
          >
          <span
            v-else
            @click="clickRelase(scope.row, 'isRelease')"
            class="editor-span"
            >上架</span
          >
          <span
            v-if="scope.row.isTop == 1"
            @click="clickRelase(scope.row, 'isTop')"
            class="ifValid-span"
            >取消置顶</span
          >
          <span
            v-else
            @click="clickRelase(scope.row, 'isTop')"
            class="ifValid-span"
            >置顶</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.length > 0"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, slot"
      :total="total"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span>是否确认删除该作品</span>
      <!-- <div v-else class="reset-box">
        您正在为<span>13ssss</span>重置密码，密码为<span>1234</span>，是否确认
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancel"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        worksName: "",
        releaseStartTime: "",
        releaseEndTime: "",
        isRelease: "",
        pageSize: 10,
        currentPage: 1,
      },
      editorList: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "已上架",
        },
        {
          id: 0,
          label: "未上架",
        },
      ],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      jumperPage: 1,
      dialogVisible: false,
      switchForReset: 0,
      cancelTmpItem: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$Https("/nft-server/nftworks/list", this.searchForm).then((res) => {
        if (res.code == 200) {
          this.tableData = res.page.data;
          this.total = res.page.totalNumber;
          this.currentPage = res.page.currentPage;
        }
      });
    },
    searchFun() {
      this.getList();
    },
    addWorksFun() {
      this.$router.push("/worksAdd");
    },
    clickEditor(row) {
      if (row.isRelease == 1) {
        this.$message.error("请先将作品下架后执行编辑操作");
        return;
      }
      this.$router.push({
        path: "/worksAdd",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    clickRelase(row, val) {
      let params = {};
      if (val == "isRelease") {
        params = {
          nwId: row.nwId,
          [val]: row[val] == 0 ? 1 : 0,
        };
      } else {
        row.isTop = (row.isTop + 1) % 2;
        params = row;
      }
      this.$Https("/nft-server/nftworks/update", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    clickCancel(row) {
      if (row.isRelease == 1) {
        this.$message.error("请先将作品下架后执行删除操作");
        return;
      }
      this.dialogVisible = true;
      this.cancelTmpItem = row;
    },
    clickResetPass(row, val) {
      this.dialogVisible = true;
      this.switchForReset = val;
    },
    sureCancel() {
      this.cancelInfoMes();
    },
    cancelInfoMes() {
      let tmp = { nwId: this.cancelTmpItem.nwId };
      this.$Https("/nft-server/nftworks/delete", tmp).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$message.success("删除成功！");
        } else {
          this.$message.error("删除异常！");
        }
        this.dialogVisible = false;
      });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getList();
    },
    jumperPageFun() {
      this.searchForm.currentPage = this.jumperPage;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-head-btn {
  margin-bottom: 0.16rem;

  .common-btn {
    float: left;
    background-color: #ccc;
    font-weight: 400;
    font-size: 14px;
    padding: 0.05rem 0.08rem;
    border-color: #ccc;
  }

  .active-btn {
    background-color: #a39ff3;
    border-color: #a39ff3;
  }

  .add-flash-btn {
    font-size: 14px;
    font-weight: 400;
    padding: 0.05rem 0 0.05rem 0.08rem;
    float: right;
    background-color: transparent;
    color: #4940e8;
    border-color: transparent;
  }
}

.page-top {
  display: flex;
  .search-form {
    // float: left;
    margin-right: 15px;
    .el-form-item {
      margin-right: 0.5rem;

      /deep/ .el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
      }

      /deep/ .el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
        padding-left: 0.05rem;
        border-color: #999;
      }
    }

    .title-form-item {
      /deep/ .el-input__inner {
        width: 2.5rem;
      }
    }

    .publish-form-item {
      /deep/ .el-input__inner {
        padding-left: 0.15rem;
        padding-right: 0.15rem;
      }
      /deep/ .datetime-split-text {
        text-align: center;
        font-size: 12px;
      }

      /deep/ .el-date-editor.el-input {
        width: 1.65rem;
      }

      /deep/.el-input__prefix {
        // right: 5px;
        // left: unset;
        left: 0.02rem;
        .el-input__icon{
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
      /deep/ .el-input__suffix{
        .el-input__icon{
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
    }

    .select-state-item {
      /deep/ .el-form-item__content {
        width: 0.85rem;
      }
    }

    .select-web-item {
      /deep/ .el-form-item__content {
        width: 1.2rem;
      }
    }

    .select-isSift-item {
      /deep/ .el-form-item__content {
        width: 0.8rem;
      }
    }

    .select-common-item {
      /deep/ .el-icon-arrow-up {
        color: #000;
          line-height: 0.24rem;

        &::before {
          content: "\e78f";
        }
      }
    }
  }

  .el-button {
    // float: right;
    height: fit-content;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}

.role-table.el-table {
  margin-bottom: 0.4rem;

  &::before {
    background-color: #fff;
  }

  /deep/ thead {
    color: #fff;

    th {
      background-color: #a39ff3;
      font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;
    }
  }

  /deep/ td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 12px;
  }

  .state-column {
    span {
      vertical-align: middle;
    }

    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.13rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
    }

    .forbidden-state {
      background-color: #ebebeb;
    }

    .start-state {
      background-color: #a39ff3;
    }
  }

  .operation-column {
    span {
      cursor: pointer;
    }

    span:not(:last-child) {
      margin-right: 0.16rem;
    }

    .editor-span {
      color: #a39ff3;
    }

    .ifValid-span {
      color: #ff3696;
    }
  }
}

.custom-pagination.el-pagination {
  text-align: center;

  &.is-background {
    /deep/ .el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;

        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }

  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 14px;
    font-weight: 300;
    color: #000;

    .jumper-input {
      width: 0.5rem;

      /deep/ .el-input__inner {
        height: 28px;
      }
    }
  }

  /deep/ button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>

<template>
  <div class="specialClassifyAdd-page">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      class="edit-form"
    >
      <el-form-item label="专题标题" class="common-form-item" prop="title">
        <el-input v-model="ruleForm.title" placeholder="请输入标题" maxlength="100"></el-input>
      </el-form-item>
      <el-form-item
        label="专题简介"
        class="common-form-item intro-form-item"
        prop="introduce"
      >
        <el-input
          type="textarea"
          v-model="ruleForm.introduce"
          placeholder="请输入简介"
        ></el-input>
      </el-form-item>
      <el-form-item label="封面" prop="coverUrl" class="cover-form-item">
        <el-upload
          class="avatar-uploader"
          :action="url + '/nft-server/common/uploadImage'"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
        >
          <el-button size="small" type="primary">上传封面</el-button>
          <div slot="tip" class="el-upload__tip">
            （图片大小限制为5MB，图片尺寸为474px*316px)
          </div>
        </el-upload>
        <img v-if="ruleForm.coverUrl" :src="ruleForm.coverUrl" class="avatar" />
        <div v-else class="default-img-box">请上传图片</div>
      </el-form-item>
      <el-form-item label="详情图" prop="detailUrl" class="cover-form-item">
        <el-upload
          class="avatar-uploader"
          :action="url + '/nft-server/common/uploadImage'"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccessD"
          :on-remove="handleRemoveD"
          :before-upload="beforeAvatarUpload"
        >
          <el-button size="small" type="primary">上传详情图</el-button>
          <div slot="tip" class="el-upload__tip">
            （图片大小限制为5MB，图片尺寸为615px*467px)
          </div>
        </el-upload>
        <img
          v-if="ruleForm.detailUrl"
          :src="ruleForm.detailUrl"
          class="avatar"
        />
        <div v-else class="default-img-box">请上传图片</div>
      </el-form-item>
      <el-form-item class="footer-form-item">
        <div class="footer-publish-btn-box">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 1)"
            class="save-btn-common"
            >保存并发布</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 0)"
            class="save-btn-common"
            >保存</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="showDialogText == 0"
        >图片格式错误，请上传bmp，jpg，png，gif，jpeg格式图片</span
      >
      <span v-else>图片过大，上传失败，请重新上传</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisible = false"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        title: "",
        introduce: "",
        coverUrl: "",
        detailUrl: "",
        isRelease: 0,
        spId: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        introduce: [{ required: true, message: "请输入简介", trigger: "blur" }],
        coverUrl: [
          { required: true, message: "请上传封面图", trigger: "blur" },
        ],
        detailUrl: [
          { required: true, message: "请上传详情图", trigger: "blur" },
        ],
      },
      detailData: {},
      webList: [],
      authorList: [],
      dialogVisible: false,
      showDialogText: 0,
      url: "",
      headers: "",
      detail: {},
      isAdd: true,
    };
  },
  created() {
    this.url = process.env.VUE_APP_BASEURL;
    this.headers = { token: sessionStorage.getItem("token") };
    if (this.$route.query.row) {
      this.detail = JSON.parse(this.$route.query.row);
      this.ruleForm.spId = this.detail.spId;
      this.getInMesDetail(this.detail);
      this.isAdd = false;
    } else {
      this.isAdd = true;
    }
  },
  methods: {
    submitForm(formName, val) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (val == 0) {
            this.ruleForm.isRelease = 0;
          } else {
            this.ruleForm.isRelease = 1;
          }
          if (this.isAdd) {
            this.addInfoMes();
          } else {
            this.editorInfoMes();
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    addInfoMes() {
      this.$Https("/nft-server/special/save", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    editorInfoMes() {
      this.$Https("/nft-server/special/update", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    getInMesDetail(row) {
      this.$Http("/nft-server/special/info/" + row.spId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.special;
          this.ruleForm.title = res.special.title;
          this.ruleForm.introduce = res.special.introduce;
          this.ruleForm.coverUrl = res.special.coverUrl;
          this.ruleForm.detailUrl = res.special.detailUrl;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      if (res.code == 200) {
        this.$message.success("上传成功！");
        this.ruleForm.coverUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccessD(res, file) {
      if (res.code == 200) {
        this.$message.success("上传成功！");
        this.ruleForm.detailUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemoveD(file, fileList) {
      console.log(file, fileList);
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      const isType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp" ||
        file.type === "image/gif" ||
        file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isType) {
        this.showDialogText = 0;
        this.dialogVisible = true;
      }
      if (!isLt5M) {
        this.showDialogText = 1;
        this.dialogVisible = true;
      }
      return isLt5M && isType;
    },
  },
};
</script>

<style lang="scss" scoped>
.specialClassifyAdd-page {
  .edit-form {
    background-color: #fff;
    padding: 0.24rem 0 0.24rem 0.34rem;
    /deep/.el-form-item__label {
      font-weight: 300;
      color: #000;
      padding-right: 0.1rem;
      font-size: 12px;
      line-height: 0.24rem;
    }
    .common-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 3.44rem;
        .el-input__inner {
          padding-left: 0.1rem;
        }
      }
    }
    /deep/.el-form-item__content {
      line-height: 0.24rem;
      .el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
      }
      .el-textarea__inner {
        font-size: 0.12rem;
      }
    }
    .select-auid-item {
      /deep/.el-input__inner {
        padding-left: 0.1rem;
      }
      /deep/.el-input__prefix {
        left: unset;
        right: 0.3rem;
        .el-input__icon {
          font-weight: bolder;
        }
      }
      /deep/.el-icon-arrow-up {
        color: #000;
        &::before {
          content: "\e78f";
        }
      }
    }
    .intro-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 6.21rem;
        .el-textarea__inner {
          min-height: 1.57rem !important;
          padding-left: 0.1rem;
        }
      }
    }
    .cover-form-item {
      /deep/.el-form-item__content {
        .avatar-uploader {
          .el-button {
            font-weight: 400;
            font-size: 12px;
            padding: 0.05rem 0.08rem;
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
        img,
        .default-img-box {
          width: 2.28rem;
          height: 1.72rem;
          vertical-align: middle;
        }
        .default-img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #999999;
          font-weight: 300;
        }
        .el-upload__tip {
          display: inline-block;
          margin-left: 0.1rem;
          margin-top: 0;
          margin-bottom: 0.14rem;
          font-size: 12px;
        }
      }
    }
    .footer-form-item {
      /deep/.el-form-item__content {
        margin-left: 0 !important;
        .all-tags-box {
          span {
            margin-right: 0.14rem;
            color: #000;
            font-weight: 400;
            font-size: 0.14rem;
          }
          span:not(:first-child) {
            padding: 0.04rem 0.14rem;
            border: 1px solid #999999;
            background-color: #fff;
          }
        }
        .footer-publish-btn-box {
          text-align: center;
          .el-button {
            background-color: #ccc;
            font-weight: 400;
            font-size: 16px;
            padding: 0.08rem 0.14rem;
            border-color: #ccc;
          }
          .save-btn-common {
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
      }
    }
  }
}
</style>
import axios from 'axios'
import ElementUI from 'element-ui';
const service = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 99999999,
});
service.interceptors.request.use(
    config => {
        if (sessionStorage.getItem('token')) {
            config.headers.token = sessionStorage.getItem('token');
        }
        return config;
    },
    error => {
        return Promise.reject(error.response);
    }
);

service.interceptors.response.use((response) => {
    const res = response.data;
    if (res.code == '401') {
        sessionStorage.removeItem('token');
        ElementUI.Message.error("登录失效");
        setTimeout(()=>{
            window.location.href = '/';
        },1000);
    }
    return res
},
    error => {
        return Promise.reject(error.response);
    }
);
export default service
<template>
  <div class="infoMes-edit-Q" v-loading="loading">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      class="edit-form"
    >
      <el-form-item label="文章标题" class="common-form-item" prop="title">
        <el-input
          v-model="ruleForm.title"
          placeholder="请输入标题"
          maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item label="作者" class="select-auid-item" prop="auId">
        <el-select v-model="ruleForm.auId" filterable placeholder="请选择">
          <el-option
            v-for="item in authorList"
            :key="item.auId"
            :label="item.auName"
            :value="item.auId"
          ></el-option>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-select>
      </el-form-item>
      <el-form-item label="分类" class="select-auid-item" prop="atNa">
        <!-- <el-select v-model="ruleForm.atId" filterable placeholder="请选择">
          <el-option
            v-for="item in classifyList"
            :key="item.atId"
            :label="item.atName"
            :value="item.atId"
          ></el-option>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-select> -->
        <el-checkbox-group v-model="ruleForm.atNa" size="mini">
          <el-checkbox-button
            v-for="item in classifyList"
            :key="item.atId"
            :label="item.atName"
            >{{ item.atName }}</el-checkbox-button
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="来源" class="common-form-item">
        <el-input
          v-model="ruleForm.source"
          placeholder="请输入来源"
          maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="简介"
        class="common-form-item intro-form-item"
        prop="introduction"
      >
        <el-input
          type="textarea"
          v-model="ruleForm.introduction"
          placeholder="请输入简介"
          maxlength="200"
        ></el-input>
      </el-form-item>
      <el-form-item label="封面" class="cover-form-item" prop="imageUrl">
        <el-upload
          class="avatar-uploader"
          :action="url + '/nft-server/common/uploadImage'"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
        >
          <el-button size="small" type="primary">上传图片</el-button>
          <div slot="tip" class="el-upload__tip">
            （图片大小限制为5MB，图片尺寸为615px*467px)
          </div>
        </el-upload>
        <img v-if="ruleForm.imageUrl" :src="ruleForm.imageUrl" class="avatar" />
        <div v-else class="default-img-box">请上传图片</div>
        <div class="uoload-img-delete-box" v-if="ruleForm.imageUrl">
          <el-button
            type="text"
            @click="deleteUploadSource('imageUrl')"
            style="color: #3e35e7"
            >删除</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="视频" class="cover-form-item">
        <el-upload
          class="avatar-uploader"
          :action="url + '/nft-server/common/uploadImage'"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccessV"
          :on-remove="handleRemoveV"
          :before-upload="beforeAvatarUploadV"
        >
          <el-button size="small" type="primary">上传视频</el-button>
          <div slot="tip" class="el-upload__tip">
            （请上传MPEG格式、AVI格式、ASF格式、WMV格式、MP4格式的视频)
          </div>
        </el-upload>
        <img
          v-if="ruleForm.videoCover"
          :src="ruleForm.videoCover"
          class="avatar"
        />
        <div v-else class="default-img-box">请上传视频</div>
        <div class="uoload-img-delete-box" v-if="ruleForm.videoUrl">
          <el-button
            type="text"
            @click="deleteUploadSource('videoUrl')"
            style="color: #3e35e7"
            >删除</el-button
          >
        </div>
      </el-form-item>
        <div style="text-align: right;padding-bottom: 5px;">
          <el-button type="primary" size="small" @click="transform()" class="save-btn-common">图片转存</el-button>
        </div>
      <el-form-item class="richText-form-item">
        <Tinymce v-model="ruleForm.context"></Tinymce>
      </el-form-item>
      <el-form-item class="footer-form-item">
        <div class="all-tags-box">
          <span>标签</span>
          <!-- <span v-for="item in Tags" :key="item.ltId">{{ item.ltName }}</span> -->
          <span v-for="(item, index) in Tags" :key="item.ltId">
            <el-tag
              v-show="!item.isShowInput"
              :closable="index != 0"
              :disable-transitions="false"
              @close="handleClose(item)"
              @click="switchTagsFun(item)"
            >
              {{ item.ltName }}
            </el-tag>
            <el-input
              v-if="item.isShowInput"
              class="input-new-tag"
              v-model="item.ltName"
              :ref="'editorTagInput' + item.ltId"
              size="small"
              @blur="editorInputConfirm(item)"
              maxlength="50"
            >
            </el-input>
          </span>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @blur="handleInputConfirm"
            maxlength="50"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >添加标签<i class="el-icon-circle-plus-outline"></i
          ></el-button>
        </div>
        <div class="footer-publish-btn-box">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 1)"
            class="save-btn-common"
            >保存并发布</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 0)"
            class="save-btn-common"
            >保存</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleTips"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="showDialogText == 0"
        >图片格式错误，请上传bmp，jpg，png，gif，jpeg格式图片</span
      >
      <span v-if="showDialogText == 1">图片过大，上传失败，请重新上传</span>
      <span v-if="showDialogText == 2"
        >视频格式错误，请上传MPEG格式、AVI格式、ASF格式、WMV格式、MP4格式的视频</span
      >
      <span v-if="showDialogText == 3">视频过大，上传失败，请重新上传</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisibleTips = false"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisibleTips = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="tagDialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span>是否确认删除该标签</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancelTag"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="tagDialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Tinymce from "../../components/VueTinymce/PackVueTinymce";
import Tinymce from "../../components/Tinymce";
export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      ruleForm: {
        title: "",
        auId: "",
        atIds: "",
        introduction: "",
        imageUrl: "",
        videoUrl: "",
        context: "",
        category: "",
        isRelase: 0,
        arId: "",
        videoCover: "",
        atNa: ["未分类"],
        source: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        auId: [{ required: true, message: "请输入作者", trigger: "change" }],
        atNa: [{ required: true, message: "请选择分类", trigger: "change" }],
        imageUrl: [
          { required: true, message: "请上传封面", trigger: "change" },
        ],
      },
      detailData: {},
      authorList: [],
      detail: {},
      isAdd: true,
      classifyList: [],
      dialogVisibleTips: false,
      showDialogText: 0,
      url: "",
      headers: "",
      loading: false,
      Tags: [],
      inputVisible: false,
      inputValue: "",
      tagDialogVisible: false,
      tmpTag: {},
    };
  },
  created() {
    this.getClsaaify();
    this.url = process.env.VUE_APP_BASEURL;
    this.headers = { token: sessionStorage.getItem("token") };
    if (this.$route.query.category) {
      this.ruleForm.category = this.$route.query.category;
    }
    if (this.$route.query.row) {
      this.detail = JSON.parse(this.$route.query.row);
      this.ruleForm.auId = this.detail.auId;
      this.ruleForm.category = this.detail.category;
      this.ruleForm.arId = this.detail.arId;
      // this.getInMesDetail(this.detail);
      this.isAdd = false;
    } else {
      this.isAdd = true;
    }
    this.getAuthor();
  },
  methods: {
    transform(){
      this.$Https("/nft-server/article/translate", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.ruleForm.context = res.result;
          let editor =  tinymce.activeEditor;
          editor.setContent(this.ruleForm.context)
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    handleClose(tag) {
      this.tagDialogVisible = true;
      this.tmpTag = tag;
    },
    sureCancelTag() {
      if (this.isAdd) {
        this.Tags.splice(this.Tags.indexOf(this.tmpTag), 1);
        this.tagDialogVisible = false;
        return;
      }
      let params = {
        arId: this.detail.arId,
        laType: 0,
        ltId: this.tmpTag.ltId,
      };
      this.$Https("/nft-server/article/remove-label", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功！");
          this.getAllTags(this.detail);
        } else {
          this.$message.error(res.msg);
        }
        this.tagDialogVisible = false;
      });
    },
    switchTagsFun(item) {
      item.isShowInput = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$refs["editorTagInput" + item.ltId][0].$refs.input.focus();
      });
    },
    editorInputConfirm(item) {
      let oldItem = JSON.stringify(item);
      if (this.isAdd) {
        if (item.ltName == "") {
          this.$message.error("标签名称不能为空");
          item.ltName = JSON.parse(oldItem).ltName;
        }
        item.isShowInput = false;
        return;
      }
      if (item.ltName == "") {
        this.$message.error("标签名称不能为空");
        this.getAllTags(this.detail);
      } else {
        let params = {
          ltId: item.ltId,
          arId: this.detail.arId,
          laType: 0,
          ltName: item.ltName,
        };
        this.$Https("/nft-server/article/edit-label", params).then((res) => {
          if (res.code == 200) {
            this.$message.success("操作成功！");
            this.getAllTags(this.detail);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue.split(" ").join("").length == 0) {
        this.$message.error("标签名称不能为空");
        this.inputVisible = false;
        return;
      }
      if (this.isAdd) {
        this.Tags.push({
          ltName: inputValue,
          ltId: this.Tags.length,
          isShowInput: false,
        });
        this.inputVisible = false;
        this.inputValue = "";
        return;
      }
      let params = {
        arId: this.detail.arId,
        laType: 0,
        ltName: inputValue,
      };
      this.$Https("/nft-server/article/add-label", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getAllTags(this.detail);
        } else {
          this.$message.error(res.msg);
        }
        this.inputVisible = false;
        this.inputValue = "";
      });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    deleteUploadSource(val) {
      this.ruleForm[val] = "";
      if (val == "videoUrl") {
        this.ruleForm.videoCover = "";
      }
    },
    submitForm(formName, val) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.ruleForm.atIds = "";
          this.ruleForm.atNa.forEach((item) => {
            this.classifyList.forEach((ele) => {
              if (item == ele.atName) {
                _this.ruleForm.atIds += ele.atId + ",";
              }
            });
          });
          if (val == 0) {
            this.ruleForm.isRelase = 0;
          } else {
            this.ruleForm.isRelase = 1;
          }
          if (this.isAdd) {
            this.addInfoMes();
          } else {
            this.editorInfoMes();
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    addInfoMes() {
      this.ruleForm.labelList = [];
      this.Tags.forEach((item) => {
        this.ruleForm.labelList.push({ ltName: item.ltName.trim() });
      });
      if (this.isRepeat(this.ruleForm.labelList, "ltName")) {
        this.$message.error("标签不能重名，请修改标签后再执行此操作");
        return;
      }
      this.ruleForm.arTxt = this.ruleForm.context.replace(/<[^>]+>/g, "");
      this.$Https("/nft-server/article/save", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    isRepeat(arr, str) {
      var obj = {};
      for (let i = 0; i < arr.length; i++) {
        if (obj[arr[i][str]]) {
          return true;
        } else {
          obj[arr[i][str]] = arr[i];
        }
      }
      return false;
    },
    editorInfoMes() {
      this.ruleForm.arTxt = this.ruleForm.context.replace(/<[^>]+>/g, "");
      this.$Https("/nft-server/article/update", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    getInMesDetail(row) {
      let _this = this;
      this.$Http("/nft-server/article/info/" + row.arId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.article;
          this.ruleForm.title = res.article.title;
          this.ruleForm.context = res.article.context;
          this.ruleForm.auId = res.article.auId;
          // this.ruleForm.atId = res.article.atId == null ? 0 : res.article.atId;
          res.article.typeRelationList.forEach((item) => {
            this.classifyList.forEach((ele) => {
              if (item.typeId == ele.atId) {
                _this.ruleForm.atNa.push(ele.atName);
                _this.ruleForm.atIds += ele.atId + ",";
              }
            });
          });
          console.log("xx", res.article.typeRelationList, _this.ruleForm.atNa);
          this.ruleForm.introduction = res.article.introduction;
          this.ruleForm.imageUrl = res.article.imageUrl;
          this.ruleForm.videoCover = res.article.videoCover;
          for (let x in res.article.labelList) {
            res.article.labelList[x]["isShowInput"] = false;
          }
          this.Tags = res.article.labelList;
          this.ruleForm.source = res.article.source;

          let editor =  tinymce.activeEditor;
          editor.setContent(this.ruleForm.context)
        }
      });
    },
    getAuthor() {
      this.$Https("/nft-server/author/list-all").then((res) => {
        this.authorList = res.list;
      });
    },
    getAllTags(row) {
      this.$Https("/nft-server/article/label/" + row.arId).then((res) => {
        if (res.code == 200) {
          for (let x in res.list) {
            res.list[x]["isShowInput"] = false;
          }
          this.Tags = res.list;
        }
      });
    },
    getClsaaify() {
      this.$Https("/nft-server/articletype/list").then((res) => {
        res.data.forEach((item) => {
          if (item.atId == null) {
            item.atId = 0;
          }
        });
        this.classifyList = res.data;
        if (this.$route.query.row) {
          this.getInMesDetail(this.detail);
        }
        console.log("分类", this.classifyList);
      });
    },
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.$message.success("上传成功！");
        // this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
        this.ruleForm.imageUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      const isType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp" ||
        file.type === "image/gif" ||
        file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isType) {
        this.showDialogText = 0;
        this.dialogVisibleTips = true;
      }
      if (!isLt5M) {
        this.showDialogText = 1;
        this.dialogVisibleTips = true;
      }
      return isLt5M && isType;
    },
    handleAvatarSuccessV(res, file) {
      if (res.code == 200) {
        this.loading = false;
        this.$message.success("上传成功！");
        this.ruleForm.videoCover = res.coverUrl;
        this.ruleForm.videoUrl = res.imageUrl;
        // this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemoveV(file, fileList) {
      console.log(file, fileList);
    },
    beforeAvatarUploadV(file) {
      console.log("file", file);
      const isType =
        file.type == "video/MPEG" ||
        file.type == "video/AVI" ||
        file.type == "video/ASF" ||
        file.type == "video/WMV" ||
        file.type == "video/MP4" ||
        file.type == "video/X-MS-ASF" ||
        file.type == "video/X-MS-WMV" ||
        // file.type == "video/MOV" ||
        // file.type == "video/QUICKTIME" ||
        file.type == "video/mpeg" ||
        file.type == "video/avi" ||
        file.type == "video/asf" ||
        file.type == "video/wmv" ||
        file.type == "video/mp4" ||
        file.type == "video/x-ms-asf" ||
        file.type == "video/x-ms-wmv";
      // file.type == "video/mov" ||
      // file.type == "video/quicktime";
      const isLt5M = file.size / 1024 / 1024 / 1024 <= 1;
      if (!isType) {
        this.showDialogText = 2;
        this.dialogVisibleTips = true;
      }
      if (!isLt5M) {
        this.showDialogText = 3;
        this.dialogVisibleTips = true;
      }
      if (isLt5M && isType) {
        this.loading = true;
      }
      return isLt5M && isType;
    },
  },
};
</script>

<style lang="scss" scoped>
.infoMes-edit-Q {
  /deep/.el-loading-mask {
    position: fixed;
  }
  .edit-form {
    /deep/.el-form-item__label {
      font-weight: 300;
      color: #000;
      padding-right: 0.1rem;
      font-size: 12px;
      line-height: 0.24rem;
    }
    /deep/.el-form-item__content {
      line-height: 0.24rem;
      .el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
      }
      .el-textarea__inner {
        font-size: 0.12rem;
      }
    }
    .common-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 3.44rem;
        .el-input__inner {
          padding-left: 0.1rem;
        }
      }
    }
    .select-auid-item {
      /deep/.el-input__inner {
        padding-left: 0.1rem;
      }
      /deep/.el-input__prefix {
        left: unset;
        right: 0.3rem;
        .el-input__icon {
          font-weight: bolder;
          line-height: 0.24rem;
        }
      }
      /deep/.el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
      .el-checkbox-button {
        margin-right: 15px;
        &.is-checked {
          /deep/.el-checkbox-button__inner {
            background-color: #a39ff3;
            border-color: #a39ff3;
            box-shadow: none;
          }
        }
        &.is-focus {
          /deep/.el-checkbox-button__inner {
            border-color: transparent;
          }
        }
        /deep/.el-checkbox-button__inner {
          border-radius: 3px;
          padding: 5px 15px;
        }
      }
    }
    .intro-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 6.21rem;
        .el-textarea__inner {
          min-height: 1.57rem !important;
          padding-left: 0.1rem;
        }
      }
    }
    .cover-form-item {
      /deep/.el-form-item__content {
        .avatar-uploader {
          .el-button {
            font-weight: 400;
            font-size: 12px;
            padding: 0.05rem 0.08rem;
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
        img,
        .default-img-box {
          width: 2.28rem;
          height: 1.72rem;
          vertical-align: middle;
        }
        .default-img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #999999;
          font-weight: 300;
        }
        .uoload-img-delete-box {
          display: inline-block;
          margin-left: 0.3rem;
          vertical-align: middle;
        }
        .el-upload__tip {
          display: inline-block;
          margin-left: 0.1rem;
          margin-top: 0;
          margin-bottom: 0.14rem;
          font-size: 12px;
        }
      }
    }
    .richText-form-item {
      /deep/.el-form-item__content {
        margin-left: 0 !important;
        .tox-tinymce {
          height: 1000px !important;
        }
      }
    }
    .footer-form-item {
      /deep/.el-form-item__content {
        margin-left: 0 !important;
        .all-tags-box {
          margin-bottom: 0.1rem;
          & > span {
            margin-right: 0.14rem;
            color: #000;
            font-weight: 400;
            font-size: 0.12rem;
          }
          // span:not(:first-child) {
          //   padding: 0.04rem 0.14rem;
          //   border: 1px solid #999999;
          //   background-color: #fff;
          // }
          .el-tag {
            margin-right: 0.08rem;
            background-color: #ccc;
            border-color: #ccc;
            color: #fff;
            cursor: pointer;
            height: 0.26rem;
            line-height: 0.24rem;
            .el-tag__close {
              color: #fff;
            }
          }
          .active.el-tag {
            background-color: #a39ff3;
          }

          .input-new-tag {
            max-width: 1rem;
          }

          .button-new-tag {
            color: #a39ff3;
            border: none;
            background-color: transparent;
            font-size: 12px;
            padding: 0.05rem 0.08rem;
          }
        }
        .footer-publish-btn-box {
          text-align: center;
          .el-button {
            background-color: #ccc;
            font-weight: 400;
            font-size: 16px;
            padding: 0.08rem 0.14rem;
            border-color: #ccc;
          }
          .save-btn-common {
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
      }
    }
  }
}
</style>

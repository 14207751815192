<template>
  <div class="role-add-page">
    <div class="form-box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="add-role-ruleForm"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input
            v-model="ruleForm.roleName"
            placeholder="请输入名称"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="roleComment">
          <el-input
            type="textarea"
            v-model="ruleForm.roleComment"
            placeholder="请输入描述"
            class="custom-text-area"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限选择" required class="authority-form-item">
          <!-- <el-form-item> -->
          <el-col :span="4" v-for="item in allMenu" :key="item.ofCode">
            <el-tree
              :data="[item]"
              show-checkbox
              node-key="ofId"
              :ref="'userTree' + item.ofCode"
              :props="defaultProps"
            >
            </el-tree>
          </el-col>
        </el-form-item>
        <!-- </el-form-item> -->
      </el-form>
    </div>
    <div class="form-footer-box">
      <el-button
        type="primary"
        class="save-add-form-btn"
        @click="submitForm('ruleForm')"
        >保存</el-button
      >
      <el-button type="primary" @click="resetForm('ruleForm')">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        roleName: "",
        roleComment: "",
        ofIds: "",
      },
      rules: {
        roleName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        roleComment: [
          { required: true, message: "请输入描述", trigger: "change" },
        ],
      },
      defaultProps: {
        children: "childFuncs",
        label: "ofName",
      },
      detail: {},
      detailData: {},
      allMenu: [],
      selectedOfIds: [],
      isAdd: true,
    };
  },
  created() {
    this.getAllMenu();
    if (this.$route.query.row) {
      this.detail = JSON.parse(this.$route.query.row);
      this.getDetail(this.detail);
      this.isAdd = false;
    } else {
      this.isAdd = true;
    }
  },
  methods: {
    getDetail(row) {
      this.$Http("/nft-server/sysrole/info/" + row.roleId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.sysRole;
          this.ruleForm.roleName = res.sysRole.roleName;
          this.ruleForm.roleComment = res.sysRole.roleComment;
          if (res.sysRole.ofIds) {
            for (let i = 0; i < this.allMenu.length; i++) {
              this.$refs[`userTree${this.allMenu[i].ofCode}`][0].setCheckedKeys(
                res.sysRole.ofIds.split(",")
              );
            }
          }
        }
      });
    },
    getAllMenu() {
      this.$Http("/nft-server/orgfunc/list").then((res) => {
        if (res.code == 200) {
          this.allMenu = res.page;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          for (let i = 0; i < this.allMenu.length; i++) {
            let tmp =
              this.$refs[
                `userTree${this.allMenu[i].ofCode}`
              ][0].getCheckedKeys();
            this.selectedOfIds = [...tmp, ...this.selectedOfIds];
          }
          // this.selectedOfIds = this.selectedOfIds.filter(
          //   (a) =>
          //     !this.allMenu.some((b) => {
          //       if (b.childFuncs.length) {
          //         return b.ofId == a;
          //       } else {
          //         return false;
          //       }
          //     })
          // );
          if (this.selectedOfIds.length == 0) {
            this.$message.error("请选择角色权限");
            return;
          }
          this.selectedOfIds = Array.from(this.selectedOfIds);
          this.selectedOfIds = this.selectedOfIds.join();
          if (this.isAdd) {
            this.addRole();
          } else {
            this.editorRole();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    addRole() {
      let params = {
        roleName: this.ruleForm.roleName,
        roleComment: this.ruleForm.roleComment,
        ofIds: this.selectedOfIds,
      };
      this.$Https("/nft-server/sysrole/save", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.$router.go(-1);
        }
      });
    },
    editorRole() {
      let params = {
        roleId: this.detail.roleId,
        roleName: this.ruleForm.roleName,
        roleComment: this.ruleForm.roleComment,
        ofIds: this.selectedOfIds,
      };
      this.$Https("/nft-server/sysrole/update", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("编辑成功！");
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.role-add-page {
  // margin-top: 0.3rem;
  .form-box {
    padding: 0.24rem 0 0.24rem 0.34rem;
    background-color: #fff;
    min-height: calc(100vh - 0.8rem - 1.85rem);
    margin-bottom: 0.3rem;
    .add-role-ruleForm {
      .el-form-item {
        margin-bottom: 0.2rem;
        width: 6.18rem;
        /deep/.el-form-item__label {
          font-size: 12px;
          line-height: 0.24rem;
        }
        /deep/.el-form-item__content {
          line-height: 0.24rem;
          .el-input__inner {
            font-size: 0.12rem;
            height: 0.24rem;
            line-height: 0.24rem;
          }
          .el-textarea__inner {
            font-size: 0.12rem;
          }
        }
        .custom-text-area {
          height: 1.06rem;
          /deep/.el-textarea__inner {
            height: 100%;
          }
        }
      }
      .authority-form-item {
        width: auto;
        margin-right: 1.66rem;
        // /deep/.el-form-item__label {
        //   float: none;
        //   display: inline-block;
        //   margin-bottom: 0.2rem;
        // }
        /deep/.el-form-item__content {
          border: 1px solid #d8d8d8;
          padding: 0.1rem 0 0.22rem 0.22rem;
          min-height: 2.69rem;
          .el-tree-node__content {
            .el-icon-caret-right {
              font-size: 12px;
              color: #000;
              padding: 0.03rem;
              &.expanded {
                color: #4940e8;
              }
              &.is-leaf {
                color: transparent;
                cursor: default;
              }
            }
          }
          .el-tree-node__children {
            .el-tree-node__label {
              font-size: 0.12rem;
            }
          }
        }
      }
      /deep/.el-textarea__inner::-webkit-input-placeholder,
      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #707070;
      }
      /deep/.el-textarea__inner::-moz-input-placeholder,
      /deep/.el-input__inner::-moz-input-placeholder {
        color: #707070;
      }
      /deep/.el-textarea__inner::-ms-input-placeholder,
      /deep/.el-input__inner::-ms-input-placeholder {
        color: #707070;
      }
    }
  }
  .form-footer-box {
    text-align: center;
    .el-button {
      font-size: 16px;
      font-weight: 400;
      padding: 0.08rem 0.24rem;
      background-color: #c9c9c9;
      border-color: #c9c9c9;
    }
    .save-add-form-btn {
      background-color: #a39ff3;
      border-color: #a39ff3;
      margin-right: 0.16rem;
    }
  }
}
</style>
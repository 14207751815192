<template>
  <div class="backstageUser-manage-page">
    <div class="page-top clearfixed">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="手机号">
          <el-input
            v-model="searchForm.cellPhoneNo"
            placeholder="请输入手机号"
            @input="inputLimitNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名账号">
          <el-input
            v-model="searchForm.suName"
            placeholder="请输入账号"
            @input="debounce(getList)"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" class="select-role-item">
          <el-select
            v-model="searchForm.roleId"
            placeholder="请选择角色"
            @change="getList"
            popper-class="cummon-select-drop"
          >
            <el-option
              v-for="item in roleList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="addUserFun">添加用户</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" class="role-table">
      <el-table-column type="index" label="序号" width="150px" align="center">
      </el-table-column>
      <el-table-column prop="cellPhoneNo" label="手机号" align="center">
      </el-table-column>
      <el-table-column prop="suName" label="姓名" align="center">
      </el-table-column>
      <el-table-column prop="loginName" label="账号" align="center">
      </el-table-column>
      <el-table-column prop="roleName" label="角色" align="center">
      </el-table-column>
      <el-table-column
        prop="ifValid"
        label="用户状态"
        class-name="state-column"
        align="center"
      >
        <template slot-scope="scope">
          <span
            :class="scope.row.ifValid == 1 ? 'forbidden-state' : 'start-state'"
          ></span>
          <span v-if="scope.row.ifValid == 1">已禁用</span>
          <span v-else>已启用</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row)" class="editor-span">编辑</span
          ><span
            @click="clickForbidden(scope.row)"
            v-if="scope.row.ifValid == 1"
            class="editor-span"
            >启用</span
          >
          <span
            v-else
            @click="clickForbidden(scope.row, 0)"
            class="ifValid-span"
            >禁用</span
          >
          <span @click="clickResetPass(scope.row, 1)" class="ifValid-span"
            >重置密码</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.length > 0"
      class="custom-pagination"
      background
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, slot"
      :total="total"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="!switchForReset"
        >是否确认禁用该用户，禁用后，用户将无法正常使用</span
      >
      <div v-else class="reset-box">
        您正在为<span>{{ forbiddenTmpItem.loginName }}</span
        >重置密码，密码为<span>{{ normalPassword }}</span
        >，是否确认
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureAlter"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        cellPhoneNo: "",
        suName: "",
        roleId: "",
        pageSize: 10,
        currentPage: 1,
      },
      roleList: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 100,
      jumperPage: 1,
      dialogVisible: false,
      switchForReset: 0,
      forbiddenTmpItem: {},
      normalPassword: "",
      debounce: null,
    };
  },
  created() {
    this.getList();
    this.getRoleList();
    this.getNormal();
    this.debounce = (fn, wait = 1000) => {
      if (window.timeout) clearTimeout(window.timeout);
      window.timeout = setTimeout(() => {
        fn();
      }, wait);
    };
  },
  methods: {
    inputLimitNumber(e){
      this.searchForm.cellPhoneNo=e.replace(/[^\d]/g,'');
      this.debounce(this.getList);
    },
    getNormal() {
      this.$Https("/nft-server/sys/getResetPass").then((res) => {
        if (res.code == 200) {
          this.normalPassword = res.pass;
        }
      });
    },
    getList() {
      this.$Https("/nft-server/sys/list", this.searchForm).then((res) => {
        if (res.code == 200) {
          this.tableData = res.page.data;
          this.currentPage = res.page.currentPage;
          this.total = res.page.totalNumber;
        }
      });
    },
    getRoleList() {
      this.$Http("/nft-server/sysrole/list").then((res) => {
        if (res.code == 200) {
          this.roleList = [{ roleId: "", roleName: "全部" }, ...res.page];
        }
      });
    },
    addUserFun() {
      this.$router.push("/backstageUserAdd");
    },
    clickEditor(row) {
      this.$router.push({
        path: "/backstageUserAdd",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    clickForbidden(row, val) {
      this.forbiddenTmpItem = row;
      if (val == 0 || val == 1) {
        this.switchForReset = val;
      }
      if (row.ifValid == 1) {
        this.sureCancel(this.forbiddenTmpItem);
      } else {
        this.dialogVisible = true;
      }
    },
    sureCancel(row) {
      let tmp = { userId: row.userId, ifValid: row.ifValid == 0 ? 1 : 0 };
      this.$Https("/nft-server/sys/update", tmp).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.message);
        }
        this.dialogVisible = false;
      });
    },
    clickResetPass(row, val) {
      this.forbiddenTmpItem = row;
      this.dialogVisible = true;
      this.switchForReset = val;
    },
    resetPassword(row) {
      let tmp = { userId: row.userId, pwd: "1" };
      this.$Https("/nft-server/sys/update", tmp).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.message);
        }
        this.dialogVisible = false;
      });
    },
    sureAlter() {
      if (this.switchForReset == 0) {
        this.sureCancel(this.forbiddenTmpItem);
      } else {
        this.resetPassword(this.forbiddenTmpItem);
      }
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getList();
    },
    jumperPageFun() {
      // this.currentPage = this.jumperPage;
      this.searchForm.currentPage = this.jumperPage;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-top {
  margin-bottom: 0.2rem;

  .search-form {
    float: left;

    .el-form-item {
      margin-right: 0.5rem;
      margin-bottom: 0;
      /deep/ .el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
        .el-input__inner {
          font-size: 0.12rem;
          height: 0.24rem;
          line-height: 0.24rem;
        }
      }
    }

    .select-role-item {
      /deep/ .el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
  }

  .el-button {
    float: right;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}

.role-table.el-table {
  margin-bottom: 0.4rem;

  &::before {
    background-color: #fff;
  }

  /deep/ thead {
    color: #fff;

    th {
      background-color: #a39ff3;
      font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;
    }
  }

  /deep/ td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 0.12rem;
  }

  .state-column {
    span {
      vertical-align: middle;
    }

    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.09rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
    }

    .forbidden-state {
      background-color: #ebebeb;
    }

    .start-state {
      background-color: #a39ff3;
    }
  }
  .operation-column {
    span {
      cursor: pointer;
    }

    span:not(:last-child) {
      margin-right: 0.16rem;
    }

    .editor-span {
      color: #a39ff3;
    }

    .ifValid-span {
      color: #ff3696;
    }
  }
}

.custom-pagination.el-pagination {
  text-align: center;

  &.is-background {
    /deep/ .el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;

        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }

  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 12px;
    font-weight: 300;
    color: #000;

    .jumper-input {
      width: 0.5rem;

      /deep/ .el-input__inner {
        height: 28px;
      }
    }
  }

  /deep/ button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>

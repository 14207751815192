<template>
  <div class="backstageUser-manage-page">
    <div class="page-top clearfixed">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="手机号">
          <el-input
            v-model="searchForm.telPhone"
            placeholder="请输入手机号"
            @input="inputLimitNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input
            v-model="searchForm.nickName"
            placeholder="请输入昵称"
            @input="debounce(getList)"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册时间" class="select-register-time-item">
          <!-- <el-col :span="11"> -->
            <el-date-picker
              type="datetime"
              placeholder="请选择开始日期"
              v-model="searchForm.startTime"
              @change="getList"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="line" :span="2">至</el-col> -->
          <span class="datetime-split-text" :span="2">至</span>
          <!-- <el-col :span="11"> -->
            <el-date-picker
              type="datetime"
              placeholder="请选择结束日期"
              v-model="searchForm.endTime"
              @change="getList"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" style="width: 100%" class="role-table">
      <el-table-column type="index" label="序号" width="150px" align="center">
      </el-table-column>
      <el-table-column prop="cellPhoneNo" label="手机号" align="center">
      </el-table-column>
      <el-table-column prop="nickName" label="昵称" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="注册时间" align="center">
      </el-table-column>
      <el-table-column
        prop="ifValid"
        label="用户状态"
        class-name="state-column"
        align="center"
      >
        <template slot-scope="scope">
          <span
            :class="scope.row.ifValid == 0 ? 'forbidden-state' : 'start-state'"
          ></span>
          <span v-if="scope.row.ifValid == 1">已禁用</span>
          <span v-else>已启用</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
      >
        <template slot-scope="scope">
          <span
            @click="clickForbidden(scope.row)"
            v-if="scope.row.ifValid == 1"
            class="editor-span"
            >启用</span
          >
          <span v-else @click="clickForbidden(scope.row)" class="ifValid-span"
            >禁用</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.length > 0"
      class="custom-pagination"
      background
      @current-change="handleCurrentChange"
      :current-page.sync="searchForm.currentPage"
      :page-size="searchForm.pageSize"
      layout="prev, pager, next, slot"
      :total="total"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span>是否确认禁用该用户，禁用后，用户将无法正常使用</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureAlter"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        telPhone: "",
        nickName: "",
        startTime: "",
        endTime: "",
        pageSize: 10,
        currentPage: 1,
      },
      tableData: [],
      total: 0,
      jumperPage: 1,
      dialogVisible: false,
      forbiddenTmpItem: {},
      debounce:null
    };
  },
  created() {
    this.getList();
    this.debounce = (fn, wait = 1000) => {
      if (window.timeout) clearTimeout(window.timeout);
      window.timeout = setTimeout(() => {
        fn();
      }, wait);
    };
  },
  methods: {
    inputLimitNumber(e){
      this.searchForm.telPhone=e.replace(/[^\d]/g,'');
      this.debounce(this.getList);
    },
    getList() {
      this.$Https("/nft-server/frontuser/list", this.searchForm).then((res) => {
        if (res.code == 200) {
          this.tableData = res.page.data;
          this.total = res.page.totalNumber;
        }
      });
    },
    addUserFun() {
      this.$router.push("/backstageUserAdd");
    },
    clickForbidden(row, val) {
      this.forbiddenTmpItem = row;
      if (row.ifValid == 0) {
        this.sureCancel(this.forbiddenTmpItem);
      } else {
        this.dialogVisible = true;
      }
    },
    sureCancel(row) {
      let tmp = { userId: row.userId, ifValid: row.ifValid == 0 ? 1 : 0 };
      this.$Https("/nft-server/frontuser/update", tmp).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.message);
        }
        this.dialogVisible = false;
      });
    },
    sureAlter() {
      this.sureCancel(this.forbiddenTmpItem);
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getList();
    },
    jumperPageFun() {
      // this.currentPage = this.jumperPage;
      this.searchForm.currentPage = this.jumperPage;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-top {
  margin-bottom: 0.2rem;

  .search-form {
    float: left;

    .el-form-item {
      margin-right: 0.5rem;
      margin-bottom: 0;
      /deep/ .el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
        .el-input__inner {
          font-size: 0.12rem;
          height: 0.24rem;
          line-height: 0.24rem;
        }
      }
    }

    .select-register-time-item {
      // .line {
      //   text-align: center;
      // }
      // /deep/ .el-icon-arrow-up {
      //   color: #000;

      //   &::before {
      //     content: "\e78f";
      //   }
      // }
      /deep/ .el-input__inner {
        padding-left: 0.15rem;
        padding-right: 0.15rem;
      }
      /deep/ .datetime-split-text {
        text-align: center;
        font-size: 12px;
      }

      /deep/ .el-date-editor.el-input {
        width: 1.65rem;
      }

      /deep/ .el-input__prefix {
        // right: 5px;
        // left: unset;
        left: 0.02rem;
        .el-input__icon{
          width: 0.15rem;
          line-height: 0.24rem;
          font-size: 12px;
        }
      }
      /deep/ .el-input__suffix{
        .el-input__icon{
          width: 0.15rem;
          line-height: 0.24rem;
          font-size: 12px;
        }
      }
    }
  }

  .el-button {
    float: right;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}

.role-table.el-table {
  margin-bottom: 0.4rem;

  &::before {
    background-color: #fff;
  }

  /deep/ thead {
    color: #fff;

    th {
      background-color: #a39ff3;
      font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;
    }
  }

  /deep/ td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 0.12rem;
  }

  .state-column {
    span {
      vertical-align: middle;
    }

    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.09rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
    }

    .forbidden-state {
      background-color: #ebebeb;
    }

    .start-state {
      background-color: #a39ff3;
    }
  }

  .operation-column {
    span {
      cursor: pointer;
    }

    span:not(:last-child) {
      margin-right: 0.16rem;
    }

    .editor-span {
      color: #a39ff3;
    }

    .ifValid-span {
      color: #ff3696;
    }
  }
}

.custom-pagination.el-pagination {
  text-align: center;

  &.is-background {
    /deep/ .el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;

        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }

  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 12px;
    font-weight: 300;
    color: #000;

    .jumper-input {
      width: 0.5rem;

      /deep/ .el-input__inner {
        height: 28px;
      }
    }
  }

  /deep/ button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>

<template>
  <div class="backstageUser-add-page">
    <div class="form-box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="add-role-ruleForm"
      >
        <el-form-item
          label="登录账号"
          prop="loginName"
          class="account-number-item"
        >
          <el-input
            v-model="ruleForm.loginName"
            placeholder="请输入账号"
          ></el-input>
          <span style="margin-left: 0.08rem" v-if="isAdd"
            >初始密码：{{ normalPassword }}</span
          >
        </el-form-item>
        <el-form-item label="姓名" prop="suName" class="account-number-item">
          <el-input
            v-model="ruleForm.suName"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="cellPhoneNo"
          class="phone-number-item"
        >
          <el-input
            v-model="ruleForm.cellPhoneNo"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" class="role-form-item" prop="roleId">
          <el-radio-group v-model="ruleForm.roleId">
            <el-radio
              :label="item.roleId"
              v-for="item in roleList"
              :key="item.roleId"
              >{{ item.roleName }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-footer-box">
      <el-button
        type="primary"
        class="save-add-form-btn"
        @click="submitForm('ruleForm')"
        >保存</el-button
      >
      <el-button type="primary" @click="resetForm('ruleForm')">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        loginName: "",
        suName: "",
        cellPhoneNo: "",
        roleId: "",
        userId: "",
      },
      rules: {
        loginName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        suName: [{ required: true, message: "请输入姓名", trigger: "change" }],
        cellPhoneNo: [
          { required: true, message: "请输入手机号", trigger: "change" },
          {
            validator: function (rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) == false) {
                callback(new Error("手机号格式错误"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
      defaultProps: {
        children: "childFuncs",
        label: "ofName",
      },
      roleList: [],
      detail: {},
      detailData: {},
      isAdd: true,
      normalPassword: "",
    };
  },
  created() {
    this.getRoleList();
    if (this.$route.query.row) {
      this.detail = JSON.parse(this.$route.query.row);
      this.ruleForm.userId = this.detail.userId;
      this.getDetail(this.detail);
      this.isAdd = false;
    } else {
      this.isAdd = true;
      this.getNormal();
    }
  },
  methods: {
    getNormal() {
      this.$Https("/nft-server/sys/getResetPass").then((res) => {
        if (res.code == 200) {
          this.normalPassword = res.pass;
        }
      });
    },
    getDetail(row) {
      this.$Http("/nft-server/sys/info/" + row.userId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.sysUser;
          this.ruleForm.loginName = res.sysUser.loginName;
          this.ruleForm.suName = res.sysUser.suName;
          this.ruleForm.cellPhoneNo = res.sysUser.cellPhoneNo;
          this.ruleForm.roleId = res.sysUser.roleId;
        }
      });
    },
    getRoleList() {
      this.$Http("/nft-server/sysrole/list-on").then((res) => {
        if (res.code == 200) {
          this.roleList = res.page;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addRole();
          } else {
            this.editorRole();
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    addRole() {
      this.$Https("/nft-server/sys/saveObj", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.$router.go(-1);
        } else{
          this.$message.error(res.msg);
        }
      });
    },
    editorRole() {
      this.$Https("/nft-server/sys/updateObj", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("编辑成功！");
          this.$router.go(-1);
        } else{
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.backstageUser-add-page {
  // margin-top: 0.35rem;
  .form-box {
    padding: 0.24rem 0 0.24rem 0.34rem;
    background-color: #fff;
    min-height: calc(100vh - 0.8rem - 1.85rem);
    margin-bottom: 0.3rem;
    .add-role-ruleForm {
      .el-form-item {
        margin-bottom: 0.2rem;
        /deep/.el-form-item__label {
          font-size: 12px;
          line-height: 0.24rem;
        }
        /deep/.el-form-item__content {
          line-height: 0.24rem;
          .el-input__inner {
            font-size: 0.12rem;
            height: 0.24rem;
            line-height: 0.24rem;
          }
          .el-textarea__inner {
            font-size: 0.12rem;
          }
        }
      }
      .role-form-item {
        margin-right: 1.66rem;
        /deep/.el-form-item__label {
          // float: none;
          // display: inline-block;
          // margin-bottom: 0.2rem;
          // text-align: left;
        }
        /deep/.el-form-item__content {
          width: 6.18rem;
          border: 1px solid #d8d8d8;
          padding: 0 0 0.22rem 0.15rem;
          min-height: 2.69rem;
          .el-tree-node__content {
            .el-icon-caret-right {
              font-size: 18px;
              color: #000;
              &.expanded {
                color: #4940e8;
              }
              &.is-leaf {
                color: transparent;
                cursor: default;
              }
            }
          }
          .el-radio-group {
            .el-radio__label {
              font-size: 12px;
            }
          }
        }
      }
      .account-number-item {
        /deep/.el-form-item__content {
          .el-input {
            width: 3.2rem;
          }
        }
      }
      .phone-number-item {
        /deep/.el-form-item__content {
          .el-input {
            width: 5.3rem;
          }
        }
      }
      /deep/.el-textarea__inner::-webkit-input-placeholder,
      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #707070;
      }
      /deep/.el-textarea__inner::-moz-input-placeholder,
      /deep/.el-input__inner::-moz-input-placeholder {
        color: #707070;
      }
      /deep/.el-textarea__inner::-ms-input-placeholder,
      /deep/.el-input__inner::-ms-input-placeholder {
        color: #707070;
      }
    }
  }
  .form-footer-box {
    text-align: center;
    .el-button {
      font-size: 16px;
      font-weight: 400;
      padding: 0.08rem 0.24rem;
      background-color: #c9c9c9;
      border-color: #c9c9c9;
    }
    .save-add-form-btn {
      background-color: #a39ff3;
      border-color: #a39ff3;
      margin-right: 0.16rem;
    }
  }
}
</style>
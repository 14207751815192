<template>
  <div id="app">
    <el-container>
      <el-header class="custom-header">
        <myHeader></myHeader>
      </el-header>
      <el-container>
        <el-aside
          class="custom-aside"
          v-if="showAside"
          :style="{ width: isCollapse ? 0 : '3.3rem' }"
        >
          <Menu></Menu>
          <span
            v-show="!isCollapse"
            class="menu-fold-btn"
            @click="switchCollapse(1)"
            :style="{ left: isCollapse ? '0.2rem' : '3.5rem' }"
            ><i class="el-icon-d-arrow-left"></i
          ></span>
          <span
            v-show="isCollapse"
            class="menu-fold-btn"
            @click="switchCollapse(0)"
            :style="{ left: isCollapse ? '0.2rem' : '3.5rem' }"
            ><i class="el-icon-d-arrow-right"></i
          ></span>
        </el-aside>
        <el-main v-if="isLogin">
          <router-view :key="key" />
        </el-main>
        <el-main v-else class="main-content">
          <router-view :key="key" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLogin: true,
      isCollapse: false,
    };
  },
  watch: {
    $route(to, from) {
      // console.log("app",to,from)
    },
  },
  computed: {
    key() {
      return this.$route.path + new Date();
    },
    showAside: {
      get() {
        return (
          this.$store.state.showMenu ||
          sessionStorage.getItem("showMenu") == "true"
        );
      },
      set(val) {
        this.$store.commit("saveShowMenu", val);
      },
    },
  },
  created() {
    this.isLogin = this.$route.meta?.isLogin;
  },
  beforeUpdate() {
    this.isLogin = this.$route.meta?.isLogin;
    this.showAside =
      this.$store.state.showMenu ||
      sessionStorage.getItem("showMenu") == "true";
  },
  methods: {
    switchCollapse(val) {
      if (val) {
        this.isCollapse = true;
      } else {
        this.isCollapse = false;
      }
    },
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.clearfixed::after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}
.clearfixed {
  zoom: 1;
}
html {
  font-size: 100px;
}
body {
  font-size: 16px;
}
.wrap {
  padding: 0 0.68rem;
}
.img-clear {
  display: block;
}
div {
  box-sizing: border-box;
}
.custom-header {
  height: auto !important;
  padding: 0 !important;
  z-index: 1;
}
.custom-aside {
  min-height: calc(100vh - 0.8rem);
  // width: 3.6rem !important;
  background-color: #fff;
  border-top: 1px solid #f2f0f0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 2;
  .menu-fold-btn {
    cursor: pointer;
    position: absolute;
    top: 1rem;
  }
}
.main-content.el-main {
  padding: 0.46rem 0.68rem 0 0.48rem;
  background-color: #ededed;
}
</style>

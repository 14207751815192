<template>
  <div class="infoMes-manage-page">
    <div class="page-head-btn clearfixed">
      <el-button
        type="primary"
        @click="switchModule(0)"
        class="common-btn"
        :class="activeModule == 0 ? 'active-btn' : ''"
        >快讯抓取</el-button
      >
      <el-button
        type="primary"
        @click="switchModule(1)"
        class="common-btn"
        :class="activeModule == 1 ? 'active-btn' : ''"
        >文章抓取</el-button
      >
    </div>
    <div class="page-top" v-show="activeModule == 0">
      <el-form :inline="true" :model="searchFormQ" class="search-form">
        <el-form-item label="标题" class="title-form-item">
          <el-input
            v-model="searchFormQ.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="抓取时间" class="publish-form-item">
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择开始日期"
            v-model="searchFormQ.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="datetime-split-text" :span="2">至</el-col> -->
          <span class="datetime-split-text" :span="2">至</span>
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择结束日期"
            v-model="searchFormQ.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item
          label="编辑状态"
          class="select-state-item select-common-item"
        >
          <el-select v-model="searchFormQ.isEdit" placeholder="请选择">
            <el-option
              v-for="item in editorList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="抓取网站"
          class="select-web-item select-common-item"
        >
          <el-select v-model="searchFormQ.fromWebSite" placeholder="请选择">
            <el-option
              v-for="item in webList"
              :key="item.dictCode"
              :label="item.dictName"
              :value="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchFun(activeModule)"
        >搜索</el-button
      >
    </div>
    <div class="page-top" v-show="activeModule == 1">
      <el-form :inline="true" :model="searchFormA" class="search-form">
        <el-form-item label="标题" class="title-form-item">
          <el-input
            v-model="searchFormA.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="抓取时间" class="publish-form-item">
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择开始日期"
            v-model="searchFormA.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="datetime-split-text" :span="2">至</el-col> -->
          <span class="datetime-split-text" :span="2">至</span>
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择结束日期"
            v-model="searchFormA.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item
          label="抓取网站"
          class="select-web-item select-common-item"
        >
          <el-select v-model="searchFormA.fromWebSite" placeholder="请选择">
            <el-option
              v-for="item in webList"
              :key="item.dictCode"
              :label="item.dictName"
              :value="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="编辑状态"
          class="select-state-item select-common-item"
        >
          <el-select v-model="searchFormA.isEdit" placeholder="请选择">
            <el-option
              v-for="item in editorList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchFun(activeModule)"
        >搜索</el-button
      >
    </div>
    <el-table
      :data="tableDataQ"
      style="width: 100%"
      class="role-table"
      v-show="activeModule == 0"
    >
      <el-table-column type="index" label="序号" width="80" align="center">
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center" min-width="220">
      </el-table-column>
      <el-table-column prop="createTime" label="抓取时间" align="center">
      </el-table-column>
      <el-table-column prop="fromWebsiteName" label="抓取网站" align="center">
      </el-table-column>
      <el-table-column
        prop="isEdit"
        label="编辑状态"
        class-name="state-column"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <span
            :class="scope.row.isEdit == 0 ? 'forbidden-state' : 'start-state'"
          ></span> -->
          <span v-if="scope.row.isEdit == 1">已编辑</span>
          <span v-else class="no-editor">未编辑</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row, 0)" class="editor-span"
            >编辑</span
          >
          <span
            @click="clickCancel(scope.row, activeModule)"
            class="editor-span"
            >删除</span
          >
          <span
            v-if="scope.row.isEdit == 1"
            @click="clickSurePublishFun(scope.row, 0)"
            class="ifValid-span"
            >确认发布</span
          >
          <span v-else style="min-width: 56px"></span>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="tableDataA"
      style="width: 100%"
      class="role-table"
      v-show="activeModule == 1"
    >
      <el-table-column type="index" label="序号" width="80" align="center">
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center" min-width="220">
      </el-table-column>
      <el-table-column prop="createTime" label="抓取时间" align="center">
      </el-table-column>
      <el-table-column prop="fromWebsiteName" label="抓取网站" align="center">
      </el-table-column>
      <el-table-column
        prop="isEdit"
        label="编辑状态"
        class-name="state-column"
        align="center"
        width="95"
      >
        <template slot-scope="scope">
          <!-- <span
            :class="scope.row.isEdit == 0 ? 'forbidden-state' : 'start-state'"
          ></span> -->
          <span v-if="scope.row.isEdit == 1">已编辑</span>
          <span v-else class="no-editor">未编辑</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row, 1)" class="editor-span"
            >编辑</span
          >
          <span
            @click="clickCancel(scope.row, activeModule)"
            class="editor-span"
            >删除</span
          >
          <span
            v-if="scope.row.isEdit == 1"
            @click="clickSurePublishFun(scope.row, 1)"
            class="ifValid-span"
            >确认发布</span
          >
          <span v-else style="min-width: 56px"></span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-show="tableDataQ.length > 0 && activeModule == 0"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchFormQ.currentPage"
      :page-size="searchFormQ.pageSize"
      layout="prev, pager, next, slot"
      :total="totalQ"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="searchFormQ.jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-pagination
      v-show="tableDataA.length > 0 && activeModule == 1"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchFormA.currentPage"
      :page-size="searchFormA.pageSize"
      layout="prev, pager, next, slot"
      :total="totalA"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="searchFormA.jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="activeModule == 0"
        >是否确认删除，删除后，前台将不再展示该资讯</span
      >
      <span v-else>是否确认删除，删除后，前台将不再展示该文章</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancel"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeModule: 0,
      webList: [],
      searchFormQ: {
        title: "",
        startTime: "",
        endTime: "",
        isEdit: "",
        fromWebSite: "",
        category: 0,
        pageSize: 10,
        currentPage: 1,
        jumperPage: 1,
      },
      searchFormA: {
        title: "",
        startTime: "",
        endTime: "",
        fromWebSite: "",
        category: 1,
        isEdit: "",
        pageSize: 10,
        currentPage: 1,
        jumperPage: 1,
      },
      editorList: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "已编辑",
        },
        {
          id: 0,
          label: "未编辑",
        },
      ],
      tableDataQ: [],
      totalQ: 0,
      tableDataA: [],
      totalA: 0,
      dialogVisible: false,
      cancelTmpItem: {},
    };
  },
  created() {
    let tmpActiveModule = sessionStorage.getItem("activeModule");
    if (tmpActiveModule) {
      this.activeModule = tmpActiveModule;
    }
    this.getWebList();
    this.getListQ();
    this.getListA();
  },
  methods: {
    getWebList() {
      this.$Http("/nft-server/sysdict/listWebSite").then((res) => {
        if (res.code == 200) {
          this.webList = [{ dictCode: "", dictName: "全部" }, ...res.data];
        }
      });
    },
    switchModule(val) {
      this.activeModule = val;
      sessionStorage.setItem("activeModule", val);
    },
    searchFun(val) {
      if (val == 0) {
        this.getListQ();
      } else {
        this.getListA();
      }
    },
    sureCancel() {
      this.cancelInfoMes();
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      if (this.activeModule == 0) {
        this.searchFormQ.jumperPage = val;
        this.getListQ();
      } else {
        this.searchFormA.jumperPage = val;
        this.getListA();
      }
    },
    jumperPageFun() {
      if (this.activeModule == 0) {
        this.searchFormQ.currentPage = this.searchFormQ.jumperPage;
        this.getListQ();
      } else {
        this.searchFormA.currentPage = this.searchFormA.jumperPage;
        this.getListA();
      }
    },
    clickCancel(row, val) {
      this.dialogVisible = true;
      this.cancelTmpItem = row;
    },
    cancelInfoMes() {
      let tmp = { arId: this.cancelTmpItem.arId };
      this.$Https("/nft-server/articlereptile/delete", tmp).then((res) => {
        if (res.code == 200) {
          if (this.activeModule == 0) {
            this.getListQ();
          } else {
            this.getListA();
          }
          this.$message.success("删除成功！");
        } else {
          this.$message.error("删除异常！");
        }
        this.dialogVisible = false;
      });
    },
    clickEditor(row, val) {
      this.$router.push({
        path: val == 0 ? "/infoMesEditQ" : "/infoMesEditA",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    clickSurePublishFun(row, val) {
      let params = {
        arId: row.arId,
      };
      this.$Https("/nft-server/articlereptile/release", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("发布成功！");
          if (val == 0) {
            this.getListQ();
          } else {
            this.getListA();
          }
        } else {
          this.$message.error("发布失败！");
        }
      });
    },
    // 快讯抓取
    getListQ() {
      this.$Https("/nft-server/articlereptile/list", this.searchFormQ).then(
        (res) => {
          if (res.code == 200) {
            this.tableDataQ = res.page.data;
            this.totalQ = res.page.totalNumber;
          }
        }
      );
    },
    // 文章抓取
    getListA() {
      this.$Https("/nft-server/articlereptile/list", this.searchFormA).then(
        (res) => {
          console.log(res);
          if (res.code == 200) {
            this.tableDataA = res.page.data;
            this.totalA = res.page.totalNumber;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page-head-btn {
  margin-bottom: 0.16rem;
  .common-btn {
    float: left;
    background-color: #ccc;
    font-weight: 400;
    font-size: 14px;
    padding: 0.05rem 0.08rem;
    border-color: #ccc;
  }
  .active-btn {
    background-color: #a39ff3;
    border-color: #a39ff3;
  }
}
.page-top {
  display: flex;
  .search-form {
    // float: left;
    margin-right: 15px;
    .el-form-item {
      margin-right: 0.4rem;
      /deep/.el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
      }
      /deep/.el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
        padding-left: 0.05rem;
        border-color: #999;
      }
      /deep/.el-input__icon {
        width: 0.15rem;
      }
    }
    .title-form-item {
      /deep/.el-input__inner {
        width: 2.3rem;
      }
    }
    .publish-form-item {
      /deep/ .el-input__inner {
        padding-left: 0.15rem;
        padding-right: 0.15rem;
      }
      /deep/.datetime-split-text {
        text-align: center;
        font-size: 12px;
      }
      /deep/.el-date-editor.el-input {
        width: 1.65rem;
      }
      /deep/.el-input__prefix {
        // right: 5px;
        // left: unset;
        left: 0.02rem;
        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
      /deep/ .el-input__suffix {
        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
    }
    .select-state-item {
      /deep/.el-form-item__content {
        width: 0.85rem;
      }
    }
    .select-web-item {
      /deep/.el-form-item__content {
        width: 1.2rem;
      }
    }
    .select-isSift-item {
      /deep/.el-form-item__content {
        width: 0.8rem;
      }
    }
    .select-common-item {
      /deep/.el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
  }
  .el-button {
    // float: right;
    height: fit-content;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}
.role-table.el-table {
  margin-bottom: 0.4rem;
  &::before {
    background-color: #fff;
  }
  /deep/thead {
    color: #fff;
    th {
      background-color: #a39ff3;
      font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;
    }
  }
  /deep/td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 12px;
  }
  .state-column {
    span {
      vertical-align: middle;
    }
    .no-editor {
      color: #f00;
    }
    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.13rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
    }
    .forbidden-state {
      background-color: #ebebeb;
    }
    .start-state {
      background-color: #a39ff3;
    }
  }
  .operation-column {
    span {
      cursor: pointer;
      display: inline-block;
    }
    span:not(:last-child) {
      margin-right: 0.16rem;
    }
    .editor-span {
      color: #a39ff3;
    }
    .ifValid-span {
      color: #ff3696;
    }
  }
}
.custom-pagination.el-pagination {
  text-align: center;
  &.is-background {
    /deep/.el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;
        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }
  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 14px;
    font-weight: 300;
    color: #000;
    .jumper-input {
      width: 0.5rem;
      /deep/.el-input__inner {
        height: 28px;
      }
    }
  }
  /deep/button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>

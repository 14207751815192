<template>
  <div class="infoMes-manage-page">
    <div class="page-head-btn clearfixed">
      <el-button
        type="primary"
        @click="switchModule(0)"
        class="common-btn"
        :class="activeModule == 0 ? 'active-btn' : ''"
        >快讯发布
      </el-button>
      <el-button
        type="primary"
        @click="switchModule(1)"
        class="common-btn"
        :class="activeModule == 1 ? 'active-btn' : ''"
        >文章发布
      </el-button>
      <el-button
        type="primary"
        @click="addInfoMes"
        v-show="activeModule == 0"
        class="add-flash-btn"
        >新建快讯<i class="el-icon-edit-outline"></i
      ></el-button>
      <el-button
        type="primary"
        @click="addInfoMes"
        v-show="activeModule == 1"
        class="add-flash-btn"
        >新建文章<i class="el-icon-edit-outline"></i
      ></el-button>
    </div>
    <div class="tags-box" v-show="activeModule == 1">
      <el-tag
        :class="item.atId == activeAtId ? 'active' : ''"
        v-for="item in tags"
        :key="item.atId == '' ? 'a' : item.atId"
        :closable="item.atId != null && item.atId != ''"
        :disable-transitions="false"
        @close="handleClose(item)"
        @click="switchTagsFun(item)"
      >
        <template v-if="item.atId !== ''">
          {{ item.atName }}({{ item.articleNum }})
        </template>
        <template v-else>
          {{ item.atName }}
        </template>
      </el-tag>
      <!-- @keyup.enter.native="handleInputConfirm" -->
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @blur="handleInputConfirm"
        maxlength="10"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput"
        >添加分类<i class="el-icon-circle-plus-outline"></i
      ></el-button>
    </div>
    <!-- clearfixed -->
    <div class="page-top" v-show="activeModule == 0">
      <el-form :inline="true" :model="searchFormQ" class="search-form">
        <el-form-item label="标题" class="title-form-item">
          <el-input
            v-model="searchFormQ.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布时间" class="publish-form-item">
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择开始日期"
            v-model="searchFormQ.relaseStartTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="datetime-split-text" :span="2">至</el-col> -->
          <span class="datetime-split-text" :span="2">至</span>
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择结束日期"
            v-model="searchFormQ.relaseEndTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item
          label="发布状态"
          class="select-state-item select-common-item"
        >
          <el-select v-model="searchFormQ.isRelase" placeholder="请选择">
            <el-option
              v-for="item in editorList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="抓取网站"
          class="select-web-item select-common-item"
        >
          <el-select v-model="searchFormQ.fromWebSite" placeholder="请选择">
            <el-option
              v-for="item in webList"
              :key="item.dictCode"
              :label="item.dictName"
              :value="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="是否精选"
          class="select-isSift-item select-common-item"
        >
          <el-select v-model="searchFormQ.isSelected" placeholder="请选择">
            <el-option
              v-for="item in selectedList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchFun(activeModule)"
        >搜索
      </el-button>
    </div>
    <!-- clearfixed -->
    <div class="page-top" v-show="activeModule == 1">
      <el-form :inline="true" :model="searchFormA" class="search-form">
        <el-form-item label="标题" class="title-form-item">
          <el-input
            v-model="searchFormA.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="作者" class="author-form-item">
          <el-input
            v-model="searchFormA.author"
            placeholder="请输入作者"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布时间" class="publish-form-item">
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择开始日期"
            v-model="searchFormA.relaseStartTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="datetime-split-text" :span="2">至</el-col> -->
          <span class="datetime-split-text" :span="2">至</span>
          <!-- <el-col :span="11"> -->
          <el-date-picker
            type="datetime"
            placeholder="请选择结束日期"
            v-model="searchFormA.relaseEndTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item
          label="发布状态"
          class="select-state-item select-common-item"
        >
          <el-select v-model="searchFormA.isRelase" placeholder="请选择">
            <el-option
              v-for="item in editorList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="抓取网站"
          class="select-web-item select-common-item"
        >
          <el-select v-model="searchFormA.fromWebSite" placeholder="请选择">
            <el-option
              v-for="item in webList"
              :key="item.dictCode"
              :label="item.dictName"
              :value="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchFun(activeModule)"
        >搜索
      </el-button>
    </div>
    <div
      class="page-head-btn move-classify-select-box clearfixed"
      v-show="activeModule == 1 && this.activeAtId != ''"
    >
      <el-checkbox
        class="selectAll"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选
      </el-checkbox>
      <el-select
        v-model="bindClassify"
        placeholder="移动分类"
        class="bindClassify-select"
        @change="changeClassify"
      >
        <el-option
          v-for="item in tagsCopy"
          :key="item.atId == '' ? 'a' : item.atId"
          :label="item.atName"
          :value="item.atId"
        ></el-option>
      </el-select>
      <span
        class="cancel-article-btn"
        v-show="this.activeAtId != null && this.activeAtId != ''"
        @click="cancelArticle"
        >移除</span
      >
    </div>
    <el-table
      :data="tableDataQ"
      style="width: 100%"
      class="role-table"
      v-show="activeModule == 0"
    >
      <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
      <el-table-column prop="title" label="标题" align="center" min-width="220"></el-table-column>
      <el-table-column prop="relaseTime" label="发布时间" align="center"></el-table-column>
      <el-table-column prop="realScanCnt" label="真实浏览量" align="center"></el-table-column>
      <el-table-column prop="fromWebsiteName" label="抓取网站" align="center"></el-table-column>
      <el-table-column
        prop="isRelase"
        label="发布状态"
        class-name="state-column"
        align="center"
        width="95"
      >
        <template slot-scope="scope">
          <!-- <span
            :class="scope.row.isRelase == 0 ? 'forbidden-state' : 'start-state'"
          ></span> -->
          <span v-if="scope.row.isRelase == 0">未上架</span>
          <span v-else>已上架</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isSelected"
        label="是否精选"
        class-name="state-column"
        align="center"
        width="95"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isSelected == 0">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
        min-width="150"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row)" class="editor-span">编辑</span>
          <span
            @click="clickCancel(scope.row, activeModule)"
            class="editor-span"
            >删除</span
          >
          <span
            v-if="scope.row.isRelase == 1"
            @click="clickRelase(scope.row)"
            class="editor-span"
            >下架</span
          >
          <span v-else @click="clickRelase(scope.row)" class="editor-span"
            >上架</span
          >
          <span
            v-if="scope.row.isSelected == 1"
            @click="clickFlag(scope.row)"
            class="ifValid-span"
            >取消标记</span
          >
          <span v-else @click="clickFlag(scope.row)" class="ifValid-span"
            >标记精选</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="tableDataA"
      @selection-change="selectRow"
      style="width: 100%"
      class="role-table article-table"
      ref="bindTable"
      v-show="activeModule == 1"
    >
      <el-table-column
        type="selection"
        width="55"
        class-name="selection-column"
        v-if="this.activeAtId != ''"
      ></el-table-column>
      <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
      <el-table-column prop="title" label="标题" align="center" min-width="220"></el-table-column>
      <el-table-column prop="auName" label="作者" align="center"></el-table-column>
      <el-table-column prop="relaseTime" label="发布时间" align="center"></el-table-column>
      <el-table-column prop="realScanCnt" label="真实浏览量" align="center"></el-table-column>
      <el-table-column prop="fromWebsiteName" label="抓取网站" align="center"></el-table-column>
      <el-table-column
        prop="isRelase"
        label="发布状态"
        class-name="state-column"
        align="center"
        width="95"
      >
        <template slot-scope="scope">
          <!-- <span
            :class="scope.row.isRelase == 0 ? 'forbidden-state' : 'start-state'"
          ></span> -->
          <span v-if="scope.row.isRelase == 0">未上架</span>
          <span v-else>已上架</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        class-name="operation-column"
        align="center"
        min-width="150"
      >
        <template slot-scope="scope">
          <span @click="clickEditor(scope.row)" class="editor-span">编辑</span>
          <span
            @click="clickCancel(scope.row, activeModule)"
            class="editor-span"
            >删除</span
          >
          <span
            v-if="scope.row.isRelase == 1"
            @click="clickRelase(scope.row)"
            class="editor-span"
            >下架</span
          >
          <span v-else @click="clickRelase(scope.row)" class="editor-span"
            >上架</span
          >
          <span
            v-if="scope.row.isHot == 1"
            @click="clickSelected(scope.row)"
            class="ifValid-span"
            >取消热门</span
          >
          <span v-else @click="clickSelected(scope.row)" class="ifValid-span"
            >热门</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableDataQ.length > 0 && activeModule == 0"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchFormQ.currentPage"
      :page-size="searchFormQ.pageSize"
      layout="prev, pager, next, slot"
      :total="totalQ"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="searchFormQ.jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-pagination
      v-if="tableDataA.length > 0 && activeModule == 1"
      class="custom-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchFormA.currentPage"
      :page-size="searchFormA.pageSize"
      layout="prev, pager, next, slot"
      :total="totalA"
    >
      <span class="jumper-input-box">
        跳至
        <el-input
          class="jumper-input"
          v-model.number="searchFormA.jumperPage"
          @blur.stop="jumperPageFun"
        ></el-input>
        页
      </span>
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="activeModule == 0 && !moveTips"
        >是否确认删除，删除后，前台将不再展示该资讯</span
      >
      <span v-if="activeModule == 1 && !moveTips"
        >是否确认删除，删除后，前台将不再展示该文章</span
      >
      <span v-if="moveTips == 1"
        >勾选的所有文章都将被移动到<span style="color: #f00">{{
          selectClassify.atName
        }}</span
        >下，是否确认操作？</span
      >
      <span v-if="moveTips == 2">是否确认删除该分类</span>
      <span v-if="moveTips == 3">是否确认移除该文章</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="sureCancel"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeModule: 0,
      moveTips: 0,
      webList: [],
      searchFormQ: {
        title: "",
        relaseStartTime: "",
        relaseEndTime: "",
        isRelase: "",
        fromWebSite: "",
        isSelected: "",
        pageSize: 10,
        currentPage: 1,
        category: 0,
        jumperPage: 1,
      },
      searchFormA: {
        title: "",
        author: "",
        relaseStartTime: "",
        relaseEndTime: "",
        isRelase: "",
        fromWebSite: "",
        pageSize: 10,
        currentPage: 1,
        category: 1,
        jumperPage: 1,
        atId: "",
      },
      editorList: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "已上架",
        },
        {
          id: 0,
          label: "未上架",
        },
      ],
      selectedList: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "是",
        },
        {
          id: 0,
          label: "否",
        },
      ],
      tableDataQ: [],
      totalQ: 0,
      tableDataA: [],
      totalA: 0,
      dialogVisible: false,
      cancelTmpItem: {},
      tags: [],
      inputVisible: false,
      inputValue: "",
      checkAll: false,
      isIndeterminate: false,
      arIds: "",
      bindClassify: "",
      selectClassify: "",
      activeAtId: "",
      tagsCopy: [],
      tmpTag: {},
    };
  },
  created() {
    let tmpActiveModule = sessionStorage.getItem("activeModuleP");
    if (tmpActiveModule) {
      this.activeModule = tmpActiveModule;
    }
    this.getClassify();
    this.getWebList();
    this.getListQ();
    this.getListA();
  },
  methods: {
    handleCheckAllChange(val) {
      this.$refs.bindTable.toggleAllSelection();
    },
    selectRow(val) {
      this.arIds = "";
      if (val.length > 0) {
        val.forEach((item) => {
          this.arIds += item.arId + ",";
        });
      } else {
        this.arIds = "";
      }
      if (
        val.length > 0 &&
        val.length < this.searchFormA.pageSize &&
        val.length != this.tableDataA.length
      ) {
        this.isIndeterminate = true;
      } else if (
        val.length == this.searchFormA.pageSize ||
        (val.length == this.tableDataA.length && val.length)
      ) {
        this.isIndeterminate = false;
        this.checkAll = true;
      } else if (val.length == 0) {
        this.isIndeterminate = false;
        this.checkAll = false;
      }
    },
    cancelArticle() {
      if (!this.arIds) {
        this.$message.warning("请先选择文章！");
        return;
      }
      this.moveTips = 3;
      this.dialogVisible = true;
    },
    changeClassify(val) {
      console.log("选择", this.arIds);
      if (!this.arIds) {
        this.$message.warning("请先选择文章！");
        this.bindClassify = "";
        return;
      }
      this.selectClassify = this.tags.find((item) => item.atId == val);
      this.moveTips = 1;
      this.dialogVisible = true;
    },
    moveClassify() {
      let params = {
        atId: this.bindClassify,
        arIds: this.arIds,
      };
      this.$Https("/nft-server/article/doWithActicleType", params).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("操作成功！");
            this.getClassify();
            this.getListA();
            this.isIndeterminate = false;
            this.checkAll = false;
            this.bindClassify = "";
          } else {
            this.$message.error(res.msg);
          }
          this.dialogVisible = false;
        }
      );
    },
    getClassify() {
      this.$Http("/nft-server/articletype/list").then((res) => {
        if (res.code == 200) {
          this.tags = [{ atId: "", atName: "全部" }, ...res.data];
          this.tagsCopy = res.data;
        }
      });
    },
    handleClose(tag) {
      // if (tag.articleNum) {
      //   this.$message.error("该分类下有文章，不可删除！");
      //   return;
      // }
      this.moveTips = 2;
      this.dialogVisible = true;
      this.tmpTag = tag;
    },
    deleteClassify() {
      let params = {
        atId: this.tmpTag.atId,
      };
      this.$Https("/nft-server/articletype/delete", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          // this.tags.splice(this.tags.indexOf(this.tmpTag), 1);
          this.getClassify();
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    switchTagsFun(item) {
      if (item.atId == null) {
        this.searchFormA.atId = 0;
      } else {
        this.searchFormA.atId = item.atId;
      }
      this.activeAtId = item.atId;
      this.getListA();
    },
    showInput() {
      if (this.tags.length == 10) {
        this.$message.error("最多添加9个分类！");
        return;
      }
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue.split(" ").join("").length == 0) {
        this.$message.error("分类名称不能为空");
        this.inputVisible = false;
        return;
      }
      let params = {
        atName: inputValue,
      };
      this.$Https("/nft-server/articletype/save", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.inputVisible = false;
          this.inputValue = "";
          this.getClassify();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getWebList() {
      this.$Http("/nft-server/sysdict/listWebSite").then((res) => {
        if (res.code == 200) {
          this.webList = [{ dictCode: "", dictName: "全部" }, ...res.data];
        }
      });
    },
    switchModule(val) {
      this.activeModule = val;
      this.moveTips = 0;
      sessionStorage.setItem("activeModuleP", val);
    },
    searchFun(val) {
      if (val == 0) {
        this.getListQ();
      } else {
        this.getListA();
      }
    },
    addInfoMes() {
      this.$router.push({
        path: this.activeModule == 0 ? "/infoMesAddPQ" : "/infoMesAddPA",
        query: {
          category: this.activeModule,
        },
      });
    },
    clickEditor(row) {
      if (row.isRelase == 1) {
        let str = row.category == 0 ? "快讯" : "文章";
        this.$message.error(`请先将${str}下架后执行编辑操作`);
        return;
      }
      this.$router.push({
        path: this.activeModule == 0 ? "/infoMesAddPQ" : "/infoMesAddPA",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    clickRelase(row) {
      let params = {
        arId: row.arId,
        isRelase: row.isRelase == 0 ? 1 : 0,
      };
      this.$Https("/nft-server/article/open-close", params).then((res) => {
        if (res.code == 200) {
          if (this.activeModule == 0) {
            this.getListQ();
          } else {
            this.getListA();
          }
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    clickFlag(row) {
      let params = {
        arId: row.arId,
        isSelected: row.isSelected == 0 ? 1 : 0,
      };
      this.$Https("/nft-server/article/open-close", params).then((res) => {
        if (res.code == 200) {
          if (this.activeModule == 0) {
            this.getListQ();
          } else {
            this.getListA();
          }
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    clickSelected(row) {
      let params = {
        arId: row.arId,
        isHot: row.isHot == 0 ? 1 : 0,
      };
      this.$Https("/nft-server/article/open-close", params).then((res) => {
        if (res.code == 200) {
          if (this.activeModule == 0) {
            this.getListQ();
          } else {
            this.getListA();
          }
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    sureCancel() {
      if (!this.moveTips) {
        this.cancelInfoMes();
      } else if (this.moveTips == 1) {
        this.moveClassify();
      } else if (this.moveTips == 2) {
        this.deleteClassify();
      } else if (this.moveTips == 3) {
        this.multipleCA();
      }
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      if (this.activeModule == 0) {
        this.searchFormQ.jumperPage = val;
        this.getListQ();
      } else {
        this.searchFormA.jumperPage = val;
        this.getListA();
      }
    },
    jumperPageFun() {
      if (this.activeModule == 0) {
        this.searchFormQ.currentPage = this.searchFormQ.jumperPage;
        this.getListQ();
      } else {
        this.searchFormA.currentPage = this.searchFormA.jumperPage;
        this.getListA();
      }
    },
    clickCancel(row, val) {
      if (row.isRelase == 1) {
        let str = row.category == 0 ? "快讯" : "文章";
        this.$message.error(`请先将${str}下架后执行编辑操作`);
        return;
      }
      this.dialogVisible = true;
      this.cancelTmpItem = row;
    },
    cancelInfoMes() {
      let tmp = { arId: this.cancelTmpItem.arId };
      this.$Https("/nft-server/article/delete", tmp).then((res) => {
        if (res.code == 200) {
          if (this.activeModule == 0) {
            this.getListQ();
          } else {
            this.getListA();
            this.arIds = "";
          }
          this.$message.success("删除成功！");
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    multipleCA() {
      let tmp = { atId: this.activeAtId, arIds: this.arIds };
      this.$Https("/nft-server/articletype/remove", tmp).then((res) => {
        if (res.code == 200) {
          this.getListA();
          this.$message.success("移除成功！");
          this.arIds = "";
          this.getClassify()
        } else {
          this.$message.error(res.msg);
        }
        this.dialogVisible = false;
      });
    },
    // 快讯发布
    getListQ() {
      this.$Https("/nft-server/article/list", this.searchFormQ).then((res) => {
        if (res.code == 200) {
          this.tableDataQ = res.page.data;
          this.totalQ = res.page.totalNumber;
        }
      });
    },
    // 文章发布
    getListA() {
      this.$Https("/nft-server/article/list", this.searchFormA).then((res) => {
        if (res.code == 200) {
          this.tableDataA = res.page.data;
          this.totalA = res.page.totalNumber;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-head-btn {
  margin-bottom: 0.16rem;
  .selectAll {
    float: left;
    line-height: 0.24rem;
    margin-right: 0.08rem;
    /deep/.el-checkbox__label {
      font-size: 12px;
    }
  }

  .bindClassify-select {
    width: 1.7rem;

    /deep/ .el-input__inner {
      padding-left: 0.05rem;
      border-color: #999;
      font-size: 0.12rem;
      height: 0.24rem;
      line-height: 0.24rem;
    }
    /deep/ .el-icon-arrow-up {
      color: #000;
      line-height: 0.24rem;
      &::before {
        content: "\e78f";
      }
    }
  }

  .common-btn {
    float: left;
    background-color: #ccc;
    font-weight: 400;
    font-size: 14px;
    padding: 0.05rem 0.08rem;
    border-color: #ccc;
  }

  .active-btn {
    background-color: #a39ff3;
    border-color: #a39ff3;
  }

  .add-flash-btn {
    font-size: 14px;
    font-weight: 400;
    padding: 0.05rem 0 0.05rem 0.08rem;
    float: right;
    background-color: transparent;
    color: #4940e8;
    border-color: transparent;
  }
}

.move-classify-select-box {
  padding-left: 0;
  .cancel-article-btn {
    cursor: pointer;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    color: #4940e8;
    vertical-align: middle;
  }
}

.tags-box {
  margin-bottom: 0.16rem;

  .el-tag {
    margin-right: 0.08rem;
    background-color: #ccc;
    color: #fff;
    cursor: pointer;
    height: 0.26rem;
    line-height: 0.24rem;
    /deep/ .el-tag__close {
      color: #fff;
    }
  }
  .active.el-tag {
    background-color: #a39ff3;
  }

  .input-new-tag {
    max-width: 1rem;
  }

  .button-new-tag {
    color: #a39ff3;
    border: none;
    background-color: transparent;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
  }
}

.page-top {
  display: flex;
  .search-form {
    // float: left;
    margin-right: 15px;
    .el-form-item {
      margin-right: 0.3rem;

      &:last-child {
        margin-right: 0;
      }

      /deep/ .el-form-item__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        padding-right: 0.08rem;
        line-height: 0.24rem;
      }
      /deep/.el-form-item__content {
        line-height: 0.24rem;
      }
      /deep/.el-input__inner {
        font-size: 0.12rem;
        height: 0.24rem;
        line-height: 0.24rem;
        padding-left: 0.05rem;
        border-color: #999;
      }
    }

    .title-form-item {
      /deep/ .el-input__inner {
        width: 2.5rem;
      }
    }

    .author-form-item {
      /deep/ .el-input__inner {
        width: 1.3rem;
      }
    }

    .publish-form-item {
      /deep/ .el-input__inner {
        padding-left: 0.15rem;
        padding-right: 0.15rem;
      }
      /deep/ .datetime-split-text {
        text-align: center;
        font-size: 12px;
      }

      /deep/ .el-date-editor.el-input {
        width: 1.65rem;
      }

      /deep/ .el-input__prefix {
        // right: 5px;
        // left: unset;
        left: 0.02rem;
        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
      /deep/ .el-input__suffix {
        .el-input__icon {
          width: 0.15rem;
          line-height: 0.24rem;
        }
      }
    }

    .select-state-item {
      /deep/ .el-form-item__content {
        width: 0.85rem;
      }
    }

    .select-web-item {
      /deep/ .el-form-item__content {
        width: 1.2rem;
      }
    }

    .select-isSift-item {
      /deep/ .el-form-item__content {
        width: 0.8rem;
      }
    }

    .select-common-item {
      /deep/ .el-icon-arrow-up {
        color: #000;
        line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
  }

  .el-button {
    // float: right;
    height: fit-content;
    background-color: #a39ff3;
    font-weight: 400;
    font-size: 12px;
    padding: 0.05rem 0.08rem;
    border-color: #a39ff3;
  }
}

.role-table.el-table {
  margin-bottom: 0.4rem;

  &::before {
    background-color: #fff;
  }

  /deep/ thead {
    color: #fff;

    th {
      background-color: #a39ff3;
      font-size: 14px;
      font-weight: 500;
      padding: 0.12rem 0;
    }
  }

  /deep/ td {
    font-weight: 300;
    padding: 0.12rem 0;
    color: #000;
    font-size: 12px;
  }

  .state-column {
    span {
      vertical-align: middle;
    }

    .forbidden-state,
    .start-state {
      display: inline-block;
      margin-right: 0.13rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
    }

    .forbidden-state {
      background-color: #ebebeb;
    }

    .start-state {
      background-color: #a39ff3;
    }
  }

  .operation-column {
    span {
      cursor: pointer;
      display: inline-block;
    }

    span:not(:last-child) {
      margin-right: 0.16rem;
    }

    .editor-span {
      color: #a39ff3;
    }

    .ifValid-span {
      color: #ff3696;
      min-width: 0.56rem;
    }
  }
}
.article-table.el-table {
  /deep/ thead {
    th {
      &.selection-column > .cell {
        display: none;
      }
    }
  }
}

.custom-pagination.el-pagination {
  text-align: center;

  &.is-background {
    /deep/ .el-pager {
      li {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin: 0 7px;

        &.active {
          background-color: #a39ff3;
        }
      }
    }
  }

  .jumper-input-box {
    margin-left: 0.24rem;
    font-size: 14px;
    font-weight: 300;
    color: #000;

    .jumper-input {
      width: 0.5rem;

      /deep/ .el-input__inner {
        height: 28px;
      }
    }
  }

  /deep/ button {
    background-color: #a39ff3;
    color: #fff;
  }
}
</style>

<template>
  <div class="infoMes-edit-Q">
    <div class="form-box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="edit-form"
      >
        <el-form-item label="网站名称" class="common-form-item" prop="naName">
          <el-input
            v-model="ruleForm.naName"
            placeholder="请输入名称"
            maxlength="40"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类" class="common-form-item select-auid-item" prop="ntId">
          <el-select
            v-model="ruleForm.ntId"
            placeholder="请选择"
            class="bindClassify-select"
          >
            <el-option
              v-for="item in tags"
              :key="item.ntId"
              :label="item.ntName"
              :value="item.ntId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接地址" class="common-form-item" prop="naLink">
          <el-input
            v-model="ruleForm.naLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="网站简介"
          class="common-form-item intro-form-item"
          prop="introduction"
        >
          <el-input
            type="textarea"
            v-model="ruleForm.introduction"
            placeholder="请输入简介"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="网站logo" class="cover-form-item" prop="logUrl">
          <el-upload
            class="avatar-uploader"
            :action="url + '/nft-server/common/uploadImage'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              （图片大小限制为5MB，图片尺寸为200px*200px)
            </div>
          </el-upload>
          <img v-if="ruleForm.logUrl" :src="ruleForm.logUrl" class="avatar" />
        <div v-else class="default-img-box">请上传图片</div>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-footer-box">
      <el-button
        type="primary"
        @click="submitForm('ruleForm', 0)"
        class="save-btn-common"
        >保存</el-button
      >
      <el-button @click="resetForm('ruleForm')">取消</el-button>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="showDialogText == 0"
        >图片格式错误，请上传bmp，jpg，png，gif，jpeg格式图片</span
      >
      <span v-else>图片过大，上传失败，请重新上传</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisible = false"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        naName: "",
        naLink: "",
        introduction: "",
        logUrl: "",
        naId: "",
        ntId:0
      },
      rules: {
        naName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        ntId: [{ required: true, message: "请选择分类", trigger: "change" }],
        naLink: [{ required: true, message: "请输入链接", trigger: "blur" }],
        introduction: [
          { required: true, message: "请输入简介", trigger: "blur" },
        ],
        logUrl: [
          { required: true, message: "请上传网站logo", trigger: "blur" },
        ],
      },
      tags:[],
      detailData: {},
      webList: [],
      authorList: [],
      dialogVisible: false,
      showDialogText: 0,
      url: "",
      headers: "",
      detail: {},
      isAdd: true,
    };
  },
  created() {
    this.getNavigationType();
    this.url = process.env.VUE_APP_BASEURL;
    this.headers = { token: sessionStorage.getItem("token") };
    if (this.$route.query.row) {
      this.detail = JSON.parse(this.$route.query.row);
      this.ruleForm.naId = this.detail.naId;
      this.getInMesDetail(this.detail);
      this.isAdd = false;
    } else {
      this.isAdd = true;
    }
  },
  methods: {
    getNavigationType() {
      this.$Http("/nft-server/navigationtype/list").then((res) => {
        if (res.code == 200) {
          res.data.forEach(item => {
            if(item.ntId==null){
              item.ntId=0;
            }
          });
          this.tags = res.data;
        }
      });
    },
    submitForm(formName, val) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addInfoMes();
          } else {
            this.editorInfoMes();
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    addInfoMes() {
      if(this.ruleForm.ntId==0){
        this.ruleForm.ntId=null;
      }
      this.$Https("/nft-server/navigation/save", this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    editorInfoMes() {
      if(this.ruleForm.ntId==0){
        this.ruleForm.ntId=null;
      }
      this.$Https("/nft-server/navigation/update", this.ruleForm).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("操作成功！");
            this.$router.go(-1);
          } else {
            this.$message.error("操作失败！");
          }
        }
      );
    },
    getInMesDetail(row) {
      this.$Http("/nft-server/navigation/info/" + row.naId).then((res) => {
        if (res.code == 200) {
          this.detailData = res.navigation;
          this.ruleForm.naName = res.navigation.naName;
          this.ruleForm.naLink = res.navigation.naLink;
          this.ruleForm.introduction = res.navigation.introduction;
          this.ruleForm.logUrl = res.navigation.logUrl;
          this.ruleForm.introduction = res.navigation.introduction;
          this.ruleForm.naId = res.navigation.naId;
          this.ruleForm.ntId=res.navigation.ntId==null ? 0 : res.navigation.ntId;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.$message.success("上传成功！");
        // this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
        this.ruleForm.logUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      const isType =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/bmp" ||
        file.type == "image/gif" ||
        file.type == "image/png";
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isType) {
        this.showDialogText = 0;
        this.dialogVisible = true;
      }
      if (!isLt5M) {
        this.showDialogText = 1;
        this.dialogVisible = true;
      }
      return isLt5M && isType;
    },
  },
};
</script>

<style lang="scss" scoped>
.infoMes-edit-Q {
  .form-box {
    padding: 0.25rem 0 0.25rem 0;
    background-color: #fff;
    min-height: calc(100vh - 0.8rem - 1.85rem);
    margin-bottom: 0.3rem;
    .edit-form {
      max-width: 6.18rem;
      .el-form-item {
        margin-bottom: 0.2rem;
        /deep/.el-form-item__label {
          font-size: 12px;
          line-height: 0.24rem;
        }
        /deep/.el-form-item__content {
          line-height: 0.24rem;
          .el-input__inner {
            font-size: 0.12rem;
            height: 0.24rem;
            line-height: 0.24rem;
          }
          .el-textarea__inner {
            font-size: 0.12rem;
          }
        }
        .custom-text-area {
          height: 1.06rem;
          /deep/.el-textarea__inner {
            height: 100%;
          }
        }
      }
    .select-auid-item {
      /deep/.el-input__inner {
        padding-left: 0.1rem;
      }
      /deep/.el-input__prefix {
        left: unset;
        right: 0.3rem;
        .el-input__icon {
          font-weight: bolder;
          line-height: 0.24rem;
        }
      }
      /deep/.el-icon-arrow-up {
        color: #000;
          line-height: 0.24rem;
        &::before {
          content: "\e78f";
        }
      }
    }
      .authority-form-item {
        // /deep/.el-form-item__label {
        //   float: none;
        //   display: inline-block;
        //   margin-bottom: 0.2rem;
        // }
        /deep/.el-form-item__content {
          border: 1px solid #d8d8d8;
          padding: 0.1rem 0 0.22rem 0.22rem;
          min-height: 2.69rem;
          .el-tree-node__content {
            .el-icon-caret-right {
              font-size: 12px;
              color: #000;
              &.expanded {
                color: #4940e8;
              }
              &.is-leaf {
                color: transparent;
                cursor: default;
              }
            }
          }
        }
      }
      /deep/.el-textarea__inner::-webkit-input-placeholder,
      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #707070;
      }
      /deep/.el-textarea__inner::-moz-input-placeholder,
      /deep/.el-input__inner::-moz-input-placeholder {
        color: #707070;
      }
      /deep/.el-textarea__inner::-ms-input-placeholder,
      /deep/.el-input__inner::-ms-input-placeholder {
        color: #707070;
      }
    }
  }
  .form-footer-box {
    text-align: center;
    .el-button {
      font-size: 16px;
      font-weight: 400;
      padding: 0.08rem 0.24rem;
      background-color: #c9c9c9;
      border-color: #c9c9c9;
    }
    .save-btn-common {
      background-color: #a39ff3;
      border-color: #a39ff3;
      margin-right: 0.16rem;
    }
  }
  .edit-form {
    /deep/.el-form-item__label {
      font-weight: 300;
      color: #000;
      padding-right: 0.1rem;
    }
    .common-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 3.44rem;
        .el-input__inner {
          padding-left: 0.1rem;
        }
      }
    }
    .intro-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 6.21rem;
        .el-textarea__inner {
          min-height: 1.57rem !important;
          padding-left: 0.1rem;
        }
      }
    }
    .cover-form-item {
      /deep/.el-form-item__content {
        .avatar-uploader {
          .el-button {
            font-weight: 400;
            font-size: 12px;
            padding: 0.05rem 0.08rem;
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
        img,
        .default-img-box {
          width: 2.28rem;
          height: 1.72rem;
          vertical-align: middle;
        }
        .default-img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #999999;
          font-weight: 300;
        }
        .el-upload__tip {
          display: inline-block;
          margin-left: 0.1rem;
          margin-top: 0;
          margin-bottom: 0.14rem;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
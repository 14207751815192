<template>
  <div class="login-changepassword-page">
    <div class="sys-title">元宇宙之心管理后台</div>
    <div v-if="showLogin">
      <el-form
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        class="loginForm"
      >
        <el-form-item prop="loginName">
          <el-input
            type="text"
            v-model="loginForm.loginName"
            placeholder="请输入账号"
            @keyup.enter.native="login"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input
            type="password"
            v-model="loginForm.pwd"
            placeholder="请输入6-16位密码"
            @keyup.enter.native="login"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="verification">
          <el-input
            type="text"
            v-model="loginForm.verification"
            placeholder="请输入5位验证码"
            @keyup.enter.native="login"
            clearable
          ></el-input>
          <el-image
            :src="codeUrl"
            class="img-code"
            alt="看不清，换一张"
            @click.native="getImgCode"
          ></el-image>
        </el-form-item>
        <el-form-item class="login-item">
          <el-button class="login-btn" type="primary" @click="login"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <el-form
        :model="changePassForm"
        :rules="changePassRules"
        ref="changePassForm"
        class="changePassForm"
      >
        <el-form-item prop="oldPassword">
          <el-input
            type="password"
            v-model="changePassForm.oldPassword"
            placeholder="请输入原密码"
            @keyup.enter.native="alter"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
            type="password"
            v-model="changePassForm.newPassword"
            placeholder="请输入6-16位新密码"
            @keyup.enter.native="alter"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="repeatPassword">
          <el-input
            type="password"
            v-model="changePassForm.repeatPassword"
            placeholder="请确认新密码"
            @keyup.enter.native="alter"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="login-item">
          <el-button class="login-btn" type="primary" @click="alter"
            >确认</el-button
          >
          <el-button type="primary" @click="cancelChangePass">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入原密码"));
      } else {
        // if (this.changePassForm.repeatPassword !== '') {
        //   this.$refs.ruleForm.validateField('checkPass');
        // }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入6-16位新密码"));
      } else {
        // const reg=/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\._\?]).{6,16}$/;
        const reg = /^[a-zA-Z\._\?\d]{6,16}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("密码格式不符合要求，请重新输入"));
        }
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认新密码"));
      } else if (value !== this.changePassForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      showLogin: true,
      loginForm: {
        loginName: "",
        pwd: "",
        verification: "",
        uuid: "",
      },
      loginRules: {
        loginName: [{ required: true, message: "请输入账号", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        verification: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      codeUrl: "",
      changePassForm: {
        oldPassword: "",
        newPassword: "",
        repeatPassword: "",
        token: "",
      },
      changePassRules: {
        oldPassword: { validator: validatePass, trigger: "blur" },
        newPassword: { validator: validatePass2, trigger: "blur" },
        repeatPassword: { validator: validatePass3, trigger: "blur" },
      },
    };
  },
  created() {
    // window.sessionStorage.clear();
    this.$store.commit("saveShowMenu", false);
    this.$store.commit("saveBreadcrumbData", []);
    sessionStorage.setItem("showMenu", false);
    if (this.$route.query?.reuse == "true") {
      this.showLogin = false;
    } else {
      this.getImgCode();
      if (sessionStorage.getItem("token")) {
        sessionStorage.removeItem("token");
      }
    }
  },
  mounted() {
    if (this.showLogin == false && window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  methods: {
    goBack() {
      this.$store.commit("saveShowMenu", true);
      window.history.back();
      history.pushState(null, null, document.URL);
    },
    login() {
      this.$refs["loginForm"].validate((valid) => {
        if (!valid) return;
        this.$Https("/nft-server/sys/login", this.loginForm).then((res) => {
          if (res.statusCode == 200) {
            sessionStorage.setItem("token", res.content.token);
            this.$store.commit("saveShowMenu", true);
            sessionStorage.setItem("showMenu", true);
            this.getMenuList();
          } else {
            this.$message.error(res.message);
            this.getImgCode();
          }
        });
      });
    },
    getImgCode() {
      let random = Math.random();
      this.$Http("/nft-server/sys/captcha", {
        params: {
          uuid: random,
        },
        responseType: "blob",
      }).then((res) => {
        this.codeUrl = window.URL.createObjectURL(res);
        this.loginForm.uuid = random;
      });
    },
    alter() {
      this.$refs["changePassForm"].validate((valid) => {
        if (!valid) return;
        this.$Https("/nft-server/sys/updatapassword", this.changePassForm).then(
          (res) => {
            if (res.statusCode == 200) {
              this.$message.success(res.message);
              this.$router.replace({
                path: "/",
                query: {
                  reuse: false,
                },
              });
            } else {
              this.$message.error(res.message);
            }
          }
        );
      });
    },
    cancelChangePass() {
      this.$store.commit("saveShowMenu", true);
      sessionStorage.setItem("showMenu", true);
      this.$router.go(-1);
    },
    getMenuList() {
      this.$Http("/nft-server/orgfunc/listRoleByUser").then((res) => {
        if (res.code == 200) {
          if (res.data?.[0].ofUrl) {
            this.$router.push({
              path: res.data[0].ofUrl,
            });
          } else {
            this.$router.push({
              path: res.data[0].childFuncs[0].ofUrl,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-changepassword-page {
  padding-top: 1rem;
  .sys-title {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin-bottom: 0.6rem;
  }
  .loginForm {
    margin: 0 auto;
    width: 4.1rem;
    /deep/.el-input__inner {
      height: 0.74rem;
      line-height: 0.74rem;
      border-width: 0 0 1px 0;
      border-color: #707070;
      border-radius: 0;
    }
    .img-code {
      height: 0.4rem;
      width: 1rem;
      border-radius: 4px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      margin-top: -0.2rem;
      right: 0.24rem;
    }
    .login-item {
      margin-top: 0.5rem;
      text-align: center;
      .el-button {
        width: 1.4rem;
        background-color: #c9c9c9;
        border-color: #c9c9c9;
        color: #ffffff;
        border-radius: 0.65rem;
        font-size: 14px;
        font-weight: 400;
        padding-top: 0.08rem;
        padding-bottom: 0.08rem;
        // &:hover {
        //   background-color: #3e35e7;
        //   border-color: #3e35e7;
        // }
      }
      .login-btn {
        background-color: #3e35e7;
        border-color: #3e35e7;
      }
    }
  }
  .changePassForm {
    margin: 0 auto;
    width: 4.1rem;
    /deep/.el-input__inner {
      height: 0.74rem;
      line-height: 0.74rem;
      border-width: 0 0 1px 0;
      border-color: #707070;
      border-radius: 0;
    }
    .login-item {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      .el-button {
        width: 1.4rem;
        background-color: #c9c9c9;
        border-color: #c9c9c9;
        color: #ffffff;
        border-radius: 0.65rem;
        font-size: 14px;
        font-weight: 400;
        padding-top: 0.08rem;
        padding-bottom: 0.08rem;
        margin-right: 0.3rem;
        // &:first-child:hover {
        //   background-color: #3e35e7;
        //   border-color: #3e35e7;
        // }
      }
      .login-btn {
        background-color: #3e35e7;
        border-color: #3e35e7;
      }
    }
  }
}
</style>
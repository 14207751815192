<template>
  <div class="infoMes-edit-Q">
    <div class="form-box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="edit-form"
      >
        <el-form-item
          label="首页轮播图"
          class="cover-form-item"
          prop="imageUrl"
        >
          <!-- <el-upload
            class="avatar-uploader"
            :action="url + '/nft-server/common/uploadImage'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="primary">上传图片</el-button>
            <div slot="tip" class="el-upload__tip">
              （图片大小限制为5MB，图片尺寸为1090px*417px)
            </div>
          </el-upload> -->
          <el-button
            @click="showDialogFun('100001')"
            size="small"
            type="primary"
            class="trigger-upload-btn"
            :disabled="indexBannerList.length > 9"
            >新增banner</el-button
          >
          <div class="el-upload__tip">
            <el-button size="small" type="primary" @click="saveBanner('100001')"
              >保存</el-button
            >
            （图片大小限制为5MB，图片尺寸为1090px*417px)
          </div>
        </el-form-item>
        <div class="index-slides-list-box">
          <div
            v-show="tmpBannerList.length > 0 && tmpBiType == '100001'"
            class="index-slides-item"
            v-for="(item, index) in tmpBannerList"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <div><img :src="item.imageUrl" alt="" /></div>
            <span>{{ item.href }}</span>
          </div>
          <div
            v-show="indexBannerList.length > 0"
            class="index-slides-item"
            v-for="(item, index) in indexBannerList"
            :key="item.biId"
          >
            <span>{{ item.bannerName }}</span>
            <div><img :src="item.imageUrl" alt="" /></div>
            <span>{{ item.linkUrl }}</span>
            <div class="slides-operation">
              <span @click="clickEditor(item, '100001', 1)">编辑</span
              ><span @click="clickDelete(item)">删除</span
              ><span
                @click="moveBanner(item, $event, 0, 'indexBannerList', index)"
                :class="
                  index == indexBannerList.length - 1 ? 'setForbidden' : ''
                "
                >下移</span
              ><span
                @click="moveBanner(item, $event, 1, 'indexBannerList', index)"
                :class="index == 0 ? 'setForbidden' : ''"
                >上移</span
              >
            </div>
          </div>
        </div>
        <el-form-item
          label="首页中部轮播图"
          class="cover-form-item"
          prop="imageUrl"
        >
          <el-button
            @click="showDialogFun('100002')"
            size="small"
            type="primary"
            class="trigger-upload-btn"
            :disabled="indexMiddleBannerList.length > 1"
            >新增banner</el-button
          >
          <div class="el-upload__tip">
            <el-button size="small" type="primary" @click="saveBanner('100002')"
              >保存</el-button
            >
            （图片大小限制为5MB，图片尺寸为1090px*417px)
          </div>
        </el-form-item>
        <div class="index-slides-list-box">
          <div
            v-show="tmpBannerList.length > 0 && tmpBiType == '100002'"
            class="index-slides-item"
            v-for="(item, index) in tmpBannerList"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <div><img :src="item.imageUrl" alt="" /></div>
            <span>{{ item.href }}</span>
          </div>
          <div
            v-show="indexMiddleBannerList.length > 0"
            class="index-slides-item"
            v-for="(item, index) in indexMiddleBannerList"
            :key="item.biId"
          >
            <span>{{ item.bannerName }}</span>
            <div><img :src="item.imageUrl" alt="" /></div>
            <span>{{ item.linkUrl }}</span>
            <div class="slides-operation">
              <span @click="clickEditor(item, '100002', 1)">编辑</span
              ><span @click="clickDelete(item)">删除</span
              ><span
                @click="
                  moveBanner(item, $event, 0, 'indexMiddleBannerList', index)
                "
                :class="
                  index == indexMiddleBannerList.length - 1
                    ? 'setForbidden'
                    : ''
                "
                >下移</span
              ><span
                @click="
                  moveBanner(item, $event, 1, 'indexMiddleBannerList', index)
                "
                :class="index == 0 ? 'setForbidden' : ''"
                >上移</span
              >
            </div>
          </div>
        </div>
        <el-form-item label="专题" class="cover-form-item add-margin-form-item">
          <el-upload
            style="margin-bottom: 0.2rem"
            class="avatar-uploader"
            :action="url + '/nft-server/common/uploadImage'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccessSpecial"
            :on-remove="handleRemoveSpecial"
            :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="primary">上传图片</el-button>
            <div slot="tip" class="el-upload__tip">
              <el-button
                size="small"
                type="primary"
                @click.stop="saveBanner('100003')"
                >保存</el-button
              >
              （图片大小限制为5MB，图片尺寸为990px*340px)
            </div>
          </el-upload>
          <img
            v-if="ruleForm.viewSpecialUrl"
            :src="ruleForm.viewSpecialUrl"
            class="avatar"
          />
          <div v-else class="default-img-box">请上传图片</div>
        </el-form-item>
        <el-form-item
          label="行业导航"
          class="cover-form-item add-margin-form-item"
        >
          <el-upload
            style="margin-bottom: 0.2rem"
            class="avatar-uploader"
            :action="url + '/nft-server/common/uploadImage'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccessIndustry"
            :on-remove="handleRemoveIndustry"
            :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="primary">上传图片</el-button>
            <div slot="tip" class="el-upload__tip">
              <el-button
                size="small"
                type="primary"
                @click.stop="saveBanner('100005')"
                >保存</el-button
              >
              （图片大小限制为5MB，图片尺寸为990px*340px)
            </div>
          </el-upload>
          <img
            v-if="ruleForm.viewIndustryUrl"
            :src="ruleForm.viewIndustryUrl"
            class="avatar"
          />
          <div v-else class="default-img-box">请上传图片</div>
        </el-form-item>
        <el-form-item
          label="NFT作品"
          class="cover-form-item add-margin-form-item"
        >
          <el-upload
            style="margin-bottom: 0.2rem"
            class="avatar-uploader"
            :action="url + '/nft-server/common/uploadImage'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccessWorks"
            :on-remove="handleRemoveWorks"
            :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="primary">上传图片</el-button>
            <div slot="tip" class="el-upload__tip">
              <el-button
                size="small"
                type="primary"
                @click.stop="saveBanner('100004')"
                >保存</el-button
              >
              （图片大小限制为5MB，图片尺寸为990px*340px)
            </div>
          </el-upload>
          <img
            v-if="ruleForm.viewWorksUrl"
            :src="ruleForm.viewWorksUrl"
            class="avatar"
          />
          <div v-else class="default-img-box">请上传图片</div>
        </el-form-item>
        <el-form-item
          label="页尾信息设置"
          class="page-footer-form-item add-margin-form-item"
          required
        >
          <!-- <el-form-item label="关于我们">
            <el-input
              v-model="ruleForm.other"
              placeholder="请输入介绍"
              @blur="setPageFooter"
              maxlength="100"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="版权所有">
            <el-input
              v-model="ruleForm.copyright"
              placeholder="请输入版权所有"
              @blur="setPageFooter"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <el-form-item label="ICP备案号">
            <el-input
              v-model="ruleForm.icpNum"
              placeholder="请输入备案号"
              @blur="setPageFooter"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系我们">
            <el-input
              v-model="ruleForm.telphone"
              placeholder="请输入联系方式"
              @blur="setPageFooter"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item
          label="logo设置"
          class="cover-form-item add-margin-form-item"
          prop="logoUrl"
        >
          <el-upload
            style="margin-bottom: 0.2rem"
            class="avatar-uploader"
            :action="url + '/nft-server/common/uploadImage'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccessLogo"
            :on-remove="handleRemoveLogo"
            :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="primary">上传logo</el-button>
            <div slot="tip" class="el-upload__tip">
              <el-button size="small" type="primary" @click.stop="saveLogo"
                >保存</el-button
              >
              （图片大小限制为5MB，图片尺寸为310px*60px)
            </div>
          </el-upload>
          <img v-if="ruleForm.logoUrl" :src="ruleForm.logoUrl" class="avatar" />
          <div v-else class="default-img-box">请上传图片</div>
        </el-form-item>
        <el-form-item
          label="二维码设置"
          class="cover-form-item add-margin-form-item"
          prop="qrCode"
        >
          <el-upload
            style="margin-bottom: 0.2rem"
            class="avatar-uploader"
            :action="url + '/nft-server/common/uploadImage'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccessQrCode"
            :on-remove="handleRemoveQrCode"
            :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="primary">上传二维码</el-button>
            <div slot="tip" class="el-upload__tip">
              <el-button size="small" type="primary" @click.stop="saveQrCode"
                >保存</el-button
              >
              （图片大小限制为5MB，图片尺寸为310px*60px)
            </div>
          </el-upload>
          <img
            v-if="ruleForm.qrCode"
            :src="ruleForm.qrCode"
            class="avatar qrimg"
          />
          <div v-else class="default-img-box">请上传图片</div>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="form-footer-box">
      <el-button
        type="primary"
        @click="submitForm('ruleForm', 0)"
        class="save-btn-common"
        >保存</el-button
      >
      <el-button @click="resetForm('ruleForm')">取消</el-button>
    </div> -->
    <el-dialog
      title="添加轮播图"
      :visible.sync="dialogVisible"
      width="35%"
      center
      custom-class="upload-banner-dialog"
    >
      <el-form
        :model="bannerForm"
        :rules="bannerRules"
        ref="ruleFormAdd"
        label-width="100px"
        class="edit-form"
      >
        <el-form-item class="cover-form-item upload-sildes-form-item">
          <img
            v-if="bannerForm.viewBannerUrl"
            :src="bannerForm.viewBannerUrl"
            class="avatar"
          />
          <div v-else class="upload-slides-tips">
            （图片大小限制为5MB，图片尺寸为1090px*417px)
          </div>
          <!-- :limit="tmpBiType == 100002 ? 2 : 100" -->
          <el-upload
            class="avatar-uploader editor-btn-upload"
            :action="url + '/nft-server/common/uploadImage'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccessB"
            :on-remove="handleRemoveB"
            :before-upload="beforeAvatarUploadB"
          >
            <el-button size="small" type="primary">上传图片</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" class="" prop="name">
          <el-input
            v-model="bannerForm.name"
            placeholder="请输入名称"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接地址" class="" prop="href">
          <el-input
            v-model="bannerForm.href"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="closeUploadDialog('ruleFormAdd')"
          class="dialog-footer-sure-btn"
          >确定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleTips"
      width="30%"
      center
      custom-class="user-for-reset-dialog"
    >
      <span v-if="showDialogText == 0"
        >图片格式错误，请上传bmp，jpg，png，gif，jpeg格式图片</span
      >
      <span v-if="showDialogText == 1">图片过大，上传失败，请重新上传</span>
      <span v-if="showDialogText == 2"
        >视频格式错误，请上传MPEG格式、AVI格式、ASF格式、WMV格式、MOV格式、MP4格式的视频</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisible = false"
          class="dialog-footer-sure-btn"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        logoUrl: "",
        imageUrl: "",
        other: "",
        copyright: "",
        icpNum: "",
        telphone: "",
        spiId: "",
        specialUrl: "",
        viewSpecialUrl: "",
        IndustryUrl: "",
        viewIndustryUrl: "",
        worksUrl: "",
        viewWorksUrl: "",
        qrCode: "",
      },
      rules: {
        logoUrl: [{ required: true, message: "请上传logo", trigger: "change" }],
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        imageUrl: [
          { required: true, message: "请输入抓取网站", trigger: "blur" },
        ],
      },
      bannerForm: {
        name: "",
        href: "",
        viewBannerUrl: "",
      },
      bannerRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        href: [{ required: true, message: "请输入链接", trigger: "blur" }],
      },
      Tags: [],
      detailData: {},
      webList: [],
      authorList: [],
      dialogVisible: false,
      dialogVisibleTips: false,
      showDialogText: 0,
      url: "",
      headers: "",
      allBannerList: [],
      tmpBiType: "100001",
      tmpBannerList: [],
      indexBannerList: [],
      indexMiddleBannerList: [],
      tmpEditorBanner: {},
      tmpBanner: {},
      tmpBiTypeCopy: "100001",
      isEdit: 0,
    };
  },
  created() {
    this.getFooterData();
    this.getBanner();
    this.url = process.env.VUE_APP_BASEURL;
    this.headers = { token: sessionStorage.getItem("token") };
  },
  methods: {
    getFooterData() {
      this.$Http("/nft-server/syspageinfo/info").then((res) => {
        if (res.code == 200) {
          this.ruleForm.logoUrl = res.sysPageInfo.logoUrl;
          this.ruleForm.other = res.sysPageInfo.other;
          this.ruleForm.copyright = res.sysPageInfo.copyright;
          this.ruleForm.icpNum = res.sysPageInfo.icpNum;
          this.ruleForm.telphone = res.sysPageInfo.telphone;
          this.ruleForm.spiId = res.sysPageInfo.spiId;
          this.ruleForm.qrCode = res.sysPageInfo.qrCode;
        } else {
          this.$message.error("获取失败！");
        }
      });
    },
    getBanner() {
      this.$Http("/nft-server/bannerinfo/list").then((res) => {
        if (res.code == 200) {
          this.indexBannerList = [];
          this.indexMiddleBannerList = [];
          this.allBannerList = res.page;
          this.allBannerList.forEach((item) => {
            if (item.biType == "100003") {
              this.ruleForm.viewSpecialUrl = item.imageUrl;
            }
            if (item.biType == "100004") {
              this.ruleForm.viewWorksUrl = item.imageUrl;
            }
            if (item.biType == "100005") {
              this.ruleForm.viewIndustryUrl = item.imageUrl;
            }
            if (item.biType == "100001") {
              this.indexBannerList.push(item);
            }
            if (item.biType == "100002") {
              this.indexMiddleBannerList.push(item);
            }
          });
        } else {
          this.$message.error("获取失败！");
        }
      });
    },
    moveBanner(item, e, val, vessel, index) {
      if (e.target.className.indexOf("setForbidden") > -1) {
        return;
      }
      let params = {
        biType: item.biType,
        linkUrlArr: [],
      };
      if (val == 1) {
        this[vessel].splice(
          index - 1,
          1,
          ...this[vessel].splice(index, 1, this[vessel][index - 1])
        );
        this[vessel].forEach((ele) => {
          params.linkUrlArr.push({
            imageUrl: ele.imageUrl,
            name: ele.bannerName,
            href: ele.linkUrl,
          });
        });
      } else {
        this[vessel].splice(
          index,
          1,
          ...this[vessel].splice(index + 1, 1, this[vessel][index])
        );
        this[vessel].forEach((ele) => {
          params.linkUrlArr.push({
            imageUrl: ele.imageUrl,
            name: ele.bannerName,
            href: ele.linkUrl,
          });
        });
      }
      this.$Https("/nft-server/bannerinfo/save", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.getBanner();
        } else {
          this.$message.error("保存失败");
        }
      });
    },
    clickDelete(item) {
      this.$Https("/nft-server/bannerinfo/delete", item).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getBanner();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    clickEditor(item, val, edit) {
      this.tmpBiTypeCopy = val;
      this.tmpBiType = val;
      this.isEdit = edit;
      // if(val=='100001'){
      //   // this.tmpBannerList   111111111111111111111111111111111111111111111111111111111
      // } else if(val='100002'){

      // }
      this.tmpEditorBanner = item;
      this.dialogVisible = true;
      this.bannerForm.viewBannerUrl = item.imageUrl;
      this.bannerForm.name = item.bannerName;
      this.bannerForm.href = item.linkUrl;
    },
    saveBanner(val) {
      let params = {
        biType: val,
        linkUrlArr: [],
      };
      if (val == 100003) {
        params.linkUrlArr.push({
          imageUrl: this.ruleForm.specialUrl,
          name: "",
          href: "",
        });
      }
      if (val == 100004) {
        params.linkUrlArr.push({
          imageUrl: this.ruleForm.worksUrl,
          name: "",
          href: "",
        });
      }
      if (val == 100005) {
        params.linkUrlArr.push({
          imageUrl: this.ruleForm.IndustryUrl,
          name: "",
          href: "",
        });
      }
      if (val == 100001) {
        if (this.tmpBannerList.length == 0 && !this.isEdit) {
          this.$message.error("请先新增Banner后再执行此操作");
          return;
        }
        if (
          val != this.tmpBiTypeCopy &&
          JSON.stringify(this.tmpBanner) != "{}"
        ) {
          let tipsname = val == "100001" ? "首页中部轮播图" : "首页轮播图";
          this.$message.error("请保存" + tipsname + "数据后再执行后续操作");
          return;
        }
        params.linkUrlArr = this.tmpBannerList;
        if (this.indexBannerList.length) {
          this.indexBannerList.forEach((item) => {
            params.linkUrlArr.push({
              imageUrl: item.imageUrl,
              name: item.bannerName,
              href: item.linkUrl,
            });
          });
        }
      }
      if (val == 100002) {
        if (this.tmpBannerList.length == 0 && !this.isEdit) {
          this.$message.error("请先新增Banner后再执行此操作");
          return;
        }
        if (
          val != this.tmpBiTypeCopy &&
          JSON.stringify(this.tmpBanner) != "{}"
        ) {
          let tipsname = val == "100001" ? "首页中部轮播图" : "首页轮播图";
          this.$message.error("请保存" + tipsname + "数据后再执行后续操作");
          return;
        }
        params.linkUrlArr = this.tmpBannerList;
        if (this.indexMiddleBannerList.length) {
          this.indexMiddleBannerList.forEach((item) => {
            params.linkUrlArr.push({
              imageUrl: item.imageUrl,
              name: item.bannerName,
              href: item.linkUrl,
            });
          });
        }
      }
      console.log("拦截", this.tmpBannerList, this.isEdit, this.tmpBanner);
      this.$Https("/nft-server/bannerinfo/save", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.getBanner();
          this.tmpBannerList = [];
          this.bannerForm.viewBannerUrl = "";
          this.tmpBanner = {};
        } else {
          this.$message.error("保存失败");
        }
      });
    },
    setPageFooter() {
      this.saveFooter();
    },
    saveLogo() {
      this.saveFooter();
    },
    saveQrCode() {
      this.saveFooter();
    },
    saveFooter() {
      let { other, copyright, icpNum, telphone, spiId, logoUrl, qrCode } =
        this.ruleForm;
      this.$Https("/nft-server/syspageinfo/update", {
        other,
        copyright,
        icpNum,
        telphone,
        spiId,
        logoUrl,
        qrCode,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
        } else {
          this.$message.error("保存失败");
        }
      });
    },
    submitForm(formName, val) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      const isType =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/bmp" ||
        file.type == "image/gif" ||
        file.type == "image/png";
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isType) {
        this.showDialogText = 0;
        this.dialogVisibleTips = true;
      }
      if (!isLt5M) {
        this.showDialogText = 1;
        this.dialogVisibleTips = true;
      }
      return isLt5M && isType;
    },
    handleAvatarSuccessLogo(res, file) {
      if (res.code == 200) {
        this.$message.success("上传成功！");
        this.ruleForm.logoUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemoveLogo(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccessQrCode(res, file) {
      if (res.code == 200) {
        this.$message.success("上传成功！");
        this.ruleForm.qrCode = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemoveQrCode(file, fileList) {},
    handleAvatarSuccessSpecial(res, file) {
      if (res.code == 200) {
        // this.$message.success("上传成功！");
        this.ruleForm.viewSpecialUrl = URL.createObjectURL(file.raw);
        this.ruleForm.specialUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemoveSpecial(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccessIndustry(res, file) {
      if (res.code == 200) {
        // this.$message.success("上传成功！");
        this.ruleForm.viewIndustryUrl = URL.createObjectURL(file.raw);
        this.ruleForm.IndustryUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemoveIndustry(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccessWorks(res, file) {
      if (res.code == 200) {
        // this.$message.success("上传成功！");
        this.ruleForm.viewWorksUrl = URL.createObjectURL(file.raw);
        this.ruleForm.worksUrl = res.imageUrl;
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemoveWorks(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccessB(res, file, filelist) {
      if (res.code == 200) {
        // this.$message.success("上传成功！");
        // this.bannerForm.viewBannerUrl = URL.createObjectURL(file.raw);
        this.bannerForm.viewBannerUrl = res.imageUrl;
        this.tmpBanner = {
          imageUrl: res.imageUrl,
          name: "",
          href: "",
        };
      } else {
        this.$message.error("上传失败！");
      }
    },
    handleRemoveB(file, fileList) {
      console.log(file, fileList);
    },
    beforeAvatarUploadB(file) {
      console.log("file", file);
      const isType =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/bmp" ||
        file.type == "image/gif" ||
        file.type == "image/png";
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isType) {
        this.showDialogText = 0;
        this.dialogVisibleTips = true;
      }
      if (!isLt5M) {
        this.showDialogText = 1;
        this.dialogVisibleTips = true;
      }
      return isLt5M && isType;
    },
    showDialogFun(val) {
      this.tmpBiType = val;
      if (val != this.tmpBiTypeCopy && JSON.stringify(this.tmpBanner) != "{}") {
        let tipsname = val == "100001" ? "首页中部轮播图" : "首页轮播图";
        this.$message.error("请保存" + tipsname + "数据后再执行后续操作");
        return;
      }
      this.tmpBiTypeCopy = val;
      this.dialogVisible = true;
      this.isEdit = 0;
    },
    closeUploadDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tmpBanner.name = this.bannerForm.name;
          this.tmpBanner.href = this.bannerForm.href;
          this.tmpBanner.imageUrl = this.bannerForm.viewBannerUrl;
          if (this.isEdit) {
            this.tmpEditorBanner.bannerName = this.bannerForm.name;
            this.tmpEditorBanner.imageUrl = this.bannerForm.viewBannerUrl;
            this.tmpEditorBanner.linkUrl = this.bannerForm.href;
            let vessel =
              this.tmpBiType == "100001"
                ? "indexBannerList"
                : "indexMiddleBannerList";
            for (let i = 0; i < this[vessel].length; i++) {
              if (this[vessel][i].biId == this.tmpEditorBanner.biId) {
                this[vessel][i] = this.tmpEditorBanner;
              }
            }
          } else {
            this.tmpBannerList.push(this.tmpBanner);
          }
          console.log("确定", this.tmpBannerList);
          this.bannerForm.viewBannerUrl = "";
          this.dialogVisible = false;
          this.$refs[formName].resetFields();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.infoMes-edit-Q {
  .form-box {
    padding: 0.25rem;
    background-color: #fff;
    min-height: calc(100vh - 0.8rem - 1.85rem);
    margin-bottom: 0.3rem;
    .edit-form {
      .el-form-item {
        margin-bottom: 0.2rem;
        position: relative;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   display: block;
        //   width: 100%;
        //   height: 1px;
        //   background-color: rgba(0, 0, 0, 0.1);
        //   top: 0.54rem;
        // }
        /deep/.el-form-item__label {
          font-size: 12px;
          line-height: 0.24rem;
        }
        /deep/.el-form-item__content {
          line-height: 0.24rem;
        }
        .custom-text-area {
          height: 1.06rem;
          /deep/.el-textarea__inner {
            height: 100%;
          }
        }
      }
      .add-margin-form-item {
        margin-bottom: 0.35rem;
      }
      .authority-form-item {
        // /deep/.el-form-item__label {
        //   float: none;
        //   display: inline-block;
        //   margin-bottom: 0.2rem;
        // }
        /deep/.el-form-item__content {
          border: 1px solid #d8d8d8;
          padding: 0.1rem 0 0.22rem 0.22rem;
          min-height: 2.69rem;
          .el-tree-node__content {
            .el-icon-caret-right {
              font-size: 18px;
              color: #000;
              &.expanded {
                color: #4940e8;
              }
              &.is-leaf {
                color: transparent;
                cursor: default;
              }
            }
          }
        }
      }
      .page-footer-form-item {
        &::after {
          width: 0 !important;
        }
        /deep/.el-form-item__content {
          margin-left: 0 !important;
          line-height: 0.24rem;
          & > div {
            div {
              display: inline-block;
              width: 3.5rem;
            }
          }
          .el-input__inner {
            font-size: 0.12rem;
            height: 0.24rem;
            line-height: 0.24rem;
          }
          .el-form-item {
            &::after {
              width: 0;
            }
          }
          .el-form-item__label {
            min-width: 0.9rem;
          }
        }
      }
      /deep/.page-footer-form-item > .el-form-item__label {
        float: none;
      }
      /deep/.el-textarea__inner::-webkit-input-placeholder,
      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #707070;
      }
      /deep/.el-textarea__inner::-moz-input-placeholder,
      /deep/.el-input__inner::-moz-input-placeholder {
        color: #707070;
      }
      /deep/.el-textarea__inner::-ms-input-placeholder,
      /deep/.el-input__inner::-ms-input-placeholder {
        color: #707070;
      }
    }
  }
  .form-footer-box {
    text-align: center;
    .el-button {
      font-weight: 400;
      font-size: 12px;
      padding: 0.05rem 0.08rem;
      background-color: #c9c9c9;
      border-color: #c9c9c9;
    }
    .save-btn-common {
      background-color: #a39ff3;
      border-color: #a39ff3;
      margin-right: 0.16rem;
    }
  }
  .edit-form {
    /deep/.el-form-item__label {
      font-weight: 300;
      color: #000;
      padding-right: 0.1rem;
    }
    .common-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 3.44rem;
        .el-input__inner {
          padding-left: 0.1rem;
        }
      }
    }
    .intro-form-item.el-form-item {
      /deep/.el-form-item__content {
        width: 6.21rem;
        .el-textarea__inner {
          min-height: 1.57rem !important;
          padding-left: 0.1rem;
        }
      }
    }
    .cover-form-item {
      /deep/.el-form-item__content {
        .avatar-uploader {
          .el-button {
            font-weight: 400;
            font-size: 12px;
            padding: 0.05rem 0.08rem;
            background-color: #a39ff3;
            border-color: #a39ff3;
          }
        }
        img,
        .default-img-box {
          width: 4.03rem;
          height: 0.9rem;
          vertical-align: middle;
        }
        .default-img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #999999;
          font-weight: 300;
        }
        .qrimg {
          width: 2rem;
          height: auto;
        }
        .el-upload__tip {
          display: inline-block;
          margin-left: 0.1rem;
          margin-top: 0;
          // margin-bottom: 0.14rem;
          font-size: 12px;
        }
        .trigger-upload-btn {
          font-weight: 400;
          font-size: 12px;
          padding: 0.05rem 0.08rem;
          background-color: #a39ff3;
          border-color: #a39ff3;
        }
        .trigger-upload-btn.is-disabled {
          background-color: #ccc;
          border-color: #ccc;
        }
      }
    }
    .upload-sildes-form-item {
      /deep/.el-form-item__content {
        margin-left: 0 !important;
        .upload-slides-tips {
          width: 4.95rem;
          height: 1.9rem;
          border: 1px solid #707070;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .index-slides-list-box {
    margin-bottom: 0.24rem;
    .index-slides-item {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 0.24rem 0;
      & > span,
      & > div {
        flex-basis: 25%;
        text-align: center;
        // display: inline-block;
        // vertical-align: middle;
        // margin-right: 0.18rem;
      }
      .slides-operation {
        span {
          margin-right: 0.3rem;
          color: #4940e8;
          font-weight: 300;
          cursor: pointer;
        }
        .setForbidden {
          color: #ccc;
        }
      }
      img {
        width: 2.08rem;
        height: 0.8rem;
        display: block;
      }
    }
  }
  .el-upload__tip {
    button {
      font-weight: 400;
      font-size: 12px;
      padding: 0.05rem 0.08rem;
      background-color: #a39ff3;
      border-color: #a39ff3;
      margin-right: 0.15rem;
    }
  }
  .editor-btn-upload {
    display: inline-block;
    margin-left: 0.1rem;
  }
}
</style>
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import myHeader from './myHeader/myHeader.vue'
import menu from './menu/menu.vue'
export default {
    install: function(Vue){
        Vue.component('swiper',swiper);
        Vue.component('swiperSlide',swiperSlide);
        Vue.component('myHeader',myHeader);
        Vue.component('Menu',menu);
    }
}
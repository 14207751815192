<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" size="mini" type="primary"
               @click=" dialogVisible=true">
<!--      <i class="fas fa-images"></i>-->
      <i class="fa fa-image"></i>
      从本地上传
    </el-button>
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
      <el-upload
          :multiple="true"
          :file-list="fileList"
          :show-file-list="true"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
          class="editor-slide-upload"
          :action="uploadAction"
          list-type="picture-card"
          :headers="headers"
          :data="uploadDate"
      >
        <el-button size="small" type="primary" style="background: #b5bcc2;border-color: #b5bcc2">
          点击上传 Upload
        </el-button>
      </el-upload>
      <el-button @click="dialogVisible = false">
        取消
      </el-button>
      <el-button type="primary" @click="handleSubmit" style="background: #3E3FE8;border-color: #b5bcc2; " >
        确认
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
// import { getToken } from 'api/qiniu'

// import {del} from "@/api/admin/file";
// import {commonGetNginxBaseUrl} from "../../../http/index"

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      uploadAction:  process.env.VUE_APP_BASEURL + "/nft-server/common/uploadImage",
      dialogVisible: false,
      listObj: {},
      fileList: [],
      headers: {
        token: sessionStorage.getItem('token')
      },
      uploadDate: {
        bussinessType: ''
      }
    }
  },
  created() {
    this.uploadDate.bussinessType = this.$store.getters.getTinymceTypeCodes
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.fileList).every(item => this.fileList[item].percentage === 100)
    },
    handleSubmit() {
     
      const arr = Object.keys(this.fileList).map(v => this.fileList[v])
      console.log(arr);
      if (!this.checkAllSuccess()) {
        this.$message('请等待所有图片成功上传。如出现网络问题，请重新上传或刷新页面!')
        return
      }

      let result = []
      arr.forEach(v => {
        result.push({url: v.response.imageUrl})
      })
      // 会调处理
      this.$emit('successCBK', result)
      // this.listObj = {}
      this.fileList = []
      this.dialogVisible = false
    },
    handleSuccess(response, file) {
      if (response !== null && Array.isArray(response)) {
        file.url = response[0]
      }
      this.fileList.push(file)
      // console.log("fileList",this.fileList)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      // del(file.url).then(response => {
      //   let index = this.fileList.findIndex(temp => temp.url == file.url)
      //   this.fileList.splice(index, 1)
      // })
    },
    beforeUpload(file) {
      const _self = this
      const _URL = window.URL || window.webkitURL
      const fileName = file.uid
      this.listObj[fileName] = {}
      return new Promise((resolve) => {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = function () {
          _self.listObj[fileName] = {hasSuccess: false, uid: file.uid, width: this.width, height: this.height}
        }
        resolve(true)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;

  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
</style>

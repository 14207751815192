<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
    <textarea :id="tinymceId" class="tinymce-textarea">
      <div></div>
    </textarea>
    <div class="editor-custom-btn-container">
      <editorImage color="#b5bcc2" class="editor-upload-btn" @successCBK="imageSuccessCBK" />
    </div>
  </div>
</template>

<script>
  import editorImage from './components/EditorImage'
  // import packvueTinymce from './PackVueTinymce.vue'
  import plugins from './plugins'
  import toolbar from './toolbar'
  import load from './dynamicLoadScript'

  // why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
 // const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
  export default {
    name: 'Tinymce',
    components: {
      editorImage
      // packvueTinymce
    },
    props: {
      id: {
        type: String,
        default: function () {
          return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
        }
      },
      value: {
        type: String,
        default: ''
      },
      toolbar: {
        type: Array,
        required: false,
        default () {
          return []
        }
      },
      menubar: {
        type: String,
        default: 'file edit insert view format table'
      },
      height: {
        type: [Number, String],
        required: false,
        default: 360
      },
      width: {
        type: [Number, String],
        required: false,
        default: 'auto'
      }
    },
    data() {
      return {
        hasChange: false,
        hasInit: false,
        tinymceId: this.id,
        fullscreen: false,
        languageTypeList: {
          'en': 'en',
          'zh': 'zh_CN',
          'es': 'es_MX',
          'ja': 'ja'
        }
      }
    },
    computed: {
      language() {
        return 'zh_CN'
      },
      containerWidth() {
        const width = this.width
        if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
          return `${width}px`
        }
        return width
      }
    },
    watch: {
      value(val) {
        if (!this.hasChange && this.hasInit) {
          this.$nextTick(() =>
            window.tinymce.get(this.tinymceId).setContent(val || ''))
        }
      },
      language() {
        this.destroyTinymce()
        this.$nextTick(() => this.initTinymce())
      }
    },
    mounted() {
      this.init()
    },
    activated() {
      if (window.tinymce) {
        this.initTinymce()
      }
    },
    deactivated() {
      this.destroyTinymce()
    },
    destroyed() {
      this.destroyTinymce()
    },
    methods: {
      init() {
        // dynamic load tinymce from cdn
        // load(tinymceCDN, (err) => {
        //   if (err) {
        //     this.$message.error(err.message)
        //     return
        //   }
        //
        // })

        this.initTinymce()
      },
      initTinymce() {
        const _this = this
        window.tinymce.init({
         // selector: '#tinymceId',
          content_style: "div {  letter-spacing:1px; font-Family:arial; font-size:12pt; line-height:2;} img { max-width:100%; }",
          paste_data_images: true, //可粘贴图片到富文本框
          invalid_elements: 'section', //无效元素
          language: this.language,
          selector: `#${this.tinymceId}`,
          height: this.height,
          body_class: 'panel-body ',
          object_resizing: false,
          toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
          menubar: this.menubar,
          plugins: plugins,
          end_container_on_empty_block: true,
          powerpaste_word_import: 'clean',
          code_dialog_height: 450,
          code_dialog_width: 1000,
          advlist_bullet_styles: 'square',
          advlist_number_styles: 'default',
          imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
          default_link_target: '_blank',
          link_title: false,
          height: 1500,
          // content_style:'',
           font_formats:"微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
         
          // 设置字体选型
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
          nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
          style_formats: [{
            title: "LineHeight",
            items: [{
                title: "1",
                block: "p",
                styles: {
                  "line-height": "1.0"
                }
              },
              {
                title: "1.5",
                block: "p",
                styles: {
                  "line-height": "1.5"
                }
              },
              {
                title: "1.75",
                block: "p",
                styles: {
                  "line-height": "1.75"
                }
              },
              {
                title: "2",
                block: "p",
                styles: {
                  "line-height": "2"
                }
              },
              {
                title: "3",
                block: "p",
                styles: {
                  "line-height": "3"
                }
              },
              {
                title: "4",
                block: "p",
                styles: {
                  "line-height": "4"
                }
              },
              {
                title: "5",
                block: "p",
                styles: {
                  "line-height": "5"
                }
              }
            ]
          }],
          style_formats_merge: true, //设置行高
          style_formats_autohide: true, //设置行高
          init_instance_callback: editor => {
            if (_this.value) {
              editor.setContent(_this.value)
            }
            _this.hasInit = true
            editor.on('NodeChange Change KeyUp SetContent', () => {
              this.hasChange = true
              this.$emit('input', editor.getContent())
            })
          },
          setup(editor) {
            editor.on('FullscreenStateChanged', (e) => {
              _this.fullscreen = e.state
            })
          },
          // it will try to keep these URLs intact
          // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
          // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
          convert_urls: false,
        })
      },
      
      destroyTinymce() {
        const tinymce = window.tinymce.get(this.tinymceId)
        if (this.fullscreen) {
          tinymce.execCommand('mceFullScreen')
        }

        if (tinymce) {
          tinymce.destroy()
        }
      },
      setContent(value) {
        window.tinymce.get(this.tinymceId).setContent(value)
      },
      getContent() {
        window.tinymce.get(this.tinymceId).getContent()
      },
      imageSuccessCBK(arr) {
        arr.forEach(v => window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`))
        
      }

    }
  }
</script>

<style lang="scss" scoped>
  .tinymce-container {
    position: relative;
    line-height: normal;
  }

  .tinymce-container {
    ::v-deep {
      .mce-fullscreen {
        z-index: 10000;
      }
    }
  }

  .tinymce-textarea {
    visibility: hidden;
    z-index: -1;
  }

  .editor-custom-btn-container {
    position: absolute;
    right: 4px;
    top: 4px;
    /*z-index: 2005;*/
    z-index: 2005;
  }

  .fullscreen .editor-custom-btn-container {
    z-index: 10000;
    position: fixed;
  }

  .editor-upload-btn {
    display: inline-block;
  }

</style>